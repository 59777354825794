export enum RoutesEnum {
  PATIENT_LOGIN_PAGE = '/',
  PATIENT_SIGNUP_MEDICINE = '/patient/signup/medicine',
  PATIENT_SIGNUP_REGISTRATION = '/patient/signup/registration',
  LOGOUT_ROUTE = '/api/auth/logout',
  PATIENT_PANEL_PAGE = '/panel/',
  PATIENT_MY_DATA = '/panel/my-data',
  PATIENT_PANEL_DASHBOARD = '/panel/dashboard',
  PATIENT_CONTENTS_ROUTE = '/panel/contents',
  PATIENT_MEDICATION_ROUTE = '/panel/my-medicines',
  PATIENT_MEDICATION_ADD_ROUTE = '/panel/my-medicines/add',
  PATIENT_MEDICATION_EDIT_ROUTE = '/panel/my-medicines/edit/:id',
  PATIENT_SCHEDULES_ROUTE = '/panel/schedules',
  PATIENT_DISCOUNTS_ROUTE = '/panel/discounts',
  PATIENT_PROGRAM_PURCHASE_ROUTE = '/panel/purchase',
  PATIENT_REGULAMENT_ROUTE = '/panel/regulament',
  PATIENT_MEDICINE_LEAFLETS_ROUTE = '/panel/medicine-leaflets',
  PATIENT_FAQ_ROUTE = '/panel/faq',
  PATIENT_ACCREDITED_ROUTE = '/panel/accredited',
  PATIENT_CREATE_USER = '/patient/create-user',
  TERM_OF_ACCEPTANCE = '/termOfAcceptance',
}


// PORTAL PATIENT API URL
export const {
  PORTAL_PATIENT_API_URL = 'http://localhost:3000/api',
} = process.env;

// BOEHRINGER CONFIGURATION
export const {
  BOEHRINGER_PROGRAM_CODE = '126',
} = process.env;

// ORIGIN CODE
export const {
  ORIGIN_CODE = '23',
} = process.env;
