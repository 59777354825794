import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { DynamicQuestions, LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';
import envConfig from '../config/environment-config';
import { authenticatedPostRequest } from './authenticatedRequestService';

function getOriginCode(pathname?: string): number {
  return pathname && pathname.includes('/unimed') ? envConfig.originCodeUnimed : envConfig.originCode;
}

function findManyByProgramCode(
  programCode: number, 
  pathname?: string,  
): UseQueryResult<AxiosResponse<DynamicQuestions, any>, unknown> {
  const method = 'form/ListDynamicsFieldsOrigin';
  const body = {
    programCode,
    originCode: getOriginCode(pathname),
  };
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms
  
  return useQuery(
    [LocalStorageKeysEnum.DYNAMIC_QUESTIONS_BY_PROGRAM, programCode],
    () => authenticatedPostRequest(
      method,
      body,
    ),
    {
      staleTime,
      cacheTime,
    },
  );
}


export const dynamicQuestionsService = {
  findManyByProgramCode,
};
