import { makeStyles, Theme } from '@material-ui/core';

const minDrawerWidth = 70;
const drawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: minDrawerWidth,
      width: `calc(100% - ${minDrawerWidth}px)`,
    },
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  appName: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  button: {
    background: theme.palette.primary.light,
    padding: '14px 16px',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1rem',
  },
  container: {
    paddingLeft: 0,
    overflow: 'hidden',
  },
  fab: {
    background: theme.palette.primary.light,
    position: 'fixed',
    bottom: 16,
    right: 16,
  },
  flex: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexGrow: {
    flex: 1,
  },
  navItem: {
    marginLeft: 40,
    // fontWeight: 700,
  },
  navLink: {
    color: theme.palette.text.secondary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.light,
    },
  },
  navUserList: {
    marginRight: -theme.spacing(3),
  },
  navUserButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  navUserButtonText: {
    marginTop: 0,
    marginBottom: 0,
  },
  activeNavLink: {
    color: theme.palette.primary.light,
  },
  menuButton: {
    marginRight: 0,
  },
  programSwitcher: {
    // transform: 'translateX(260px)',
  },
  programSwitcherShift: {
    // transform: 'translateX(300px)',
  },
  toolbar: {},
  hide: {
    display: 'none',
  },
  drawerToggleButton: {
    position: 'absolute',
    top: 25,
    left: -20,
    height: 40,
    width: 40,
    zIndex: 1201,
    border: '1px solid #DADADA',
    background: '#FFF',
  },
  footer: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#282929',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  span: {
    color: '#C1C1C2',
  },
}));
