import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory, useLocation  } from 'react-router-dom';
import { validateCaptcha } from 'react-simple-captcha';
import { noop } from 'lodash';
import { Button } from '../../common/styles';
import { AddMoreText, Box, Container } from '../../common/styles/FormStyles';
import { localStorageMethods } from '../../common/helpers';
import { IMedicineFormData } from '../contracts';
import { PatientCodeAndBirthdayInputBox, MedicineAndDoctorInputBox } from '../../common/components';
import { IUseFormData, LocalStorageKeysEnum } from '../../common/contracts';
import { RoutesEnum } from '../../../../constants';
import { dynamicQuestionsService, medicinesService } from '../../../../services';
import { useAlert } from '../../../../contexts/patient-portal/Alert.context';
import { medicineFormAlertMessages } from '../helpers';
import envConfig from '../../../../config/environment-config';
import Captcha from '../../../../components/Captcha.tsx';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';

export function MedicineForm(): JSX.Element {
  const { push } = useHistory();
  const [medicinsInputsList, setMedicinsInputsList] = useState([0]);
  const handleAddMedicine = () => {
    setMedicinsInputsList([...medicinsInputsList, 1]);
  };
  const { showAlert, hideAlert } = useAlert();
  const { showDialogue } = useDialogue();
  const location = useLocation(); 

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    getValues,
    watch,
  } = useForm();
  const formData: IUseFormData = {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister: noop,
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'medication',
  });
  const pathname = location.pathname; 
  const { data: allMedicines } = medicinesService.findManyByProgramCode(envConfig.boehringerProgramCode, pathname);
  const {
    data: questions,
  } = dynamicQuestionsService.findManyByProgramCode(envConfig.boehringerProgramCode, pathname); 

  const onSubmit = (data: any) => {
    if (!allMedicines || !questions) {
      return showDialogue('Dados dos medicamentos', 'Ops! Tivemos um erro ao obter os dados dos medicamentos, tente novamente mais tarde!', 'error');
    } 
    const userCaptchaValue = (document.getElementById('medicine_captcha_input') as HTMLInputElement)?.value;
    const { success, error } = medicineFormAlertMessages;
    const origin = window.location.pathname.includes('/unimed') ? '/unimed' : '';

    if (validateCaptcha(userCaptchaValue)) {
      localStorageMethods.setItem<IMedicineFormData>(LocalStorageKeysEnum.MEDICINE_FORM_DATA, data);
      showAlert(success?.title || '', success?.subTitle || '', 'success');
      setTimeout(() => hideAlert(), 3000);
      setTimeout(() => push(`${RoutesEnum.PATIENT_SIGNUP_REGISTRATION}${origin}`), 3200);
      setTimeout(() => localStorageMethods.removeItem(LocalStorageKeysEnum.PATIENT_CPF_LOGIN), 4000);
    } else {
      showAlert(error?.title || '', error?.subTitle || '', 'error');
    }
  };

  const defaultCpfValue = localStorageMethods.getItem<string>(LocalStorageKeysEnum.PATIENT_CPF_LOGIN);
  const pageIsBlocked = !defaultCpfValue;

  if (pageIsBlocked) {
    push(RoutesEnum.PATIENT_LOGIN_PAGE);
    return <></>;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <PatientCodeAndBirthdayInputBox 
            formData={formData}
            defaultValue={{
              cpf: defaultCpfValue,
            }}
          />
        </Box>

        <Box>
          {
            medicinsInputsList
              .map((value, index) => (
                <MedicineAndDoctorInputBox
                  index={index}
                  key={index}
                  formData={formData}
                  medicinsList={allMedicines?.data?.medicines}
                />
              ))
          }
        </Box>

        <Box onClick={handleAddMedicine}>
          <AddMoreText>
            + Adicionar medicamento
          </AddMoreText>
        </Box>

        <Box>
          <Captcha idName='medicine_captcha_input'/>
        </Box>

        <Button type='default'>
          <button type="submit"> Fazer meu cadastro </button>
        </Button>
      </form>
    </Container>
  );
}
