import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '64vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '100px auto',
    }
  },
  icon: {
    fontSize: '5rem',
    marginX: '2rem',
    color: 'var(--primary-main)',
  },
}));

const ServiceWillBeAvailable: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <PhoneInTalkOutlinedIcon className={classes.icon} />
      <Typography variant="h4" component="h1" gutterBottom style={{ marginTop: '30px' }}>
        Em breve as informações de agendamento estarão disponíveis.
      </Typography>
      <Typography variant="body1" component="p">
        Por enquanto, para falar sobre agendamento, entre em contato com 0800 701 6633
      </Typography>
    </Container>
  );
};

export default ServiceWillBeAvailable;