import { DynamicResponse } from "../contracts";

function checkLegalAge(birthDate?: string): boolean {
  if (!birthDate) return false;
  const [day, month, year] = birthDate?.split('/').map(Number);
  const dateOfBirth = new Date(year, month - 1, day);
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  return dateOfBirth <= eighteenYearsAgo;
}

export function checkIfPatientNeedsCaregiver({
  userDynamicQuestions,
  setPatientLegalAgeError,
  birthDate,
}: {
  userDynamicQuestions: DynamicResponse[],
  setPatientLegalAgeError: React.Dispatch<React.SetStateAction<boolean>>,
  birthDate?: string,
}) {
  const patientIsLegalAge = checkLegalAge(birthDate);
  if (patientIsLegalAge) return setPatientLegalAgeError(false);

  const caregiverCode = '960';
  const legalResponsableCode = '962';

  const correctChoice = userDynamicQuestions
    ?.find(
      (v: any) => {
        if (!Array.isArray(v?.CodeResponse)) {
          const condition = [caregiverCode, legalResponsableCode].includes(v?.CodeResponse);
          return condition;
        }

        const condition = v?.CodeResponse?.includes(caregiverCode)
        || v?.CodeResponse?.includes(legalResponsableCode);
        return condition;
      },
    );

  if (!correctChoice) {
    return setPatientLegalAgeError(true);
  }
  return setPatientLegalAgeError(false);
}
