import React from 'react';
import ServiceWillBeAvailable from '../../../components/ServiceWillBeAvailable';

const PurchasePage: React.FC<any> = (): JSX.Element => {

  return (
    <>
      <ServiceWillBeAvailable />
    </>
  );
};

export default PurchasePage;
