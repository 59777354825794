import React from 'react';
import styled from 'styled-components';

interface CardProps {
product: string;
name: string;
activeIngredient: string;
}

const CardContainer = styled.div`
  background-color: #FAFBFB;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  border: 1px solid #F3F3F4;
  flex: 1 1 calc(50% - 40px);
  box-sizing: border-box;
  max-width: calc(50% - 40px);
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const CardTitle = styled.h3`
  color: #1570EF;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: left;
`;

const CardPrincipios = styled.p`
  color: #555555;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
`;

export function Card({ product, name, activeIngredient } : CardProps) {
  const handleClick = () => {
    const url = `https://consultas.anvisa.gov.br/#/bulario/q/?nomeProduto=${encodeURIComponent(product)}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <CardContainer onClick={handleClick}>
        <CardTitle>{name}</CardTitle>
        <CardPrincipios>{activeIngredient}</CardPrincipios>
      </CardContainer>
    </>
  );
}
