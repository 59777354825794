import React from 'react';
import styled from 'styled-components';
import { Facebook, YouTube, Instagram } from '@material-ui/icons';

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const FooterWrapper = styled.div`
  background-color: #173366;
  color: #ffffff;
  padding: 20px 0;
  height: 351px;
`;

const SocialMedia = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  height: 80px;

  svg {
    margin: 0 10px;
    cursor: pointer;
  }
`;

const Terms = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 588px;
  margin: 40px auto;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FooterLink = styled.a`
  color: #ffffff;
  margin: 0 15px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.09px;
  letter-spacing: 0.38px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #ffffff;
  margin: 20px auto;
`;

const FooterText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #C1C1C2;
  text-align: center;
  line-height: 21px;
  margin: 0;
`;

export function Footer() {
  const links = {
    youtube: '',
    instagram: '',
    facebook: '',
    useTerms: '',
    privacyPolicy: '/termOfAcceptance#confidentialityAndProtection',
    regulation: '/termOfAcceptance#termsAndConditions',
  };

  return (
    <FooterWrapper>
      <Container>
        <SocialMedia>
          <FooterText>Siga-nos</FooterText>
          <Facebook />
          <YouTube />
          <Instagram />
        </SocialMedia>
        <Divider />
        <Terms>
          <FooterLink href={links.useTerms}>Termos de uso</FooterLink>
          <FooterLink href={links.privacyPolicy}>Política de privacidade</FooterLink>
          <FooterLink href={links.regulation}>Regulamento</FooterLink>
        </Terms>
        <FooterText>
          SAC Boehringer Ingelheim, 0800 701 6633,
          Atendimento ao consumidor
          <br />
          Material destinado exclusivamente a profissionais
          prescritores e/ou dispensadores de medicamentos.
        </FooterText>
        <br />
        <br />
        <FooterText>
          © 2022 Boehringer Ingelheim International GmbH. Todos os direitos reservados.
        </FooterText>
      </Container>
    </FooterWrapper>
  );
}
