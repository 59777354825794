import React, { createContext, useContext, ReactElement, useState, useEffect } from 'react';
import { authenticatedGetRequest } from '../services';
import envConfig from '../config/environment-config';
import { localStorageMethods } from '../pages/portal-patient/common/helpers';
import { IPatientCrendentials, LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';

export type AuthContextState = {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: any;
  isUnimed: boolean;
};

export type AuthProviderProps = {
  children: ReactElement;
};

const defaultState: AuthContextState = {
  isAuthenticated: false,
  isLoading: true,
  user: undefined,
  isUnimed: false
};

export const AuthContext = createContext<AuthContextState>(defaultState);

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [state, setState] = useState<AuthContextState>(defaultState);

  useEffect(() => {
    async function runAsync() {
      try {
        const credentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);

        if (!credentials || !credentials?.access_token) {
          setState({
            ...defaultState,
            isLoading: false,
          });
          return;
        }

        const response = await authenticatedGetRequest('auth/user');
        
        if (!response || response?.data?.uuid !== credentials.healthPassportId) {
          setState({
            ...defaultState,
            isLoading: false,
          });
        } else {
          setState({
            isAuthenticated: true,
            isLoading: false,
            user: response.data,
            isUnimed: credentials.isUnimed
          });
        }
      } catch (err) {
        console.log(err);
        setState({
          ...defaultState,
          isLoading: false,
        });
      }
    }
    runAsync();
  }, []);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextState => useContext<AuthContextState>(AuthContext);
