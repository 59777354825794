import React, { useState } from 'react';
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Box,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Menu as MenuIcon, Person } from '@material-ui/icons';
import clsx from 'clsx';
import { RoutesEnum } from '../../constants';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { localStorageMethods } from '../../pages/portal-patient/common/helpers';
import { IPatientCrendentials, LocalStorageKeysEnum } from '../../pages/portal-patient/common/contracts';
import { useHistory } from 'react-router-dom';

type NavbarProps = {
  isDrawerOpen?: boolean;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
};

export default function Navbar({
  isDrawerOpen,
  onDrawerOpen,
  onDrawerClose,
}: NavbarProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [menu, setMenu] = useState(null);
  const [showOptout, setShowOptout] = useState(false);
  const { push } = useHistory();
  const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);

  const addSufix = (route: string, sufix: string): string => {
    return route.endsWith(sufix) ? route : `${route}${sufix}`;
  };
  
  const sufix = '/unimed';

  const handleMenuOpen = (event: any) => {
    setMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleMyDataClick = () => {
    const credentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
    push(credentials?.isUnimed ? addSufix(RoutesEnum.PATIENT_MY_DATA, sufix) : RoutesEnum.PATIENT_MY_DATA);
  }

  return (
    <AppBar
      color="default"
      elevation={0}
      className={clsx(classes.appBar, 'navbar-container', {
        [classes.appBarShift]: isDrawerOpen,
      })}
    >
      <Box className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={onDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isDrawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.flex}>
            {!isXS && (
              <>
                <Link to={`/panel/dashboard`} style={{ textDecoration: 'none' }}>
                  <div className={clsx('navbar-client-image')} />
                </Link>
                <Typography variant="h1" className={classes.appName}>
                  {'Portal Paciente'}
                </Typography>
                <div className={classes.flexGrow} />
              </>
            )}
            <List disablePadding className={classes.navUserList}>
              <ListItem
                button
                dense
                disableGutters
                onClick={handleMenuOpen}
                className={classes.navUserButton}
                aria-haspopup="true"
                aria-controls="user-menu"
              >
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={patientCredentials?.fullName?.split(' ')[0] || 'carregando...'}
                  secondary={patientCredentials?.email || 'carregando...'}
                  className={classes.navUserButtonText}
                />
              </ListItem>
            </List>
            <Menu
              id="user-menu"
              anchorEl={menu}
              open={Boolean(menu)}
              keepMounted
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMyDataClick}>Meus Dados</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Box>
      <Divider />
      <IconButton
        className={clsx(classes.drawerToggleButton, 'navbar-drawer-toggle', {
          [classes.hide]: !isDrawerOpen,
        })}
        color="secondary"
        onClick={onDrawerClose}
      >
        <MenuIcon />
      </IconButton>
    </AppBar>
  );
}
