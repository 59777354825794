import React from 'react';
import { Footer, ImageTopBar } from '../../common/components';
import { CreateUserWelcome } from '../components/CreateUserWelcome';
import { CreateUserForm } from '../components/CreateUserForm';

export default function CreateUserPage(): JSX.Element {
  return (
    <>
      <ImageTopBar />
      <CreateUserWelcome
        title="Informações de cadastro"
        subtitle="Precisamos de algumas informações para criar o seu usuário no Programa."
      />
      <CreateUserForm />
      <Footer />
    </>
  );
}
