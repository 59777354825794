import axios, { AxiosResponse } from 'axios';
import { PORTAL_PATIENT_API_URL } from '../constants';
import { authenticatedPostRequest } from './authenticatedRequestService';
import envConfig from '../config/environment-config';

// TODO: Pro futuro integrar com o portal do médico para validação.
async function verifyDoctor(document: string): Promise<AxiosResponse<any, any>> {
  const method = 'patients/RegisteredDoctor';
  return await axios.post(`${PORTAL_PATIENT_API_URL}/${method}`, {
    document,
  });
}

async function verifyPatient(cpfOrEmail: string): Promise<AxiosResponse<any, any>> {
  const method = 'patients/RegisteredPatient';
  const body = {
    cpfOrEmail: cpfOrEmail.includes('@') ? cpfOrEmail : cpfOrEmail.replace(/[./-]/g, ''),
    programCode: envConfig.boehringerProgramCode,
  };

  return await authenticatedPostRequest(
    method,
    body,
  );
}

async function loginPatient(login: string, password: string): Promise<AxiosResponse<any, any>> {
  const method = 'auth/login';
  const body = {
    login: login.includes('@') ? login : login.replace(/[./-]/g, ''),
    password,
    programId: envConfig.boehringerProgramCode,
  };

  return await authenticatedPostRequest(
    method,
    body,
  );
}

export default {
  verifyDoctor,
  verifyPatient,
  loginPatient,
};
