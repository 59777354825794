import React, { useState } from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IMedicineFormData } from '../contracts';
import { localStorageMethods } from '../../common/helpers';
import { LocalStorageKeysEnum } from '../../common/contracts';
import { Container } from '../../common/styles';
import { medicinesService } from '../../../../services';
import { BOEHRINGER_PROGRAM_CODE } from '../../../../constants';
import envConfig from '../../../../config/environment-config';

const List = styled.div`
  margin-top: 20px;

  p {
    margin-left: 10px;
    margin-bottom: 5px;
    color: #282929;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
`;

const BoxFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;

    p {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      margin: 0;
      color: #000000;
    }

    button {
      border: none;
      background-color: white;
    }

    @media (max-width: 600px) {
      width: 250px;

      p {
        text-align: start;
        padding-left: 10px;
      }
    }
`;

export function MedicineToggleList() {
  const [showList, setShowList] = useState<boolean>(true);
  const medicineFormData = localStorageMethods
    .getItem<IMedicineFormData>(LocalStorageKeysEnum.MEDICINE_FORM_DATA);

  const { data: allMedicines } = medicinesService.findManyByProgramCode(envConfig.boehringerProgramCode);
  const selectedMedicines = medicineFormData?.medication?.map((value) => {
    const productName = (allMedicines?.data?.medicines?.find(
      (v) => v.lineCode.toString() === value.CodeProductRule.toString(),
    ))?.description;
    return productName;
  });

  return (
    <Container>
      <BoxFlex>
        <button type="submit" onClick={() => setShowList(!showList)}>
          { showList ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
        </button>
        <p>Lista de medicamento, selecionado:</p>
      </BoxFlex>
      {
        showList
          ? (
            <List>
              {
                selectedMedicines?.map((value, index) => <p key={index}>{ value }</p>)
              }
            </List>
          ) : ''
      }
    </Container>
  );
}
