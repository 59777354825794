/* eslint-disable */

import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const News1: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / O que é diabetes?</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        O que é diabetes?
      </Typography>
      <br />
      <Typography paragraph> Quantas vezes você já ouviu falar que o açúcar em excesso faz mal para a saúde? Apesar de essa ser uma informação já conhecida por muitas pessoas, o conhecimento sobre o que é o diabetes de fato ainda é escasso. </Typography>
      <Typography paragraph> Nesse artigo você encontra informações confiáveis sobre o que é o diabetes e ainda entende quais são os tipos dessa doença que pode comprometer a saúde. </Typography>
      <Typography paragraph> Para que funcionem adequadamente, as células do corpo precisam de um combustível, que é, justamente, a glicose. Porém, para que a glicose penetre nas células, é necessário um hormônio chamado insulina. </Typography>
      <Typography paragraph> Isso quer dizer que quando há glicose em excesso ou falta de insulina, podem surgir problemas. </Typography>
      <Typography paragraph> Em geral, o diabetes é uma doença metabólica que se caracteriza pelo aumento do nível de glicose no sangue, a famosa hiperglicemia. </Typography>
      <Typography paragraph> Existem três tipos principais de diabetes: </Typography>

      <Typography paragraph variant="h6"> 1. Diabetes mellitus tipo I</Typography>
      <Typography paragraph> Também conhecido como infanto juvenil ou imunomediado. Nesse tipo de diabetes, a produção de insulina do pâncreas é insuficiente, pois suas células sofrem o que chamamos de destruição autoimune.</Typography>
      <Typography paragraph> Os portadores de diabetes tipo 1 necessitam de injeções diárias de insulina para manterem as taxas de glicose no sangue em valores normais. Se as doses de insulina não forem dadas diariamente, um risco maior para a saúde pode ser gerado.</Typography>
      <Typography paragraph> Embora possa acontecer em qualquer idade, o diabetes tipo 1 é mais comum em crianças, adolescentes e adultos jovens.</Typography>

      <Typography paragraph variant="h6"> 2. Diabetes mellitus tipo II</Typography>
      <Typography paragraph> Cerca de 90% dos casos de diabetes são do tipo II. Essa doença ocorre, geralmente, em pessoas com obesidade com mais de 40 anos de idade.</Typography>
      <Typography paragraph> Apesar disso, por conta de maus hábitos alimentares, sedentarismo e estresse, o diabetes tipo II tem sido cada vez mais frequente em pessoas mais jovens.</Typography>
      <Typography paragraph> Nesse tipo de diabetes, o organismo produz insulina, mas sua ação é dificultada, o que é conhecido como resistência insulínica, uma das causas de hiperglicemia.</Typography>
      <Typography paragraph> Por ser pouco sintomático, na maioria das vezes o diabetes permanece por muitos anos sem diagnóstico e sem tratamento, o que favorece a ocorrência das suas complicações.</Typography>

      <Typography paragraph variant="h6"> 3. Diabetes gestacional</Typography>
      <Typography paragraph> A presença de glicose elevada no sangue durante a gravidez é denominada de diabetes gestacional. Apesar do susto, geralmente, a glicose no sangue se normaliza após o parto.</Typography>
      <Typography paragraph> No entanto, as mulheres que apresentam ou apresentaram diabetes gestacional possuem maior risco de desenvolverem diabetes tipo II ao longo da vida e o mesmo acontece com seus filhos.</Typography>
      <Typography paragraph> Apesar de não apresentar sintomas muito evidentes, o diabetes pode causar muitos problemas para a sua saúde, principalmente a longo prazo. Consulte um médico com frequência e siga o tratamento indicado por ele corretamente.</Typography>
      <Typography paragraph> Além disso, uma vida mais saudável, com uma alimentação balanceada e atividade física regular pode trazer mais bem-estar e ainda contribuir com o tratamento e prevenção do diabetes.</Typography>
      <Typography paragraph> E lembre-se que o Programa Abraçar a Vida estará sempre ao seu lado trazendo mais informações, dicas e conteúdos importantes e confiáveis para a sua saúde.</Typography>

      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        Bibliografia consultada:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1. Diretrizes da Sociedade Brasileira de Diabetes: 2014-2015/Sociedade Brasileira de Diabetes; 2015. São Paulo: AC Farmacêutica."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. American Diabetes Association. Standards of medical care in Diabetes 2015. Diabetes Care. 2015 Jan;38 Suppl:S4"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. (ADA) American Diabetes Association. Diabetes Care 2010; 33(Suppl 1):S11-61."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4. American Association of Clinical Endocrinologists. Endocr Prac. 2007;13:1-68; American Diabetes Association. Standards of medical care in Diabetes 2015. Diabetes Care. 2015 Jan;38 Suppl:S4."
          />
        </ListItem>
      </List>

      <br />
      <Typography paragraph variant="h6"> Quais são os sintomas do diabetes?</Typography>
      <Typography paragraph> O diabetes pode ser um inimigo silencioso da sua saúde. Isso porque é pouco sintomático, ou seja, os sintomas do diabetes demoram a aparecer ou são pouco perceptíveis por quem tem a doença.</Typography>
      <Typography paragraph> Entretanto, o diagnóstico precoce é muito importante para iniciar o tratamento o quanto antes e evitar as possíveis complicações.</Typography>
      <Typography paragraph> Por isso, além de manter consultas de rotina para monitorar os níveis de glicose no sangue, também vale a pena ficar atento aos possíveis sintomas do diabetes.</Typography>
      <Typography paragraph> Quando presentes, os sintomas mais comuns do diabetes são: cansaço, sede excessiva, vista embaçada ou turva, aumento do apetite, urinar excessivamente – inclusive durante a noite, perda de peso nos quadros mais graves – mesmo quando a pessoa come de maneira excessiva, infecções frequentes – sendo as infecções de pele as mais comuns.</Typography>
      <Typography paragraph> Como falamos no início do texto, nos casos de diabetes tipo II, esses sintomas, quando presentes, se instalam de maneira gradativa e muitas vezes podem não ser percebidos. Já no caso do diabetes tipo I, os sintomas se instalam rapidamente, especialmente a necessidade de urinar de maneira excessiva, a sede intensa e o emagrecimento.</Typography>
      <Typography paragraph> Apesar de não apresentar sintomas muito evidentes, o diabetes de qualquer tipo pode causar muitos problemas para a sua saúde, principalmente a longo prazo.</Typography>
      <Typography paragraph> Seja qual for o sintoma, é importante ficar atento e procurar um médico o mais rápido possível para que ele possa realizar o diagnóstico corretamente e indicar o melhor tratamento para o seu caso.</Typography>
      <Typography paragraph> Cuide da sua saúde e tenha mais bem-estar e qualidade de vida no seu dia a dia. Conte com o Programa Abraçar a Vida para que essa caminhada seja mais leve e tranquila.</Typography>

      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        Bibliografia consultada:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1. Diretrizes da Sociedade Brasileira de Diabetes: 2014-2015/Sociedade Brasileira de Diabetes; 2015. São Paulo: AC Farmacêutica."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. American Diabetes Association. Standards of medical care in Diabetes 2015. Diabetes Care. 2015 Jan;38 Suppl:S4"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. (ADA) American Diabetes Association. Diabetes Care 2010; 33(Suppl 1):S11-61."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4. American Association of Clinical Endocrinologists. Endocr Prac. 2007;13:1-68; American Diabetes Association. Standards of medical care in Diabetes 2015. Diabetes Care. 2015 Jan;38 Suppl:S4."
          />
        </ListItem>
      </List>

      <br />
      <Typography paragraph variant="h6"> Quem pode ter diabetes? Conheça os fatores de risco!</Typography>
      <Typography paragraph> Hoje, no Brasil, há mais de 13 milhões de pessoas vivendo com diabetes, o que representa 6,9% da população.</Typography>
      <Typography paragraph> Será que você corre o risco de ter diabetes? Alguns fatores de risco indicam se você tem mais chance de desenvolver essa doença. Por isso, nesse texto vamos mostrar quem está mais propenso a ter diabetes para minimizar o risco ou ficar atento e procurar um médico para manter seus níveis de glicose sempre monitorados e sob controle.</Typography>
      <Typography paragraph> A presença de uma ou mais das seguintes condições são fatores de risco para a presença de diabetes:</Typography>
      <Typography paragraph> • Familiares próximos com diabetes;</Typography>
      <Typography paragraph> • Idade acima de 45 anos;</Typography>
      <Typography paragraph> • Excesso de peso ou obesidade;</Typography>
      <Typography paragraph> • Pressão alta;</Typography>
      <Typography paragraph> • Colesterol elevado;</Typography>
      <Typography paragraph> • Mulheres que tiveram filhos nascidos com mais de 4Kg ou que tiveram diabetes gestacional.</Typography>
      <Typography paragraph> Cerca de 90% dos portadores de diabetes têm o tipo II da doença, que é pouco sintomático e pode passar despercebido por muito tempo.</Typography>
      <Typography paragraph> Isso, muitas vezes, retarda o diagnóstico e o tratamento, aumentando o risco da ocorrência de complicações. Você pode ser um dos muitos brasileiros que têm diabetes e não sabem.</Typography>

      <Typography paragraph variant="h6"> Como diagnosticar diabetes?</Typography>
      <Typography paragraph> O diagnóstico de diabetes é, geralmente, simples. Procure um médico ou uma unidade de saúde próxima a sua casa para a medir a glicose no sangue (glicemia) e, caso necessário, fazer exames complementares para saber se você é portador de diabetes.</Typography>
      <Typography paragraph> Se você apresenta um ou mais dos fatores de risco citados acima, consulte o seu médico e faça exames para um check-up com certa frequência, lembre-se de manter um estilo de vida mais saudável sempre e conte com o Abraçar a Vida para apoiar a sua jornada.</Typography>

      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        Bibliografia consultada:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1. Diretrizes da Sociedade Brasileira de Diabetes: 2014-2015/Sociedade Brasileira de Diabetes; 2015. São Paulo: AC Farmacêutica."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. American Diabetes Association. Standards of medical care in Diabetes 2015. Diabetes Care. 2015 Jan;38 Suppl:S4"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. (ADA) American Diabetes Association. Diabetes Care 2010; 33(Suppl 1):S11-61."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4. American Association of Clinical Endocrinologists. Endocr Prac. 2007;13:1-68; American Diabetes Association. Standards of medical care in Diabetes 2015. Diabetes Care. 2015 Jan;38 Suppl:S4."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="5. https://www.diabetes.org.br/publico/diabetes/fatores-de-risco Acesso em 27 de abril de 2021."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="6. https://www.diabetes.org.br/publico/diabetes/oque-e-diabetes Acesso em 27 de abril de 2021."
          />
        </ListItem>
      </List>

      <br />
      <Typography paragraph variant="h6"> Tipos de diabetes: conheça melhor alguns deles</Typography>
      <Typography paragraph> O diabetes é uma doença que atinge mais de 380 milhões de pessoas no mundo e é provável que esse número aumente em 100 milhões até 2035. Os números só crescem, o que indica a necessidade de prevenir, de acompanhar e de entender melhor o que é diabetes e as diferentes formas em que a doença se apresenta.</Typography>
      <Typography paragraph> Caso você não seja muito familiarizado com o diabetes, trata-se de uma doença metabólica em que ocorre um defeito da ação ou da produção da insulina no organismo e aumenta a quantidade de glicose no sangue (chamada hiperglicemia), com exceção de um dos tipos, chamado diabetes insipidus.</Typography>
      <Typography paragraph> Seu organismo precisa de glicose para gerar energia e conseguir fazer as atividades cotidianas. Para que a glicose consiga entrar nas células e se transformar em combustível, é preciso da ajuda da insulina, portanto sem a ação desse hormônio não há o funcionamento correto do organismo!</Typography>
      <Typography paragraph> Existem vários tipos de diabetes, entre os mais comuns, estão o diabetes mellitus tipo 1, diabetes mellitus tipo 2, diabetes gestacional e diabetes insipidus.</Typography>
      <Typography paragraph> Vamos entender um pouco mais sobre cada um deles?</Typography>

      <Typography paragraph variant="h6"> • Diabetes mellitus tipo 1 (ou DM1)</Typography>
      <Typography paragraph> O diabetes tipo 1 é definido como insulinopênico, é a deficiência absoluta de insulina no corpo. As células sofrem uma espécie de destruição autoimune e, para manter o equilíbrio do metabolismo, os portadores desse tipo de diabetes precisam de injeções diárias de insulina.</Typography>
      <Typography paragraph> Tudo isso para que as taxas de glicose se mantenham estáveis. Embora ocorra em apenas de 5 a 10% de todos os casos de pessoas com diabetes, normalmente é identificado em crianças, jovens e adultos jovens.</Typography>

      <Typography paragraph variant="h6"> • Diabetes mellitus tipo 2 (ou DM2)</Typography>
      <Typography paragraph> Esse tipo de diabetes acontece devido a resistência à insulina e é o mais frequente.</Typography>
      <Typography paragraph> Um dos maiores vilões desse tipo de diabetes é a obesidade (ou sobrepeso) e, embora seja diagnosticado, normalmente, em pessoas com mais de 40 anos, esse fator de risco pode levar a pessoas de qualquer idade a contraí-lo, desde adolescentes até idosos.</Typography>
      <Typography paragraph> Por isso, o acompanhamento regular das suas taxas de glicose é imprescindível.</Typography>

      <Typography paragraph variant="h6"> • Diabetes gestacional</Typography>
      <Typography paragraph> O diabetes gestacional nada mais é do que alterações na concentração de glicose que aparecem durante a gestação. Esse tipo, se não controlado, pode ser muito prejudicial ao bebê.</Typography>
      <Typography paragraph> É importante salientar que, para ser considerado diabetes gestacional, a paciente tem que ser diagnosticada no pré-natal ou no início gestacional, sem um histórico da doença.</Typography>
      <Typography paragraph> Outro ponto muito importante é que, nesse tipo de diabetes, a paciente deve ser avaliada novamente de quatro a seis semanas após o parto.</Typography>
      <Typography paragraph> Na grande maioria a taxa de glicose volta ao normal, mas é importante salientar que ter apresentado diabetes gestacional é fator de risco para DM2 (Diabetes mellitus tipo 2).</Typography>

      <Typography paragraph variant="h6"> • Diabetes insipidus (ou insípida)</Typography>
      <Typography paragraph> Esse tipo de diabetes é bastante raro e é caracterizado pela incapacidade do rim de concentrar a urina – esse distúrbio acaba causando uma sede intensa e o aumento da quantidade da urina.</Typography>
      <Typography paragraph> Essa doença renal é mais comum na infância e no início da fase adulta, acontece igualmente em ambos os sexos e pode causar danos ao sistema nervoso central, entre outros.</Typography>
      <Typography paragraph> Apesar de se chamar “diabetes”, o diabetes insupidus não tem nenhuma relação com o diabetes mellitus e nem com a glicose no sangue.</Typography>
      <Typography paragraph> Agora você que você já sabe como o diabetes pode se manifestar de diferentes formas, lembre-se sempre que manter estilo de vida saudável e o acompanhamento regular de sua glicose é importantíssimo e que você precisa estar atento aos sintomas e possíveis alterações do seu organismo.</Typography>

      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        Bibliografia consultada:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1- Sociedade Brasileira de Diabetes (Brasil). Diretrizes da Sociedade Brasileira de Diabetes. São Paulo: AC Farmacêutica, 2015."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2- Pincinato EC. Atualização no diagnóstico, classificação e tratamento para o diabetes mellitus (DM). Revista de Atenção à Saúde (antiga Rev. Bras. Ciên. Saúde). 2003 Jan/Jun; 1(1): 62-68."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3- Rocha JL, Friedman E, Boson WL, De Marco L. Diabetes insipidus nefrogênico: conceitos atuais de fisiopatologia e aspectos clínicos. Arq Bras Endocrinol Metab [Internet]. 2000 Ago; 44( 4 ): 290-299. Disponível em: http://www.scielo.br/scielo.php?script=sci_arttext&pid=S0004-27302000000400004&lng=en. http://dx.doi.org/10.1590/S0004-27302000000400004."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4- Weiler FG, Blumberg K, Liboni CS, et al. Diabetes insípido em pacientes com esclerose múltipla. Arq Bras Endrocrinol Metab. 2008 Fev; 52(1): 134 -137."
          />
        </ListItem>
      </List>

      <br />
      <Typography paragraph variant="h6"> O tratamento do DM2 além do medicamento.</Typography>
      <Typography paragraph> Ser diagnosticado com diabetes não é nada fácil e significa que você terá que fazer algumas mudanças no seu dia a dia. Isso porque o tratamento para diabetes vai muito além das medicações e depende muito dos seus hábitos de vida.</Typography>
      <Typography paragraph> Vamos falar mais sobre esse assunto e contar como você pode tomar a frente e cuidar da sua saúde!</Typography>
      <Typography paragraph> Para o tratamento do diabetes, é preciso manter uma vida saudável e o controle da glicemia sempre em dia, a fim de evitar possíveis complicações da doença. Os principais cuidados para tratar o diabetes incluem:</Typography>

      <Typography paragraph variant="h6"> Exercícios físicos:</Typography>
      <Typography paragraph> A atividade física é essencial no tratamento do diabetes para manter os níveis de açúcar no sangue controlados e afastar os riscos de ganho de peso.</Typography>
      <Typography paragraph> A prática de exercícios deve ser realizada de três a cinco vezes na semana. É importante que você combine com seu médico quais são as melhores opções.</Typography>

      <Typography paragraph variant="h6"> Controle da dieta:</Typography>
      <Typography paragraph> Pessoas com diabetes devem cuidar muito da alimentação, reduzindo os açúcares simples presentes nos doces e carboidratos simples como massas e pães, pois eles possuem um índice glicêmico muito alto.</Typography>
      <Typography paragraph> Os carboidratos devem constituir de 50 a 60% das calorias totais ingeridas pela pessoa com diabetes, preferindo-se os carboidratos complexos (castanhas, nozes, grãos integrais) que serão absorvidos mais lentamente. Consulte seu médico e nutricionista para mais informações.</Typography>

      <Typography paragraph variant="h6"> Verificar a glicemia:</Typography>
      <Typography paragraph> No diabetes mellitus tipo I e tipo II é importante verificar sua glicemia com frequência. Para fazer essa medida, é necessário ter um glicosímetro, dispositivo capaz de medir a concentração exata de glicose no sangue.</Typography>
      <Typography paragraph> O médico e o restante da equipe de saúde podem ajudar a definir um cronograma de testes para serem feitos e a definir as metas relativas às taxas de glicose. Além disso, os resultados dos testes são importantes para alterar as refeições, suas atividades ou os medicamentos e, assim, manter os níveis de glicose normalizados.</Typography>
      <Typography paragraph> Esse procedimento pode ajudar a identificar as altas e as baixas taxas de glicose no sangue antes que elas causem problemas e ainda evita o risco de complicações.</Typography>

      <Typography paragraph variant="h6"> Evite o consumo excessivo de bebidas alcoólicas:</Typography>
      <Typography paragraph> O consumo de álcool não é proibido na maioria das vezes, mas deve ser moderado e sempre acompanhado de um alimento, pois o consumo isolado pode causar hipoglicemia.</Typography>
      <Typography paragraph> É importante fazer o monitoramento de glicemia antes e depois de consumir bebidas alcoólicas.</Typography>
      <Typography paragraph> Cuidado com cervejas e bebidas doces ou à base de carboidratos. Elas têm alto índice glicêmico e podem aumentar a glicemia. Sempre consulte seu médico e nutricionista para ter as orientações corretas para o seu caso.</Typography>

      <Typography paragraph variant="h6"> Controle o estresse:</Typography>
      <Typography paragraph> Pessoas com diabetes têm maiores chances de ter ansiedade e depressão. Os pacientes podem sentir uma sensação de ansiedade em relação ao controle da glicemia, tomada da medicação, dieta e controle do peso. Procure sua equipe de saúde e converse sobre o que sente para que eles possam te indicar o tratamento adequado.</Typography>

      <Typography paragraph variant="h6"> Tabagismo:</Typography>
      <Typography paragraph> O diabetes combinado com o hábito de fumar multiplica, em até cinco vezes, o risco de algumas complicações. Portanto, é um hábito que deve ser evitado.</Typography>

      <Typography paragraph variant="h6"> Cuide da saúde bucal:</Typography>
      <Typography paragraph> A higiene bucal após cada refeição também é fundamental para quem tem diabetes. Isso porque o sangue dos portadores de diabetes, com alta concentração de glicose, é mais propício à proliferação de bactérias. Realizar uma boa escovação e ir ao dentista periodicamente é de extrema importância.</Typography>
      <Typography paragraph> Agora que você já sabe como pode contribuir com o seu tratamento, que tal dar o primeiro passo e começar a realizar pequenas mudanças no seu dia a dia? O Programa Abraçar a Vida estará ao seu lado para apoiar nessa jornada.</Typography>

      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        Bibliografia consultada:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1) Association of Diabetes Care and Education Specialists, Kolb L. An Effective Model of Diabetes Care and Education: The ADCES7 Self-Care Behaviors™."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2) Sci Diabetes Self Manag Care. 2021 Feb;47(1):30-53. doi: 10.1177/0145721720978154. PMID: 34078208."
          />
        </ListItem>
      </List>
    </Container>
  );
};

export const News2: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / Mitos e verdades sobre alimentação e diabetes</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        Mitos e verdades sobre alimentação e diabetes
      </Typography>
      <Typography variant="h6" component="p" gutterBottom>
        Por Maristela Bassi Strufaldi – Nutricionista do Programa Abraçar a Vida
      </Typography>
      <br />
      <Typography paragraph>
        Quem convive com o diabetes sabe que não faltam dicas e receitas milagrosas que prometem controlar a glicemia. Mas afinal, o que realmente é verdade e o que é mentira? Veja abaixo alguns mitos e verdades sobre o assunto:
        <List>
          <ListItem>
            <ListItemText primary="1. Frutas são alimentos saudáveis e por isso, podem ser consumidos à vontade por quem tem diabetes. VERDADEIRO ou FALSO?  "
              secondary="FALSO. As frutas possuem frutose em sua composição, um açúcar natural que – quando consumido em excesso – também eleva a glicemia. Se consumidas em quantidades adequadas e distribuídas corretamente ao longo do dia, as frutas não interferem no controle do diabetes e favorecem a saúde, com a abundante oferta de vitaminas, minerais e compostos bioativos. De qualquer forma, destaca-se que nenhuma fruta é proibida para quem tem diabetes, o importante é que haja moderação na quantidade consumida. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="2. Tudo que nasce abaixo da terra, por exemplo, mandioquinha, cará, mandioca e batata, aumenta as taxas de açúcar no sangue e por isso, são proibidos para quem tem diabetes. VERDADEIRO ou FALSO?"
              secondary="FALSO. Esses alimentos são fontes de carboidrato, que é um nutriente fundamental para a saúde de todos (é um combustível para o organismo, um alimento energético). Entre todos, ele é sim o nutriente que se transforma mais rapidamente em açúcar no sangue, mas isso não quer dizer que não deve ser consumido por quem tem diabetes. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="3. A combinação entre arroz e feijão é muito saudável, inclusive para quem tem diabetes. VERDADEIRO ou FALSO?  "
              secondary="VERDADEIRO. A combinação entre arroz e feijão é muito saudável e benéfica para todos, incluindo as pessoas com diabetes. O feijão é um dos alimentos mais ricos em fibras solúveis, o que torna a digestão mais lenta, importantíssimo para menor elevação da glicose no sangue. Outro aspecto relevante é a saciedade que o feijão proporciona, resultado desse mesmo processo de digestão. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="4. Alimentos como carnes, leites e derivados não são fontes de carboidratos/açúcares. Logo, podem ser consumidos à vontade, pois não interferem nas taxas de açúcar do sangue. VERDADEIRO ou FALSO?  "
              secondary="FALSO. Esses alimentos são ricos em proteínas e gorduras, que se consumidos em excesso, também interferem nas taxas de açúcar do sangue. Além disso, o consumo abusivo desses alimentos pode elevar o peso e favorecer o aumento da pressão e do colesterol.   " />
          </ListItem>
          <ListItem>
            <ListItemText primary="5. Produtos diets não possuem açúcar, por isso, podem ser consumidos à vontade por quem tem diabetes. VERDADEIRO ou FALSO? "
              secondary="FALSO. Alimentos diets podem não conter sacarose (açúcar) em sua composição, mas possuem outros ingredientes que também alteram a glicemia, como outras fontes de carboidratos (naturalmente presentes no alimento), gorduras e proteínas. A moderação é a palavra-chave para um bom controle glicêmico! " />
          </ListItem>
          <ListItem>
            <ListItemText primary="6. Alimentos como o caqui, a beterraba, a cenoura e a uva são alimentos de sabor mais adocicado e por isso, devem ser evitados por quem tem diabetes. VERDADEIRO ou FALSO? "
              secondary="FALSO. As pessoas com diabetes podem consumir tais alimentos, pois o fato deles terem um sabor mais adocicado não determinada que eles possuem mais carboidrato (açúcar) em sua composição. Nenhuma fruta é proibida para quem tem diabetes, tal como nenhum legume, raiz ou vegetal.  " />
          </ListItem>
        </List>
      </Typography>
    </Container>
  );
};

export const News3: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / Todo movimento conta</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        Todo movimento conta
      </Typography>
      <br />
      <Typography paragraph>
        Você quer incorporar a atividade física na sua rotina, mas não sabe o que fazer nem por onde começar?
      </Typography>
      <Typography paragraph>
        Os diversos estudos científicos consolidados ao longo dos anos definem metas e objetivos para que os benefícios da atividade física regular sejam mais efetivos. O que é preciso fazer, segundo a Organização Mundial da Saúde e o Guia da Atividade Física para a População Brasileira?

        <List>
          <ListItem>
            <ListItemText primary="• Pelo menos 150 minutos por semana (equivalente a 30 minutos, 5 dias na semana) de atividade aeróbia moderada. Atividade aeróbia é aquela que envolve vários músculos e pode ser praticada por períodos mais longos. Exemplos: caminhar, correr, nadar, andar de bicicleta." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Pelo menos 2 vezes por semana, exercícios de fortalecimento muscular que, como o próprio nome diz, trazem força e resistência para os músculos. Os exercícios de fortalecimento podem ser realizados usando máquinas de musculação, halteres, faixas elásticas ou até mesmo o peso corporal.  " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• 2 vezes por semana, exercícios de flexibilidade, como alongamento e ioga. Essas atividades servem para aumentar a amplitude de movimento das articulações e a elasticidade dos músculos e tendões. Ajudam a melhorar a capacidade do corpo de realizar movimentos, sendo fundamental para prevenir lesões, melhorar a postura e promover bem-estar. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Reduzir o comportamento sedentário. Isso significa que não é bom para a saúde ficar muito tempo sentado no computador ou vendo televisão, por exemplo. O ideal é adotar um padrão de vida ativo, com deslocamentos a pé, subir escadas, fazer pausas ativas durante a jornada de trabalho.  " />
          </ListItem>
        </List>
      </Typography>
      <Typography paragraph>
        Cansou só de ler? Pois é, não é exatamente fácil cumprir essas metas à risca. Mas a boa notícia é que a própria OMS fala, com todas as letras, que QUALQUER MOVIMENTO CONTA. Ou seja, o importante é se mexer, incorporar algum movimento (mesmo pouco) ao seu cotidiano.
      </Typography>
      <Typography paragraph>
        Comece devagar: 5 minutos já são suficientes para trazer benefícios e para ajudar a criar o hábito de se exercitar. O melhor é escolher alguma coisa que você goste de fazer e é importante manter a rotina. Aos poucos, você pode ir aumentando a duração (mais tempo) e a frequência (mais dias na semana). Dica: melhor 5 ou 10 minutos por dia do que 2 horas só uma vez na semana.
      </Typography>
      <Typography paragraph>
        Caso você possua alguma restrição fisica, converse antes com o seu médico, para que você adapte os exercícios físicos da melhor forma à sua rotina.
      </Typography>
      <Typography paragraph>
        Então, vamos começar?
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        REFERÊNCIAS:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="WHO guidelines on physical activity and sedentary behavior: at a glance. Geneva: World Health Organization; 2020 Guia de Atividade Física para a População Brasileira. 2021"
          />
        </ListItem>
      </List>
    </Container>
  );
};

export const News4: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / Como tratar a hipoglicemia</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        Como tratar a hipoglicemia
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        Ana Renata de Godoy Ferreira
      </Typography>
      <br />
      <Typography paragraph>
        A hipoglicemia é uma complicação aguda que se caracteriza por níveis baixos de glicose no sangue. Ocorre uma queda na
        concentração da glicose para níveis abaixo de 70 mg/dl. É mais comum acontecer em pessoas que têm diabetes mellitus
        tipo 1, mas também ocorre em pacientes com diabetes mellitus tipo 2, principalmente os que fazem uso de insulina e
        alguns tipos de antidiabéticos orais.
      </Typography>
      <Typography paragraph>
        São vários os sintomas da hipoglicemia: tontura, sonolência, dificuldade na concentração, tremores, sudorese, fome, dor de cabeça, irritabilidade,
        fraqueza, confusão mental, visão embaçada, dentre outros sintomas. Em casos mais graves, pode ocorrer convulsão. Há casos em que a pessoa não
        tem os sintomas da hipoglicemia, sendo importante intensificar a monitorização da glicemia.
      </Typography>
      <Typography paragraph>
        A hipoglicemia é classificada em três níveis:
        <List>
          <ListItem>
            <ListItemText primary="• Nível 1 – com resultado entre 54 e 70 mg/dl;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Nível 2 – com resultado abaixo de 54 mg/dl;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Nível 3 – independente do resultado, há comprometimento da consciência e necessidade de assistência." />
          </ListItem>
        </List>
      </Typography>
      <Typography paragraph>
        Para o tratamento da hipoglicemia a pessoa deve ingerir 15 gramas de carboidrato simples. Encontramos 15 gramas de carboidrato simples em: 3-4
        balas mastigáveis, 01 colher de sopa de mel, 01 colher de sopa de açúcar (pode ser diluída), 150 ml de suco de laranja ou 150 ml de refrigerante
        que não seja diet. Esperar 15 minutos e verificar a glicemia novamente. Se continuar abaixo de 70 mg/dl, ingerir outros 15 gramas de carboidrato
        simples. Se na próxima hora não tiver uma refeição prevista, importante fazer um lanche para evitar outro quadro de hipoglicemia.
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        REFERÊNCIAS:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="SOCIEDADE BRASILEIRA DE DIABETES. Hipoglicemia: como tratar e evitar?"
            secondary="Disponível em: https://diabetes.org.br/hipoglicemia-como-tratar-e-evitar/. Acesso em: 28 ago. 2023."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="MINISTÉRIO DA SAÚDE. Hipoglicemia."
            secondary="Disponível em: https://www.gov.br/saude/pt-br/assuntos/saude-de-a-a-z/d/diabetes/hipoglicemia. Acesso em: 28 ago. 2023."
          />
        </ListItem>
      </List>
    </Container>
  );
};

export const News5: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / Os sete comportamentos do autocuidado</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        Os sete comportamentos do autocuidado
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        Ana Renata de Godoy Ferreira
      </Typography>
      <br />
      <Typography paragraph>
        O autocuidado pode ser definido através de atividades que o indivíduo realiza em benefício de si próprio. Essas atividades estão relacionadas ao bem-estar e à promoção da saúde. A facilidade para essas atividades serem praticadas está associada às habilidades e dificuldades do indivíduo. O autocuidado é uma prática aonde o indivíduo será protagonista do próprio cuidado, baseado em diretrizes estabelecidas pela medicina. Vários fatores podem influenciar na decisão do autocuidado, dentre eles podemos citar: fatores ambientais, sociais, econômicos, hereditários e de acesso aos serviços de saúde (GOMIDES et al., 2013).
      </Typography>
      <Typography paragraph>
        Os sete comportamentos do autocuidado é um instrumento que foi validado pela Associação Americana de Educadores em Diabetes. Esse instrumento auxilia na identificação das habilidades e pontos de maior atenção que a pessoa com diabetes deve desenvolver (Sociedade Brasileira de Diabetes, 2022).
      </Typography>
      <Typography paragraph>
        Os principais objetivos dos sete comportamentos do autocuidado para quem tem diabetes é incentivar o indivíduo a buscar um bom controle da glicemia e também que ele se adapte de forma positiva, mesmo com o diagnóstico (Sociedade Brasileira de Diabetes, 2022).
      </Typography>
      <Typography paragraph>
        É importante que a pessoa com diabetes tenha conhecimento dos sete comportamentos do autocuidado para um tratamento efetivo, sendo sempre direcionado por uma equipe de saúde que facilite este processo. São eles:
        <List>
          <ListItem>
            <ListItemText primary="• Comer saudavelmente: uma alimentação saudável garante equilíbrio entre as escolhas dos alimentos e suas quantidades. Alimentos in natura ou minimamente processados devem ser preferidos e de maneira que a refeição esteja balanceada. O PRATO SAUDÁVEL é composto por 50% de vegetais crus e cozidos, 25% de proteína e 25% de carboidratos. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Praticar exercícios: o resultado da prática do exercício resulta numa maior facilidade da glicose entrar na célula e também diminui a resistência a insulina. Escolha um exercício que seja prazeroso e aumente gradualmente o tempo da prática desse exercício. Não se esqueça da hidratação e para quem faz uso de insulina, verificar a glicose antes e depois do exercício. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Vigiar taxas: a monitorização da glicose seja através de glicosímetro ou através de sensores de monitorização contínua da glicose, traz resultado importante para a tomada de decisão. Nesse caso a chance de hipoglicemia e hiperglicemia pode ser reduzida. Importante registrar os resultados para que a equipe de saúde avalie junto ao paciente, se as metas estão sendo atingidas. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Tomar medicamento: importante seguir a prescrição médica, principalmente atentar-se para o horário de tomada dos medicamentos, bem como se os medicamentos podem ser tomados juntos ou se precisam de jejum. O paciente deve estar orientado caso esqueça a tomada do medicamento e também ser orientado quanto ao local de armazenamento. Nos casos de uso da insulina, o paciente deve estar orientado quanto à técnica de aplicação, importância do rodízio da aplicação e armazenamento da insulina, reutilização e descarte correto das agulhas. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Resolver problemas: saber identificar e corrigir a hipoglicemia e hiperglicemia é uma ação para esse autocuidado. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Reduzir riscos: o risco diminuído para desenvolver complicações está associado ao estilo de vida saudável, participação em grupos de educação em diabetes, prevenção através de consultas com especialistas e realização rotineira de exames laboratoriais. " />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Adaptar-se saudavelmente: estar preparado com o quantitativo de insumos para uma viagem, diante de um quadro infeccioso e manejo da glicose, diante das escolhas em uma festa. Uma rede de apoio é importante para o enfrentamento saudável e convívio com o diabetes (Sociedade Brasileira de Diabetes, 2022). " />
          </ListItem>
        </List>
      </Typography>
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        REFERÊNCIAS:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="GOMIDES et al. Autocuidado das pessoas com diabetes mellitus que possuem complicações em membros inferiores. Act Paul Enf., 2013. "
            secondary="Disponível em: https://www.scielo.br/j/ape/a/33wvfN3pN6VzDxnG39CYyLf/?lang=pt#. Acesso em: 17 out. 2023. "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="SOCIEDADE BRASILEIRA DE DIABETES. Os 7 comportamentos do autocuidado."
            secondary="Disponível em: https://diabetes.org.br/os-7-comportamentos-do-autocuidado/. Acesso em: 17 out. 2023. "
          />
        </ListItem>
      </List>
    </Container>
  );
};

export const News6: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / O que é hipertensão arterial?</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        O que é hipertensão arterial?
      </Typography>
      <br />
      <Typography paragraph> Que a alimentação faz toda diferença na sua saúde você já deve saber, certo? Mas você sabia que é possível reduzir a pressão arterial com mudanças na dieta diária? </Typography>
      <Typography paragraph> A hipertensão arterial é um dos maiores problemas de saúde pública no mundo e é também um dos principais fatores de risco para o desenvolvimento de acidente vascular cerebral (AVC) e de problemas cardíacos. </Typography>
      <Typography paragraph> A simples alteração nos hábitos de nutrição da pessoa hipertensa pode ajudar a controlar os níveis da pressão arterial. Isso porque, entre outros fatores, a elevação desses níveis está ligada a excessos alimentares (principalmente o alto consumo de sal) e ao sobrepeso. </Typography>
      <Typography paragraph> No texto de hoje vamos falar um pouco mais sobre como fazer essas mudanças e dicas para você colocar tudo em prática e começar a cuidar mais da sua saúde! </Typography>

      <Typography paragraph variant="h6"> A dieta DASH </Typography>
      <Typography paragraph> Diversos estudos recentes indicam que hipertensos devem adotar um padrão de alimentação baseado na dieta DASH (Dietary Approaches to Stop Hypertension ou, em português, Abordagens Dietéticas para Parar a Hipertensão). </Typography>
      <Typography paragraph> Isso envolve investir em uma dieta rica em frutas, hortaliças, fibras, minerais, laticínios e alimentos com baixo teor de gordura, o que aumenta o potencial para reduzir e até mesmo evitar a elevação da pressão arterial. </Typography>

      <Typography paragraph variant="h6"> Alimentos aconselhados e contraindicados para controlar a hipertensão </Typography>

      <Typography paragraph variant="h6"> Frutas e verduras </Typography>
      <Typography paragraph> A inclusão desses alimentos na dieta diária é fundamental para controlar a pressão arterial. É indicado o consumo de, pelo menos, 8 a 10 porções de frutas e verduras por dia (sendo uma porção equivalente a uma concha média). </Typography>

      <Typography paragraph variant="h6"> Sódio (sal) </Typography>
      <Typography paragraph> O alto consumo de sódio está diretamente ligado à hipertensão – especialmente no Brasil, onde o uso de sal de cozinha corresponde ao dobro da recomendação diária. Para pessoas hipertensas, indica-se a redução expressiva do sal no preparo dos alimentos, assim como evitar o uso de condimentos, molhos e temperos industrializados, que contenham bastante sódio. </Typography>

      <Typography paragraph variant="h6"> Carnes </Typography>
      <Typography paragraph> Consuma carne em pequenas quantidades e sempre escolha por aquelas com baixo teor de gordura saturada, colesterol e gordura total, como aves, peixes e outras carnes magras. </Typography>

      <Typography paragraph variant="h6"> Ácidos graxos insaturados (oléicos) </Typography>
      <Typography paragraph> Especialmente para idosos, o consumo diário de óleo de peixe (rico em ômega 3) pode auxiliar no controle da pressão. Outros alimentos benéficos são o óleo de oliva, o óleo de canola, o abacate e as oleaginosas, como castanhas e nozes. </Typography>

      <Typography paragraph variant="h6"> Laticínios </Typography>
      <Typography paragraph> Por serem uma importante fonte de cálcio, os laticínios devem ser incluídos na dieta diária, principalmente, para prevenir os riscos de hipertensão. </Typography>

      <Typography paragraph variant="h6"> Além dos cuidados com a alimentação, é preciso ter hábitos saudáveis! </Typography>
      <Typography paragraph> Para reduzir os efeitos da hipertensão arterial, além de adotar uma alimentação mais equilibrada, com a redução de alimentos industrializados (ricos em sódio, em gorduras e em açúcar), deve-se investir em hábitos mais saudáveis. Entre eles: parar de fumar, reduzir o consumo de álcool e praticar exercícios físicos regularmente. </Typography>
      <Typography paragraph> Essa é uma fórmula simples que vai garantir ainda mais qualidade de vida para o seu dia a dia seja você diagnosticado com hipertensão ou apenas tenha propensão para desenvolver a doença. </Typography>

      <br />
      <Divider />
      <br />
      <List>
        <ListItem>
          <ListItemText
            primary="1. Malachias MVB, Souza WKSB, Plavnik FL, et al. 7a?Diretriz Brasileira de Hipertensão Arterial. Arq Bras Cardiol 2016; 107(3Supl.3):1-83. "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. Bisi Molina M del C, Cunha Rde S, Herkenhoff LF, et al. Hypertension and salt intake in an urban population. Rev Saude Publica. 2003;37(6):743-50. "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. Hinderliter AL, Babyak MA, Sherwood A, Blumenthal JA. The DASH diet and insulin sensitivity. Curr Hypertens Rep. 2011;13(1):67-73 "
          />
        </ListItem>
      </List>
    </Container>
  );
};

export const News7: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / O que é hipertensão arterial?</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        O que é hipertensão arterial?
      </Typography>
      <br />
      <Typography paragraph> A pressão arterial é um daqueles assuntos de saúde que todo mundo acha que entende um pouco. Afinal, quem nunca sentiu uma fraqueza e logo pensou “minha pressão deve estar baixa”? </Typography>
      <Typography paragraph> A pressão arterial é uma parte muito importante do nosso corpo e desempenha um papel fundamental na nossa saúde e por isso é importante entender melhor sobre quando ela começa a ter problemas, como é o caso da hipertensão arterial. </Typography>

      <Typography paragraph variant="h6"> O que é hipertensão arterial? </Typography>
      <Typography paragraph> A hipertensão arterial sistêmica (HAS), mais conhecida como pressão alta, é uma doença caracterizada pela elevação sustentada dos níveis de pressão arterial (PA). Níveis de pressão iguais ou acima de 14 por 9 são considerados altos a população adulta. </Typography>
      <Typography paragraph> Na maior parte dos casos, a hipertensão não tem uma causa conhecida sendo chamada de hipertensão essencial ou primária, mas em uma pequena parte a hipertensão pode ser causada por outras doenças ou efeito de medicações, denominada hipertensão secundária. </Typography>
      <Typography paragraph> A pressão se eleva por vários motivos, mas principalmente porque os vasos nos quais o sangue circula se contraem. Imagine uma torneira aberta ligada a vários esguichos. Se fecharmos a ponta dos esguichos a pressão lá dentro aumenta. O mesmo ocorre quando o coração bombeia o sangue. Se os vasos são estreitados, a pressão sobe. </Typography>
      <Typography paragraph> Apesar de, na maioria das vezes, não conseguirmos saber com precisão a causa da hipertensão arterial, sabemos que muitos fatores podem ser responsáveis por ela:
        <List>
          <ListItem>
            <ListItemText
              primary="• Hereditariedade;"
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="• Idade; "
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="• Raça; "
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="• Obesidade; "
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="• Tabagismo;"
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="• Sedentarismo;"
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="• Estresse; "
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="• Bebidas alcoólicas;"
            />
          </ListItem>
        </List>
      </Typography>

      <Typography paragraph> A hipertensão é muito comum. Para se ter uma ideia, segundo a Sociedade Brasileira de Hipertensão, ela acomete 1 em cada 4 pessoas adultas e a estimativa é que, no mínimo, 25 % da população brasileira adulta tenha pressão alta, chegando a mais de 50% após os 60 anos e está presente em 5% das crianças e adolescentes no Brasil. </Typography>
      <Typography paragraph> A hipertensão é responsável por 40% dos infartos, 80% dos derrames e 25% dos casos de insuficiência renal terminal. </Typography>
      <Typography paragraph> A pressão arterial varia ao longo das 24 horas do dia e isso é normal. Na maioria das pessoas, durante as horas de repouso, ocorre uma diminuição da pressão, que vai aumentado gradativamente ao amanhecer até atingir seus níveis máximos durante o dia. </Typography>
      <Typography paragraph> Essa elevação matinal da pressão arterial é associada, segundo estudos científicos, com maior ocorrência de doenças cardiovasculares como o infarto e o derrame cerebral, que ocorrem com maior frequência no período da manhã entre 6h e 12h. </Typography>
      <Typography paragraph> Em geral, a hipertensão não apresenta sintomas e é este “silêncio” na manifestação da doença que a torna perigosa, pois quando sub tratada ou não tratada, pode trazer sérios riscos à saúde afetando órgãos como o cérebro, coração, rins e vasos sanguíneos, causando doenças como derrames cerebrais (AVC), insuficiência cardíaca, infarto do coração, paralização dos rins e problemas na visão. </Typography>
      <Typography paragraph> A boa notícia é que as graves consequências da pressão alta podem ser evitadas, desde que os hipertensos conheçam sua condição e mantenham-se em tratamento com adequado controle da pressão. </Typography>
      <Typography paragraph> Tratar a hipertensão pode reduzir em 40% as chances de sofrer um derrame.?Saiba mais sobre o tratamento de hipertensão! </Typography>
      <Typography paragraph> Faça consultas regulares ao seu médico e siga sempre as orientações dele para ter uma vida mais saudável e com mais bem-estar! </Typography>

      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        Bibliografia consultada:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1. Sociedade Brasileira de Hipertensão (SBH). São Paulo. [acesso em 15 Set 2015]."
            secondary="Disponível em: http://www.sbh.org.br/geral/oque-e-hipertensao.asp "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. Sociedade Brasileira de Cardiologia. VI Diretrizes Brasileiras de Hipertensão. Arq Bras Cardiol 2010; 95(1 supl.1): 1-51. "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. Sociedade Brasileira de Hipertensão (SBH). São Paulo. [acesso em 28 abr 2021]. Disponível em: "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4. Sociedade Brasileira de Hipertensão (SBH). São Paulo. [acesso em 28 abr 2021]. "
            secondary="Disponível em:?http://menospressao.com.br/sobre-a-hipertensao/ "
          />
        </ListItem>
      </List>
    </Container>
  );
};

export const News8: React.FC = () => {
  return (
    <Container>
      <Typography style={{ cursor: 'default' }}><Link to={`/panel/contents`} style={{ textDecoration: 'none', color: '#556275' }}>Conteúdo</Link> / Tratamento para hipertensão</Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" component="h1" gutterBottom>
        Tratamento para hipertensão
      </Typography>
      <br />
      <Typography paragraph> Ser hipertenso significa que o coração tem que trabalhar mais para conseguir vencer os altos valores de pressão. Esse trabalho extra faz com que as chances de ter complicações como um acidente vascular cerebral (derrame), problemas cardíacos ou renais aumentam muito. </Typography>
      <Typography paragraph> Mas a boa notícia é que tudo isso pode ser evitado quando os níveis de pressão são controlados por meio do tratamento correto para hipertensão que pode ser uma combinação da melhora dos hábitos de vida com o uso de medicamentos. </Typography>
      <Typography paragraph> Nos pacientes hipertensos leves, o controle do peso corporal, a alimentação saudável e balanceada, a prática de atividade física moderada (por exemplo, caminhadas rápidas de 30 min, 5x na semana) e a diminuição do consumo de bebidas alcoólicas podem ser o suficiente para controlar os níveis tensionais. </Typography>
      <Typography paragraph> Para o controle da hipertensão arterial, é essencial seguir as orientações de seu médico, fazer controle periódico, tomar os medicamentos orientados regularmente e não abandonar seu tratamento. </Typography>
      <Typography paragraph> Veja algumas dicas: </Typography>

      <Typography paragraph variant="h6"> Refeições equilibradas </Typography>
      <Typography paragraph> Aposte em verduras, legumes, grãos e azeites. Consuma menos gordura animal, açúcar, refrigerantes e doces, evite o uso de enlatados e conservas, carnes embutidas, defumados, frituras, creme de leite e maionese. </Typography>

      <Typography paragraph variant="h6"> Temperos ao invés de sal </Typography>
      <Typography paragraph> É sempre bom dar sabor aos alimentos com iguarias, ervas ou sucos de frutas. Manjericão, canela, cominho, curry, mostarda seca, alho, cebola, páprica ou pimenta são boas possibilidades de deixar a alimentação saborosa e saudável. </Typography>

      <Typography paragraph variant="h6"> Evitar a ingestão de bebidas alcoólicas </Typography>
      <Typography paragraph> Elas contribuem para o aumento da pressão e para o aumento de peso. Habituar-se à prática regular de atividade física aeróbica (como caminhadas, por, pelo menos, 30 minutos por dia, 3 vezes por semana), pode auxiliar na prevenção e no tratamento da hipertensão. </Typography>

      <Typography paragraph variant="h6"> Parar de fumar </Typography>
      <Typography paragraph> Depois da hipertensão, o fumo é o principal fator de risco de doenças cardiovasculares. Isso contribui para uma vida saudável e diminui o risco cardiovascular.</Typography>

      <Typography paragraph variant="h6"> Evite o estresse </Typography>
      <Typography paragraph> Divirta-se, tenha tempo para sua família e para você. Isso faz parte do tratamento, melhora sua qualidade de vida e auxilia no controle da pressão. </Typography>
      <Typography paragraph> A hipertensão arterial não tem cura, mas deve ser tratada para impedir complicações. Meça sua pressão arterial regularmente e siga as orientações dos profissionais de saúde envolvidos no seu tratamento sempre! </Typography>
      <Typography paragraph> Continue contando com o Programa Saúde Fácil para apoiar a sua caminhada. </Typography>

      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="h2" gutterBottom>
        Bibliografia consultada:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1. Sociedade Brasileira de Hipertensão (SBH). São Paulo. [acesso em 15 Set 2015]. "
            secondary="Disponível em:?http://www.sbh.org.br/geral/oque-e-hipertensao.asp "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. Sociedade Brasileira de Cardiologia. VI Diretrizes Brasileiras de Hipertensão. Arq Bras Cardiol 2010; 95(1 supl.1): 1-51. "
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. 1. Sociedade Brasileira de Hipertensão (SBH). São Paulo. [acesso em 28 Abr 2021]. "
            secondary="Disponível em:?http://menospressao.com.br/sobre-a-hipertensao/ "
          />
        </ListItem>
      </List>
    </Container>
  );
};
