import React, { useEffect, useState } from 'react';
import { TextField, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Box, InputTitle, InputsBox, inputLabelProps, inputMUIDefaultStyles } from '../../common/styles';
import { SelectInput } from '../../common/components';
import { IUseFormData } from '../../common/contracts';
import { addressValidation, cepValidation, cityValidation, numberHouseValidation } from '../../common/validations/forms';
import { statesList } from '../../common/helpers/statesListMock';
import { IMaskInputCEP } from '../../common/components/IMaskInput';
import { cepIntegrationService } from '../../../../services/cepIntegrationService';

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2), 
  },
  marginBottom2: {
    marginBottom: theme.spacing(1), 
  },
  marginTopOnLg: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(1), 
    },
  },
}));

export function AddressBoxInputs({
  formData,
  defaultValue,
}: {
  formData: IUseFormData,
  defaultValue: any,
}) {
  const { register, errors, watch, setValue, getValues } = formData;
  const [userEnteredCep, setUserEnteredCep] = useState<boolean>(false);
  const [cepIntegrationError, setCepIntegrationError] = useState<boolean>(false);
  const [callCepIntegration, setCallCepIntegration] = useState<boolean>(false);
  const addressValue = getValues('address');
  const cepValue: string = getValues('cep');
  const complementValue: string = getValues('complement');
  const cityValue: string = getValues('city');
  const cepField: string = watch()?.cep;
  const {
    data: addressByCepIntegration,
  } = cepIntegrationService.getAddressByCEP(cepField);

  const classes = useStyles(); 
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (defaultValue?.cep && !userEnteredCep) return;
    if (cepField?.length !== 9) setCepIntegrationError(false);
    if (addressByCepIntegration && cepField?.length === 9) {
      const addressData = addressByCepIntegration?.data;

      if (addressData?.erro) {
        setCepIntegrationError(true);
      } else {
        setCepIntegrationError(false);
      }

      setValue('address', addressData.logradouro);
      setValue('uf', addressData.uf);
      setValue('city', addressData.localidade);
      setValue('complement', addressData.bairro);
    }
  }, [
    cepField,
    addressByCepIntegration,
    setValue,
    cepIntegrationError,
    callCepIntegration,
  ]);

  const handleCepChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserEnteredCep(true);
    setValue('cep', e.target.value);
    setCallCepIntegration(!callCepIntegration);
  };

  return (
    <>
      <InputTitle>Endereço</InputTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            variant="outlined"
            required
            id="cep"
            label="CEP"
            autoComplete="cep"
            style={isSmallScreen ? inputMUIDefaultStyles.normal : inputMUIDefaultStyles.large}
            error={!!errors.cep || cepIntegrationError}
            defaultValue={defaultValue?.cep}
            helperText={errors.cep ? errors.cep.message : (cepIntegrationError ? 'Cep inválido' : '')}
            {...register('cep', {
              ...cepValidation,
              validate: {
                ...cepValidation.validate,
                checkError: () => !cepIntegrationError || 'Cep inválido',
              },
            })}
            onChange={(e) => handleCepChange(e)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!cepValue,
            }}
            InputProps={{
              inputComponent: IMaskInputCEP as any,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            variant="outlined"
            required
            id="address"
            label="Endereço"
            autoComplete="address"
            style={isSmallScreen ? inputMUIDefaultStyles.normal : inputMUIDefaultStyles.large}
            error={!!errors.address}
            defaultValue={defaultValue?.address}
            helperText={errors.address ? errors.address.message : ''}
            {...register('address', addressValidation)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!addressValue,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.marginBottom}>
          <TextField
            variant="outlined"
            required
            id="numberHouse"
            label="Número"
            autoComplete="numberHouse"
            style={inputMUIDefaultStyles.normal}
            error={!!errors.numberHouse}
            helperText={errors.numberHouse ? errors.numberHouse.message : ''}
            defaultValue={defaultValue?.numberHouse}
            {...register('numberHouse', numberHouseValidation)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!getValues('numberHouse'),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.marginBottom2}>
          <TextField
            variant="outlined"
            id="complement"
            required
            label="Bairro"
            autoComplete="complement"
            style={inputMUIDefaultStyles.normal}
            error={!!errors.complement}
            helperText={errors.complement ? errors.complement.message : ''}
            defaultValue={defaultValue?.complement}
            {...register('complement')}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!complementValue,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={`${classes.marginBottom2} ${classes.marginTopOnLg}`} style={{ marginTop: '10px' }}>
          <TextField
            variant="outlined"
            required
            id="city"
            label="Cidade"
            autoComplete="city"
            style={inputMUIDefaultStyles.normal}
            error={!!errors.city}
            helperText={errors.city ? errors.city.message : ''}
            defaultValue={defaultValue?.city}
            {...register('city', cityValidation)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!cityValue,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.marginBottom}>
          <SelectInput
            title="Estado"
            isRequired
            list={statesList}
            style={inputMUIDefaultStyles.normal}
            defaultValue={defaultValue?.uf}
            formData={formData}
            propertieFormName="uf"
          />
        </Grid>
      </Grid>
    </>
  );
}
