import { DynamicResponse } from "./signupInterface";

/* eslint-disable-next-line no-shadow */
export enum GenderEnum {
  MALE = 'M',
  FEMALE = 'F',
  UNKNOWN = 'N',
}

export interface IRegistrationFormData {
  name: string,
  cpf?: string,
  birthDate?: string,
  gender: GenderEnum,
  DDD: string,
  phone: string,
  email: string,
  password: string,
  confirmPassword: string,
  patient: string,
  cep: string,
  address: string,
  numberHouse: string,
  complement?: string,
  city: string,
  uf: string,
  acceptSMS: boolean,
  acceptWhatsapp: boolean,
  acceptEmail: boolean,
  confirmation: boolean,
  terms: boolean,
  // eslint-disable-next-line
  user_dynamic_questions: DynamicResponse[],
}

// eslint-disable-next-line
export enum PatientFormDataPropertieNamesEnum {
  USER_DYNAMIC_QUESTIONS = 'user_dynamic_questions',
}

export type IDefaultValueRegistrationFormData = Omit<
  IRegistrationFormData,
  | 'terms'
  | 'confirmation'
  | 'acceptSMS'
  | 'acceptWhatsapp'
  | 'acceptEmail'
  | 'patient'
  | 'password'
  | 'confirmPassword'
> & {
  password?: string;
  confirmPassword?: string;
  oldPassword?: string;
};

export interface IUpdatedPatient {
  name: string,
  birthDate: string,
  gender: GenderEnum,
  cellphone: string,
  email: string,
  password?: string,
  confirmPassword?: string,
  oldPassword?: string
  cep: string,
  address: string,
  numberHouse: string,
  neighborhood?: string,
  city: string,
  uf: string,
  // eslint-disable-next-line
  user_dynamic_questions: DynamicResponse[],
}
