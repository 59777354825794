import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { InputTitle, inputLabelProps, inputMUIDefaultStyles } from '../styles';
import { DynamicFieldTypeEnum, DynamicResponse, IUseFormData, Question } from '../contracts';
import { SelectInput } from './SelectInput';
import { dynamicFieldsHelpers } from '../helpers';
import { FileUpload } from './FileUpload';
import { masksByCodeQuestion } from '../validations/custom';

export function DynamicFields({
  question,
  formData,
  mainFieldName,
  index,
  setAnswerCode,
  defaultValue,
}: {
  question: Question,
  formData: IUseFormData,
  mainFieldName: string,
  index: number,
  setAnswerCode?: React.Dispatch<React.SetStateAction<string>>,
  defaultValue?: DynamicResponse,
}) {
  const { register, setValue, getValues, watch } = formData;
  const [requiredCheckBox, setRequiredCheckBox] = useState<boolean>(false);
  const orderedAnswers = question.Respostas?.sort((a, b) => a.OrdemExibicao - b.OrdemExibicao);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const list = orderedAnswers?.map((value) => ({
    value: value.CodigoResposta.toString(),
    label: value.TituloExibicao,
  }));

  useEffect(() => {
    if (question.Obrigatoria && question.MultiplaEscolha) {
      const actualValue = getValues(`${mainFieldName}.${index}.CodeResponse`);

      if (actualValue?.length === 0 || !actualValue) {
        setRequiredCheckBox(true);
      } else {
        setRequiredCheckBox(false);
      }
    }
  }, [watch()]);

  useEffect(() => {
    if (question.TipoControle === DynamicFieldTypeEnum.TEXT_BOX) {
      if (defaultValue?.EnteredValue) {
        dynamicFieldsHelpers.onChangeTextField({
          value: defaultValue?.EnteredValue,
          codeQuestion: defaultValue?.CodeQuestion,
          lineCode: defaultValue?.CodeProductRule,
          codeResponse: defaultValue?.CodeResponse.toString(),
          setValue,
          fieldName: `${mainFieldName}.${index}`,
        });
      }
    }
    
    if (question.TipoControle === DynamicFieldTypeEnum.DROPDOWN_LIST) {
      if (defaultValue?.CodeResponse) {
        dynamicFieldsHelpers.onChangeSelectInput({
          value: defaultValue?.CodeResponse?.[0],
          codeQuestion: defaultValue?.CodeQuestion,
          lineCode: defaultValue?.CodeProductRule,
          setValue,
          fieldName: `${mainFieldName}.${index}`,
        });
      }
    }

    if (question.TipoControle === DynamicFieldTypeEnum.RADIO_BUTTON && !question.MultiplaEscolha) {
      if (defaultValue?.CodeResponse) {
        dynamicFieldsHelpers.onChangeSelectInput({
          value: defaultValue?.CodeResponse?.[0],
          codeQuestion: defaultValue?.CodeQuestion,
          lineCode: defaultValue?.CodeProductRule,
          setValue,
          fieldName: `${mainFieldName}.${index}`,
        });
      }
    }
  }, [defaultValue?.CodeResponse?.[0]]);

  if (question.TipoControle === DynamicFieldTypeEnum.TEXT_BOX) {
    return (
      <TextField
        required={question.Obrigatoria}
        variant="outlined"
        label={question.TituloExibicao}
        style={isSmallScreen ? inputMUIDefaultStyles.normal : inputMUIDefaultStyles.large}
        InputLabelProps={{
          ...inputLabelProps,
          shrink: !!defaultValue?.EnteredValue || getValues(`${mainFieldName}.${index}.EnteredValue`),
        }}
        InputProps={{
          inputComponent: masksByCodeQuestion[question.TituloExibicao] as any,
        }}
        defaultValue={defaultValue?.EnteredValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dynamicFieldsHelpers.onChangeTextField({
            value: e.target.value,
            codeQuestion: question.CodigoQuestao,
            lineCode: question.CodigoLinha,
            codeResponse: question.Respostas[0].CodigoResposta,
            setValue,
            fieldName: `${mainFieldName}.${index}`,
          });
        }}
      />
    );
  }

  if (question.TipoControle === DynamicFieldTypeEnum.DROPDOWN_LIST) {
    return (
      <SelectInput
        title={question.TituloExibicao}
        list={list}
        isRequired={question.Obrigatoria}
        style={isSmallScreen ? inputMUIDefaultStyles.normal : inputMUIDefaultStyles.large}
        formData={formData}
        propertieFormName={`${mainFieldName}.${index}.CodeResponse`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const selectedValue = e?.target?.value;
          if (setAnswerCode) setAnswerCode(selectedValue);
          dynamicFieldsHelpers.onChangeSelectInput({
            value: selectedValue,
            codeQuestion: question.CodigoQuestao,
            lineCode: question.CodigoLinha,
            setValue,
            fieldName: `${mainFieldName}.${index}`,
          });
        }}
      />
    );
  }

  if (question.TipoControle === DynamicFieldTypeEnum.RADIO_BUTTON && !question.MultiplaEscolha) {
    return (
      <>
        <FormControl required={false}>
          <InputTitle>
            {question.TituloExibicao}
          </InputTitle>
          <RadioGroup
            defaultValue={defaultValue?.CodeResponse?.[0]}
            onChange={(e) => {
              const radioValue = e?.target?.value;
              if (setAnswerCode) setAnswerCode(radioValue);
              dynamicFieldsHelpers.onChangeSelectInput({
                value: radioValue,
                codeQuestion: question.CodigoQuestao,
                lineCode: question.CodigoLinha,
                setValue,
                fieldName: `${mainFieldName}.${index}`,
              });
            }}
          >
            {
              list?.map((v, i) => (
                <FormControlLabel
                  key={i}
                  value={v.value}
                  control={
                    <Radio required={false} />
                  }
                  label={v.label}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      </>
    );
  }

  if (question.TipoControle === DynamicFieldTypeEnum.RADIO_BUTTON && question.MultiplaEscolha) {
    return (
      <>
        <FormControl required={question.Obrigatoria}>
          <InputTitle>
            {question.TituloExibicao}
          </InputTitle>
          <FormGroup
            onChange={(e: any) => {
              if (setAnswerCode) setAnswerCode(e?.target?.value);
              const checkboxValue = e.target.value;
              const { checked } = e.target;
              dynamicFieldsHelpers.onChangeCheckBox({
                value: checkboxValue,
                checked,
                setValue,
                getValues,
                fieldName: `${mainFieldName}.${index}`,
                codeQuestion: question.CodigoQuestao,
                lineCode: question.CodigoLinha,
              });
            }}
          >
            {list?.map((value, i) => (
              <FormControlLabel
                key={i}
                value={value.value}
                control={(
                  <Checkbox required={requiredCheckBox} />
                )}
                label={value.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </>
    );
  }

  if (question.TipoControle === DynamicFieldTypeEnum.UPLOAD_FILE) {
    return (
      <>
        <FileUpload
          questionCode={question.CodigoQuestao}
          required={question.Obrigatoria}
          label={question.TituloExibicao}
          description={question.DicaQuestao}
          onChange={(fileContentValue: string, fileNameValue: string) => {
            dynamicFieldsHelpers.onChangeFileUpload({
              fileContentCodeResponse: question.Respostas[0].CodigoResposta,
              fileContentValue,
              fileNameCodeResponse: question.Respostas[1].CodigoResposta,
              fileNameValue,
              codeQuestion: question.CodigoQuestao,
              lineCode: question.CodigoLinha,
              setValue,
              fieldName: `${mainFieldName}.${index}`,
            });
          }}
        />
      </>
    );
  }

  return <></>;
}
