/* eslint-disable */

import React from 'react';
import { Typography, Container, Box, Grid } from '@material-ui/core';

import styles from './RegulamentPage.module.css';

const RegulamentPage: React.FC<any> = (): JSX.Element => {
  return (
    <>
      <Container>
        <Box my={4} py={3}>
          <Grid container spacing={2}>
            <Typography variant="h1" className={styles.PageTitle}>
              Regulamento do programa
            </Typography>
            <Typography variant="body2" style={{ color: '#808080', width: '100%' }}>
              Versão: Maio/2024
            </Typography>

            <hr style={{ border: '1px solid lightgray', margin: '24px 0', width: '100%' }} />

            <Typography variant="body2">
              Bem-Vindo! Este Regulamento tem como objetivo apresentar a você as regras para participação no Programa de Suporte ao Paciente Abraçar a Vida (“Programa Abraçar a Vida”), oferecido pela Boehringer Ingelheim do Brasil Química e Farmacêutica LTDA, com sede na Av. das Nações Unidas, 14.171 – 18º andar, Vila Gertrudes, São Paulo – SP – CEP 04794-000, devidamente inscrita no CNPJ/MF sob nº 60.831.658/0001-770 objetivo do Programa é permitir uma melhor adesão ao tratamento pelos pacientes por meio da disseminação de informações sobre saúde e bem-estar e pela facilitação do acesso a medicamentos e concessão de descontos na aquisição de medicamentos. Ao se cadastrar gratuitamente no Programa Abraçar a Vida, você declara ter lido, entendido e concordado com regras estipuladas neste Regulamento.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              REGULAMENTO O QUE É O PROGRAMA:
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              A Boehringer Ingelheim é uma empresa guiada pelo desejo de servir à humanidade por meio do aprimoramento da saúde humana e animal. Nós nos sentimos responsáveis pela comunidade em que estamos inseridos e, por isso, desenvolvemos o Programa Abraçar a Vida, com vistas a, gratuitamente, disseminar conhecimento e educação sobre doenças e pela oferta de produtos e serviços relacionados à saúde e bem-estar. O conteúdo do Programa é de natureza meramente informativa, e não deve substituir a relação médico–paciente, e em hipótese alguma, deve ser considerado como diagnóstico ou qualquer tipo de aconselhamento médico.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              I. COMO PARTICIPAR
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              Condições necessárias para a participação no Programa Abraçar a Vida:<br/>
              <br/>
              Após receber a receita do profissional da saúde com prescrição de um medicamento Boehringer incluído no Programa, o participante deve efetuar seu cadastramento no Programa Abraçar a Vida, por meio um dos canais abaixo:<br/>
              <br/>
              <b>a.</b> website Programa Abraçar a Vida (<a href="https://www.boehringer-ingelheim.com/">https://www.programaabracarvida.com.br/</a>)<br/>
              <b>b.</b> Central de Atendimento Telefônico, número <a href="tel:08007016633">0800 701 6633</a>. Ao se cadastrar no Programa, a Boehringer solicitará alguns Dados Pessoais, inclusive de saúde, cujo fornecimento será fundamental para permitir a adesão e o correto funcionamento do Programa, conforme descrito neste Regulamento.<br/>
              <br/>
              Os dados a serem coletados são os seguintes:<br/>
              <br/>
              <b>Dados de cadastro</b><br/>
              <br/>
              <b>a.</b> Nome Completo;<br/>
              <b>b.</b> Data de Nascimento;<br/>
              <b>c.</b> CPF;<br/>
              <b>d.</b> Número de Telefone e/ou endereço de e-mail;<br/>
              <b>e.</b> Endereço.<br/>
              <br/>
              <b>Dados de saúde:</b><br/>
              <br/>
              <b>f.</b> Nome e apresentação do medicamento Boehringer prescrito;<br/>
              <b>g.</b> Número CRM do médico prescritor;<br/>
              <br/>
              Após o aceite das regras estabelecidas no presente Regulamento e a finalização do cadastro, o participante receberá, pelos canais informados, materiais educativos, ofertas de produtos e serviços e terá acesso a descontos para aquisição do medicamento prescrito nas redes de farmácias e drogarias credenciadas pelo Programa, mediante apresentação do número de seu CPF.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              II. TERMOS E CONDIÇÕES
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              <b>1.</b> Os dados fornecidos a qualquer tempo ao Programa (na adesão, na atualização ou a qualquer outro momento) pelo participante são de sua total responsabilidade, sendo este também responsável por garantir a sua veracidade e exatidão e atualização.<br/>
              <b>2.</b> As informações solicitadas no cadastramento serão utilizadas para assegurar o correto acesso aos benefícios do Programa, bem como para envio de comunicações, materiais, ofertas de produtos e serviços correlatos ao funcionamento do programa, saúde, bem-estar e sobre o medicamento Boehringer ao qual o cadastro está vinculado.<br/>
              <b>3.</b> Somente será aceito 1(um) participante por Cadastro de Pessoa Física ativo do Ministério da Economia (“CPF”).<br/>
              <b>4.</b> O participante poderá cancelar sua participação no Programa a qualquer momento e sem necessidade de aviso prévio ligando para o canal <a href="tel:08007016633">0800 701 6633</a>.<br/>
              <b>5.</b> O participante poderá ser excluído do Programa caso preste informações falsas, hipótese na qual deverá responsabilizar-se, nos termos da Lei, pelas informações fornecidas.<br/>
              <b>6.</b> A Boehringer Ingelheim do Brasil não cadastra participantes menores de 18 anos de idade, exceto se o medicamento possuir indicação para pacientes para desta faixa etária.<br/>
              <b>7.</b> Este programa tem como data de início de sua vigência junho de 2022 e vigorará por prazo indeterminado, podendo a Boehringer Ingelheim alterá-lo, suspendê-lo ou encerrá-lo a qualquer momento mediante comunicado aos participantes por meio do site: Programa Abraçar a Vida (<a href="https://www.boehringer-ingelheim.com/">https://www.programaabracarvida.com.br/</a>).<br/>
              <b>8.</b> Ao se cadastrar, o participante adere aos Termos e Condições descritos neste regulamento e às cláusulas de Compartilhamento de Informações e Política de Privacidade.<br/>
              <b>9.</b> Para obter os benefícios de desconto no momento da compra de medicamento, o participante deverá se dirigir até uma rede credenciada e mediante a apresentação da prescrição médica, informar o número de seu CPF.<br/>
              <b>10.</b> Os descontos do Programa são aplicados sobre o preço de venda ao consumidor vigente no momento da compra.<br/>
              <b>11.</b> Caso o participante queira desistir da sua adesão ao programa, basta deixar de utilizar seu CPF na rede credenciada, sem necessidade de qualquer aviso, verbal ou por escrito, à Boehringer Ingelheim do Brasil.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              III. CONFIDENCIALIDADE E PROTEÇÃO DOS DADOS PESSOAIS
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              Para permitir o correto funcionamento do Programa Abraçar a Vida, a Boehringer Ingelheim realiza atividades de tratamento de Dados Pessoais e de Dados Pessoais Sensíveis de seus participantes. Por isso, a leitura do presente tópico é importante para esclarecer o contexto de tratamento de Dados Pessoais e a importância que a Boehringer Ingelheim dedica à privacidade das informações. No contexto do Programa Abraçar a Vida, todas as atividades de tratamento de Dados Pessoais ou de tratamento de Dados Pessoais Sensíveis, serão fundamentadas em uma das bases legais previstas no 7º, para o tratamento de Dados Pessoais, ou no Art. 11, para o tratamento de Dados Pessoais Sensíveis, ambos da Lei Geral de Proteção de Dados – LGPD, (Lei Federal n.º 13.709/2018), assim como respeitarão os princípios previstos no artigo 6º dessa mesma lei. a saber:<br/>
              <br/>
              <b>Princípios:</b><br/>
              <b>I</b> – finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao titular, sem possibilidade de tratamento posterior de forma incompatível com essas finalidades;<br/>
              <b>II</b> – adequação: compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o contexto do tratamento;<br/>
              <b>III</b> – necessidade: limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do tratamento de dados;<br/>
              <b>IV</b> – livre acesso: garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do tratamento, bem como sobre a integralidade de seus dados pessoais;<br/>
              <b>V</b> – qualidade dos dados: garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;<br/>
              <b>VI</b> – transparência: garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a realização do tratamento e os respectivos agentes de tratamento, observados os segredos comercial e industrial;<br/>
              <b>VII</b> – segurança: utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão;<br/>
              <b>VIII</b> – prevenção: adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados pessoais;<br/>
              <b>IX</b> – não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou abusivos;<br/>
              <b>X</b> – responsabilização e prestação de contas: demonstração, pelo agente, da adoção de medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive, da eficácia dessas medidas.<br/>
              <br/>
              As informações pessoais fornecidas pelos participantes do Programa Abraçar a Vida, serão utilizadas para fins de:<br/>
              – Participação no Programa Abraçar a Vida e acesso aos benefícios ali previstos;<br/>
              – Envio de comunicações, avisos, materiais educativos contendo dicas de saúde e bem-estar, benefícios do produto, promoções e ofertas de descontos;<br/>
              – Oferta de produtos e serviços de Saúde e Bem-Estar;<br/>
              – Prevenção a fraudes e garantia de segurança ao titular dos dados;<br/>
              – Pesquisas exclusiva sobre os nossos produtos e sobre o Programa Abraçar a Vida para seu constante aprimoramento;<br/>
              – Definição de estratégias de marketing e relacionamento com os pontos de venda e profissionais de saúde;<br/>
              – Relatórios estatísticos de comportamento de adesão e compra;<br/>
              – Histórico de compras de medicamentos dentro do programa;<br/>
              – Cumprimento de obrigações regulatórias (relatos de eventos adversos e queixas técnicas);<br/>
              <br/>
              De acordo com as finalidades acima expostas e considerando que no âmbito do Programa Abraçar a Vida serão tratados Dados Pessoais e Dados Pessoais Sensíveis, as atividades de tratamento de Dados Pessoais se fundamentarão nas seguintes bases legais:<br/>
              <b>I</b> – Consentimento (Art. 11, I, LGPD);<br/>
              <b>II</b> – Exercício Regular de Direitos, inclusive em contrato (Art. 11, II, “d”);<br/>
              <b>III</b> – Exercício Regular de Direitos em processo judicial, administrativo ou arbitral (Art. 7º, VI);<br/>
              <b>IV</b> – Legítimo Interesse (Art. 7º, IX);<br/>
              <b>V</b> – Cumprimento de obrigação legal ou regulatória (Art. 7º, II e Art. 11, II, “a”);<br/>
              <b>VI</b> – outras bases legais eventualmente aplicáveis, levando em conta as especificidades do caso concreto e com fundamento nos artigos 7º ou 11 da LGPD.<br/>
              <b>2.</b> Na qualidade de Controladora dos Dados Pessoais tratados pelo Programa Abraçar a Vida, a Boehringer Ingelheim informa que não comercializa nenhuma informação pessoal, bem como que apenas tratará Dados Pessoais sob um fundamento legal adequado. A Boehringer Ingelheim através de terceiros se responsabiliza pela manutenção da base de dados do Programa em um ambiente seguro de acesso restrito, garantindo sua confidencialidade, integridade e disponibilidade.<br/>
              <b>3.</b> A qualquer momento, e dentro dos limites legais, você, como titular dos dados, poderá, por meio do nosso canal de atendimento disponível em (DPOBrasil.BR@boehringer-ingelheim.com), promover solicitações e exercer os direitos a seguir listados, conforme previsto na LGPD:<br/>
              – confirmação da existência de tratamento dos Dados Pessoais;<br/>
              – acesso aos Dados Pessoais;<br/>
              – correção de dados incompletos, inexatos ou desatualizados;<br/>
              – anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto em Lei;<br/>
              – portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;<br/>
              – eliminação dos Dados Pessoais tratados com o consentimento do Titular;<br/>
              – informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;<br/>
              – informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;<br/>
              – revogação do consentimento, sem que isso implique em retirada da validade do tratamento realizado enquanto o consentimento estava vigente. O participante entende e se declara ciente de que o exercício dos direitos acima descritos poderá, eventualmente, impossibilitar o correto funcionamento do Programa e, portanto, poderá acarretar cancelamento da sua participação no Programa Abraçar a Vida e revogação dos benefícios nele previstos. Qualquer uma das solicitações pode ser feita por meio do canal <a href="tel:08007016633">0800 701 6633</a> ou mediante envio de e-mail a DPOBrasil.BR@boehringer-ingelheim.com.<br/>
              <b>4.</b> Quaisquer mudanças nos termos, dados coletados ou finalidades relacionadas ao tratamento de dados pessoais serão comunicadas no regulamento do programa e estarão disponíveis no site Programa Abraçar a Vida (<a href="https://www.boehringer-ingelheim.com/">https://www.programaabracarvida.com.br/</a>).<br/>
              <b>5.</b> Caso o Usuário pretenda obter maiores detalhes a respeito de como a BI realiza o tratamento de seus Dados Pessoais, deverá acessar o Aviso de Privacidade.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              IV. COMPARTILHAMENTO DAS INFORMAÇÕES
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              Para a execução adequada deste programa, seus dados precisarão ser compartilhados com empresas terceiras, que garantem a total privacidade e a proteção dos Dados Pessoais e individuais do paciente, aplicando padrões de mercado para a certificação da segurança dos mesmos durante o armazenamento e compartilhamento dessas informações. A lista de terceiros está disponível no site Programa Abraçar a Vida. Os seus dados serão compartilhados com o seu médico através da área logada da plataforma de atendimento, com a finalidade de acompanhar a sua evolução no tratamento. VI. Aviso de privacidade específico para farmacovigilância Se você relatar eventos adversos ou outras informações relevantes de farmacovigilância referentes a um produto da BI, usaremos e compartilharemos esses dados apenas para fins de farmacovigilância (Farmacovigilância é a detecção, avaliação, entendimento e prevenção dos eventos adversos ou qualquer outro problema relacionado ao medicamento.) Todos os relatos serão compartilhados com a Boehringer Ingelheim International GmbH, que opera o banco de dados global de farmacovigilância. A BI é legalmente obrigada a relatar informações relevantes de farmacovigilância às autoridades de saúde em todo o mundo.<br/>
              <br/>
              Os relatos conterão detalhes sobre a ocorrência, com Dados Pessoais limitados:<br/>
              • Para os pacientes, o relato conterá apenas idade, sexo e iniciais, conforme fornecido, mas nunca o nome do paciente.<br/>
              • Para os indivíduos que relataram, o relato poderá incluir o nome, profissão (por exemplo, médico, farmacêutico), iniciais, endereço, e-mail e número de telefone, conforme fornecido.<br/>
              <br/>
              As informações de contato são necessárias para poder acompanhar o relato e obter alta qualidade e informações completas sobre eventos adversos. Se o relator não desejar fornecer seus dados de contato à Boehringer Ingelheim ou às autoridades, o termo “Privacidade” será inserido nos campos aplicáveis, ou os mesmos serão deixados em branco, conforme legislação vigente. Nos casos em que seus dados forem compartilhados com outras empresas, parceiros de negócios ou prestadores de serviços da BI fora do Brasil, forneceremos uma proteção adequada aos Dados Pessoais a fim de manter sua confidencialidade, integridade e disponibilidade, em respeito às diretrizes da LGPD, e em observância a orientações e regulamentações provenientes da Autoridade Nacional de Proteção de Dados – ANPD. Como os relatos sobre eventos adversos são importantes por razões de saúde pública, eles são mantidos em nosso banco de dados pelo período necessário, conforme estabelecido em legislação específica.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              V. DISPOSIÇÕES GERAIS
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              <b>1.</b> A Boehringer reserva-se o direito de alterar este Regulamento a qualquer momento, sem aviso prévio. A versão atualizada deste Regulamento sempre estará disponível no site: <a href="https://www.boehringer-ingelheim.com/">https://www.programaabracarvida.com.br/</a>.<br/>
              <b>2.</b> Cabe ao médico indicar o tratamento que julgar adequado aos seus pacientes. Em hipótese alguma a Boehringer Ingelheim abordará o médico para concessão de qualquer tipo de benefício como contrapartida de prescrição de produtos da saúde.<br/>
              <b>3.</b> A existência do Programa Abraçar a Vida beneficia exclusivamente os pacientes que atendam aos requisitos expressos nesse Regulamento e não deve possuir influência sobre o registro, aquisição ou potencial aquisição, indicação ou prescrição de quaisquer produtos de qualquer Indústria, sendo respeitada a independência e autonomia dos profissionais médicos e dos serviços de saúde pela determinação dos melhores procedimentos em razão do quadro clínico de cada paciente.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              VI – LEI APLICÁVEL E JURISDIÇÃO
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              Este Regulamento rege-se pela legislação da República Federativa do Brasil, ficando eleito o foro do domicílio do Participante, desde que localizado em território brasileiro, para dirimir quaisquer controvérsias.
            </Typography>

            <Typography variant="h1" className={styles.ParagraphTitle}>
              VI – DÚVIDAS
            </Typography>
            <Typography variant="body2" style={{ width: '100%' }}>
              Caso tenha dúvidas a respeito do Programa Abraçar a Vida, entre em contato com a BI no telefone <a href="tel:08007016633">0800 701 6633</a>.
            </Typography>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default RegulamentPage;
