import React, { useEffect } from 'react';
import { DynamicResponse, IUseFormData, Question } from '../contracts';
import { DynamicFields } from './DynamicFields';
import { Box } from '../styles';
import { PatientFormDataPropertieNamesEnum } from '../../signup/contracts';

export function NestedQuestions({
  allQuestions,
  question,
  answerCode,
  formData,
  mainFieldName,
  defaultValue,
}: {
  allQuestions: Question[],
  question: Question,
  answerCode: string,
  formData: IUseFormData,
  mainFieldName: string,
  defaultValue?: DynamicResponse[],
}) {
  const { setValue, getValues } = formData;
  const hasNestedQuestion = question.QuestaoAninhada 
  && question.QuestaoAninhada.some(nested =>
    nested.CodigoRespostaPai.some(codigo => codigo.toString() === answerCode?.toString())
  );

  const nestedQuestions = allQuestions
    .filter((value) => {
      console.log(value.CodigoQuestaoPai)
      return value.CodigoQuestaoPai == question.CodigoQuestao
    });

  useEffect(() => {
    if (hasNestedQuestion) return;

    const nestedCodeQuestions = nestedQuestions?.map((v) => v.CodigoQuestao.toString());
    const userDynamicQuestions: DynamicResponse[] = getValues(
      PatientFormDataPropertieNamesEnum.USER_DYNAMIC_QUESTIONS,
    );
    const values: DynamicResponse[] = userDynamicQuestions?.filter((v) => {
      const condition = nestedCodeQuestions.includes(v.CodeQuestion);
      return !condition;
    });

    setValue(PatientFormDataPropertieNamesEnum.USER_DYNAMIC_QUESTIONS, values);
  }, [answerCode]);

  if (!hasNestedQuestion) return <></>;

  return (
    <>
      {
        nestedQuestions?.map((value, index) => (
          <Box key={index}>
            <DynamicFields
              question={value}
              formData={formData}
              mainFieldName={mainFieldName}
              index={index + 50} // It's necessary to create a different object
              defaultValue={
                defaultValue?.find((response) => (
                  response.CodeQuestion.toString() === value.CodigoQuestao.toString()
                ))
              }
            />
          </Box>
        ))
      }
    </>
  );
}
