import React, { useState, useEffect } from 'react';
import { Typography, Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel, Grid, Box, Container, TableContainer, useMediaQuery, Theme, Paper, IconButton, TextField, Button, CircularProgress } from '@material-ui/core';
import { Pencil, TrashCan } from 'mdi-material-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { useAlert } from '../../../../contexts/patient-portal/Alert.context';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';
import { RoutesEnum } from '../../../../constants';
import envConfig from '../../../../config/environment-config';
import { medicinesService } from '../../../../services';
import { localStorageMethods } from '../../common/helpers';
import { IFindMedicinesByPatient, IPatientCrendentials } from '../../common/contracts';
import { LocalStorageKeysEnum } from '../../common/contracts';
import { inputLabelProps } from '../../common/styles';

export function MedicineList(): JSX.Element {
  const history = useHistory();
  const { showAlert } = useAlert();
  const { showDialogue, hideDialogue } = useDialogue();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
  const location = useLocation();
  const addSufix = (route: string, sufix: string): string => {
    return route.endsWith(sufix) ? route : `${route}${sufix}`;
  };

  const sufix = '/unimed';

  const handleAddMedicine = () => {
    history.push(patientCredentials?.isUnimed ? addSufix(RoutesEnum.PATIENT_MEDICATION_ADD_ROUTE, sufix) : RoutesEnum.PATIENT_MEDICATION_ADD_ROUTE);
  };

  const handleEdit = (medicineId: string) => {
    history.push(patientCredentials?.isUnimed ? addSufix(`/panel/my-medicines/edit/${medicineId}`, sufix) : `/panel/my-medicines/edit/${medicineId}`);
  };

  const pathname = location.pathname;
  const { data: allMedicines } = medicinesService.findManyByProgramCode(envConfig.boehringerProgramCode, pathname);
  const { data: medicinesByPatient, isLoading: isLoadingMedicinesByPatient } = medicinesService.findManyByPatient(
    envConfig.boehringerProgramCode,
    patientCredentials?.healthPassportId || '',
  );
  const medicines = medicinesByPatient?.data || [];

  const handleDelete = (medicineId: string) => {
    const onConfirm = () => {
      try {
        // TODO: call back end delete function
        const success = true;
        if (success) {
          showAlert('Exclusão de medicamento', 'Medicamento excluído com sucesso', 'success');
        } else {
          throw new Error('Erro ao excluir medicamento');
        }
      } catch (error) {
        showAlert('Exclusão de medicamento', 'Erro ao excluir medicamento', 'error');
      } finally {
        hideDialogue();
      }
    };

    showDialogue('Exclusão de medicamento', 'Deseja realmente excluir este medicamento?', 'warning', 'Sim', 'Não', onConfirm, hideDialogue);
  };

  const columns = [
    { id: 'name', label: 'Medicamento', useSort: true },
    { id: 'startDate', label: 'Data de início', useSort: false },
    { id: 'endDate', label: 'Data de término', useSort: false },
    { id: 'prescriber', label: 'Prescritor', useSort: true },
  ];

  // Sort function
  const sortedMedicines = React.useMemo(() => {
    const sortableItems: any = [...medicines];
    if (sortConfig !== null) {
      sortableItems.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [medicines, sortConfig]);

  // Sort handler
  const requestSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // eslint-disable-next-line
  const filteredMedicines = sortedMedicines.filter((medicine: any) => medicine.name.toString().toLowerCase().includes(searchTerm.toLowerCase()));

  if (isLoadingMedicinesByPatient) {
    return (
      <div style={{ margin: '200px auto', marginLeft: '48%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container>
      <Box my={4} py={3}>
        <Grid container spacing={2}>
          <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
            Meus medicamentos
          </Typography>
          <hr style={{ border: '1px solid lightgray', margin: '24px 0' }} />

          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} lg={8}>
              <TextField
                label="Encontre seus medicamentos"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputLabelProps={inputLabelProps}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddMedicine}
                size="large"
                fullWidth
                style={!isMobile ? { height: '100%', marginTop: '-2px' } : {}}
              >
                Adicionar medicamento
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Box} style={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '8px', width: '100%', overflow: 'auto' }}>
            <Table style={{ minWidth: isMobile ? 200 : 650 }} aria-label="Meus medicamentos">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.useSort && (
                        <TableSortLabel
                          active={sortConfig?.key === column.id}
                          direction={sortConfig?.direction === 'descending' ? 'desc' : 'asc'}
                          onClick={() => requestSort(column.id)}
                        >
                          <b>{column.label}</b>
                        </TableSortLabel>
                      )}
                      {!column.useSort && <b>{column.label}</b>}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMedicines?.map((medicine: IFindMedicinesByPatient, index: number) => (
                  <TableRow key={medicine.name}>
                    <TableCell>
                      {/* <IconButton
                        onClick={() => handleEdit(medicine.id.toString())}
                      >
                        <Pencil />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(medicine.id.toString())}
                      >
                        <TrashCan />
                      </IconButton> */}
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ borderBottom: index === medicines.length - 1 ? 'none' : '' }}>
                      {medicine.name} {medicine.description ? ` (${medicine.description})` : ''}
                    </TableCell>
                    <TableCell style={{ borderBottom: index === medicines.length - 1 ? 'none' : '' }}>{medicine.startDate ?? '-'}</TableCell>
                    <TableCell style={{ borderBottom: index === medicines.length - 1 ? 'none' : '' }}>{medicine.endDate ?? '-'}</TableCell>
                    <TableCell style={{ borderBottom: index === medicines.length - 1 ? 'none' : '' }}>{medicine.prescriber ?? '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Container>
  );
}
