import React, { useState } from 'react';
import { AddMoreText } from '../../common/styles';
import { PasswordInput } from './PasswordInput';
import { IUseFormData } from '../../common/contracts';

const InputsBoxStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '20px',
  width: '100%',
};

const PasswordInputStyle: React.CSSProperties = {
  width: '10%',
};

export function PasswordBoxInputs({ formData }: { formData: IUseFormData }) {
  return (
    <>
      <div style={InputsBoxStyle}>
        <PasswordInput
          title="Digite sua senha"
          helperText="Deve ter pelo menos 6 caracteres"
          formData={formData}
          propertieFormName="password"
          style={PasswordInputStyle}
        />
        <PasswordInput
          title="Confirme sua senha"
          formData={formData}
          propertieFormName="confirmPassword"
          style={PasswordInputStyle}
        />
      </div>
    </>
  );
}

export function UpdatePasswordBoxInputs({ formData }: { formData: IUseFormData }) {
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const { unregister } = formData;

  return (
    <>
      <AddMoreText
        onClick={() => {
          setEditPassword(!editPassword);
          if (editPassword) {
            unregister('password');
            unregister('oldPassword');
            unregister('confirmPassword');
          }
        }}
      >
        {editPassword ? 'Desfazer alterações de senha' : 'Clique aqui pra editar sua senha '}
      </AddMoreText>
      {
        editPassword
          ? (
            <>
              <div style={InputsBoxStyle}>
                <PasswordInput
                  title="Digite sua senha antiga"
                  formData={formData}
                  propertieFormName="oldPassword"
                />
                <PasswordInput
                  title="Digite sua nova senha"
                  helperText="Deve ter pelo menos 6 caracteres"
                  formData={formData}
                  propertieFormName="password"
                />
                <PasswordInput
                  title="Confirme sua nova senha"
                  formData={formData}
                  propertieFormName="confirmPassword"
                />
              </div>
            </>
          )
          : null
      }
    </>
  );
}
