import React, { useState } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

interface IInputTableProps {
  data: Array<{
    tradeName: string;
    address: string;
    phone: string;
  }>;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 16,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      padding: '10px 12px',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    tradeName: {
      color: '#1570EF',
      fontSize: 16,
      fontWeight: 'bold',
    },
    button: {
      backgroundColor: '#FFFFFF',
      color: '#1570EF',
      border: '2px solid #1570EF',
      borderRadius: '8px',
      fontWeight: 'bold',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  }),
);

export const InputTable: React.FC<IInputTableProps> = ({ data }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Farmácias</StyledTableCell>
              <StyledTableCell>Endereço</StyledTableCell>
              <StyledTableCell>Telefone</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
              const endereco = item.address.replace(/\s+/g, '+');
              const url = `https://www.google.com/maps?q=${endereco}`;
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell className={classes.tradeName}>{item.tradeName}</StyledTableCell>
                  <StyledTableCell>{item.address}</StyledTableCell>
                  <StyledTableCell>{item.phone}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      className={classes.button}
                      variant="contained"
                      onClick={() => window.open(url, '_blank')}
                    >
                      Exibir mapa
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
