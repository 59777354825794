import { IAlertMessage } from '../../common/contracts';
import { captchaError } from '../../common/helpers';

export const medicineFormAlertMessages: IAlertMessage = {
  success: {
    title: 'Paciente Elegível',
    subTitle: 'O paciente atende aos critérios de elegibilidade. Você será redirecionado para a próxima etapa do processo.',
  },
  error: {
    title: captchaError.title,
    subTitle: captchaError.subTitle,
  },
};
