import { BOEHRINGER_PROGRAM_CODE } from '../../../../../constants';
import { IAccreditedLocationParams, IAccreditedFormData } from '../../contracts';

export const checkExactLength = (value: string, length: number, message?: string) => (value.length === length) || `${message ?? ' '}`;

export const createRequiredText = (fieldName?: string) => (fieldName ? `${fieldName} é obrigatório` : 'Obrigatório');

export const checkOnlyNumber = (value: string, message?: string) => (!Number.isNaN(Number(value))) || `${message ?? 'Somente números'}`;

export const prepareData = ({ accreditedFormData }: { accreditedFormData: IAccreditedFormData }): IAccreditedLocationParams => ({
  programCode: 1,
  state: accreditedFormData.uf,
  cityCode: Number(accreditedFormData.city),
  neighborhoodCode: Number(accreditedFormData.neighborhood),
});