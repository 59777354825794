import React, { useState, ReactElement, ReactNode } from 'react';
import { AlertConfig } from '../../types/AlertConfig';
import styles from './PortalPatientLayout.module.css';

interface PortalPatientLayoutProps {
  children: ReactNode;
}

const PortalPatientLayout: React.FC<PortalPatientLayoutProps> = ({ children }) => {
  const [configAlert] = useState<AlertConfig | null>(null);

  return (
    <div className={styles.BgTop}>
      <main id="portal-patient-login">
        { children }
      </main>
    </div>
  );
};

export default PortalPatientLayout;
