import { AxiosResponse } from 'axios';
import { SignupPatientParams } from '../pages/portal-patient/signup/contracts';
import { authenticatedPostRequest } from './authenticatedRequestService';
import { IAuthUser } from '../pages/portal-patient/common/contracts';
import envConfig from '../config/environment-config';

function getOriginCode(pathname?: string): number {
  return pathname && pathname.includes('/unimed') ? envConfig.originCodeUnimed : envConfig.originCode;
}

async function resgister(body: SignupPatientParams, pathname?: string): Promise<AxiosResponse<IAuthUser, any>> {
  const method = 'patients/signup';
  const requestBody = {
    ...body,
    originCode: getOriginCode(pathname),
  };

  return await authenticatedPostRequest(
    method,
    requestBody,
  );
}

export const signupPatientService = {
  resgister,
};
