import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';
import { GetPatient, UpdatePatientParams } from '../pages/portal-patient/signup/contracts/signupInterface';
import { authenticatedPostRequest } from './authenticatedRequestService';
import envConfig from '../config/environment-config';

function getOriginCode(pathname?: string): number {
  return pathname && pathname.includes('/unimed') ? envConfig.originCodeUnimed : envConfig.originCode;
}

function getPatientData(
  programCode: number,
  healthPassportId: string,
  setCallApi: React.Dispatch<React.SetStateAction<boolean>>,
): UseQueryResult<AxiosResponse<GetPatient, any>, unknown> {
  const method = 'patients/ConsultPatient';
  const body = {
    programCode,
    healthPassportId,
  };
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 10000; //ms

  return useQuery(
    [LocalStorageKeysEnum.PATIENT_DATA, healthPassportId],
    async () => await authenticatedPostRequest(
      method,
      body,
    ),
    {
      onSuccess: (data) => setCallApi(true),
      staleTime,
      cacheTime
    }
  );
}

async function updatePatientData(body: UpdatePatientParams, pathname?: string) {
  const method = 'patients/update';
  const requestBody = {
    ...body,
    originCode: getOriginCode(pathname),
  };

  return await authenticatedPostRequest(
    method,
    requestBody,
  );
}

export const patientDataService = {
  getPatientData,
  updatePatientData,
};
