import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { ICepIntegration, LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';
import { authenticatedGetRequest } from './authenticatedRequestService';

function getAddressByCEP(
  cep: string,
): UseQueryResult<AxiosResponse<ICepIntegration, any>, unknown> {
  const method = 'integration/get-address-by-cep';
  const cepLengthWithPontuation = 9;
  const params = `/${cep}`;
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.CEP_INTEGRATION, cep],
    () => authenticatedGetRequest(
      method,
      params,
    ),
    {
      enabled: cep?.length === cepLengthWithPontuation,
      staleTime,
      cacheTime,
    },
  );
}

export const cepIntegrationService = {
  getAddressByCEP,
};
