import { IAlertMessage } from '../../common/contracts';

export const createUserFormAlertMessages: IAlertMessage = {
  success: {
    title: 'Cadastro realizado.',
    subTitle: 'Bem vindo ao programa, você será redirecionado para pagina de login.',
    status: 'success',
  },
  defaultError: {
    title: 'Ocorreu um erro.',
    subTitle: 'Não foi possível realizar o seu cadastro.',
    status: 'error',
  },
  emailDifferent: {
    title: 'Ocorreu um erro.',
    subTitle: 'Email não condiz com o cadastro, para altera-lo entre em contato com o numero - (11) 99202-0448.',
    status: 'error',
  },
};
