import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '64vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '50px auto',
    }
  },
  icon: {
    fontSize: '5rem',
    marginX: '2rem',
    color: 'var(--primary-main)',
  },
  link: {
    textDecoration: 'none',
    color: 'var(--primary-main)',
    fontWeight: 'bold',
  },
}));

const ExternalPage: React.FC<{ linkUrl: string }> = ({ linkUrl }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <ExitToAppIcon className={classes.icon} />
      <Typography variant="h4" component="h1" gutterBottom>
        Conteúdo Externo
      </Typography>
      <Typography variant="body1" component="p">
        Esse conteúdo está disponível em outro site, para acessá-lo clique aqui:{' '}
        <a href={linkUrl} className={classes.link} target="_blank" rel="noopener noreferrer">
          Perguntas Frequentes.
        </a>
      </Typography>
    </Container>
  );
};

export default ExternalPage;
