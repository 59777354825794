import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    /* width */
    '::-webkit-scrollbar': {
      width: '3px',
    },

    /* Track */
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },

    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    flex: 1,
    padding: 30,
  },
  flex: {
    flex: 1,
  },
  footer: {
    background: '#282929',
  },
  mainLayout: {
    background: '#fcfcfc',
    display: 'flex',
    height: 'calc(100vh - 50px)',
  },
  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // height: '100vh',
    overflowX: 'hidden',
    // padding: theme.spacing(2, 0),
    marginTop: theme.mixins.toolbar.minHeight,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: 72,
    [theme.breakpoints.up('sm')]: {
      height: 100,
    },
  },
}));
