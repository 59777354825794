import React from 'react';
import { Box, InputsBox, inputMUIDefaultStyles } from '../styles/FormStyles';
import { SelectInput } from './SelectInput';
import { IMedicine, IUseFormData } from '../contracts';

export function MedicineBoxInput({
  index,
  handleSetSelectedMedicine,
  formData,
  showMedicine,
  medicinsList,
}: {
  index: number,
  handleSetSelectedMedicine: (selectedValue: string | undefined) => void,
  formData: IUseFormData,
  showMedicine: boolean,
  medicinsList?: IMedicine[],
}) {
  const list = medicinsList?.map((medicine) => ({
    value: medicine.lineCode.toString(),
    label: medicine.description,
    data_name: medicine.lineDescription,
  })) || [];

  const propertieFormNames = {
    codeProductRule: `medication.${index}.CodeProductRule`,
  };

  return (
    <Box>
      <InputsBox style={{ display: showMedicine ? 'initial' : 'none' }}>
        <SelectInput
          title="Selecione o medicamento prescrito"
          list={list}
          style={inputMUIDefaultStyles.large}
          formData={formData}
          propertieFormName={propertieFormNames.codeProductRule}
          isRequired
          onChange={
            (e: any) => {
              const selelectedValue = e.target.value as string;
              const medicineName = list.find((medicine) => medicine.value === selelectedValue);
              handleSetSelectedMedicine(medicineName?.data_name);
            }
          }
        />
      </InputsBox>
    </Box>
  );
}
