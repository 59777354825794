import React from 'react';
import styled from 'styled-components';

interface GroupDividerProps {
  letter: string;
}

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const GroupCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F3F4;
  color: #1849A9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.09px;
  letter-spacing: 0.3799999952316284px;
  text-align: center;
`;

export function GroupDivider({ letter }: GroupDividerProps) {
  return (
    <DividerContainer>
      <GroupCircle>{letter}</GroupCircle>
    </DividerContainer>
  );
}
