import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';
import { authenticatedGetRequest } from './authenticatedRequestService';
import { IDiscountsUser, ProgramProductLine } from '../pages/portal-patient/discounts/contracts';

function findDiscountsByPatient(
  query: any,
  callApi: boolean,
  setCallApi: React.Dispatch<React.SetStateAction<boolean>>,
): UseQueryResult<AxiosResponse<IDiscountsUser, any>, unknown> {
  const method = 'discounts/get-discounts';
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_DISCOUNTS_BY_PATIENT, JSON.stringify(query)],
    () => authenticatedGetRequest(
      method,
      undefined,
      query
    ),
    {
      staleTime,
      cacheTime,
      enabled: callApi,
      onSuccess: () => {
        setCallApi(false);
      },
      onError: () => {
        setCallApi(false);
      },
    },
  );
}

function findProductsAvailableToDiscounts(
    programId: number,
): UseQueryResult<AxiosResponse<ProgramProductLine[], any>, unknown> {
  const method = 'discounts/get-products';
  const query = {
    programId,
  };
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_PRODUCTS_AVAILABLE_TO_DISCOUNTS, programId],
    () => authenticatedGetRequest(
      method,
      undefined,
      query,
    ),
    {
      staleTime,
      cacheTime,
    },
  );
}

export const discountsService = {
  findDiscountsByPatient,
  findProductsAvailableToDiscounts,
};