import { FormControl, FormGroup, IconButton, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { InputSubTitle, InputTitle } from '../styles';

const MAX_FILE_SIZE_NUMBER = 2; // 2MB
const MAX_FILE_SIZE = MAX_FILE_SIZE_NUMBER * 1024 * 1024; 
const HiddenInput = styled.input`
  display: none;
`;

const Container = styled.div`
  border: 1px dashed #1570EF;
  background-color: #f5f8fd;
`;

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 54px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    border-color: #1976d2;
  }
`;

const FileName = styled(Typography)`
  flex-grow: 1;
  margin-left: 8px;
`;

export function FileUpload({
  questionCode,
  required,
  label,
  description,
  onChange,
}: {
  questionCode: number,
  required: boolean,
  label: string,
  description?: string,
  onChange?: (fileContentValue: string, fileNameValue: string) => void,
}) {
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const textFieldRef = React.useRef<any>(null);
  const [fileError, setFileError] = useState<string>('');

  const checkFileType = (fileType: string): boolean => {
    const validTypes = ['image/png', 'image/jpeg', 'application/pdf'];

    const fileTypeIsInvalid = !validTypes.includes(fileType);
    if (fileTypeIsInvalid) {
      setFileError('Formato de arquivo inválido. Somente PNG, JPEG, JPG ou PDF são aceitos.');
      setFileName('');
      setTimeout(() => setFileError(''), 10000);
      if (fileInputRef.current) fileInputRef.current.value = '';
      return false;
    }

    return true;
  }

  const checkFileSize = (fileSize: number): boolean => {
    const fileIsLarge = fileSize > MAX_FILE_SIZE;
    if (fileIsLarge) {
      setFileError(`O arquivo é muito grande. O tamanho máximo permitido é de ${MAX_FILE_SIZE_NUMBER}MB.`);
      setFileName('');
      setTimeout(() => setFileError(''), 10000);
      if (fileInputRef.current) fileInputRef.current.value = '';
      return false;
    }

    return true;
  }

  const checkFile = (file: File) => {
    const fileTypeIsValid = checkFileType(file.type);
    if (!fileTypeIsValid) return false;

    const fileSizeIsValid = checkFileSize(file.size);
    if (!fileSizeIsValid) return false;

    return true;
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileName(event.target.files[0].name);
      setFileError('');
      
      const fileIsValid = checkFile(file);
      if (!fileIsValid) return;
      
      if (textFieldRef.current)
        textFieldRef.current.value = event.target.files[0].name;

      if(onChange) {
        //pass file content and file name
        const _fileName = event.target.files[0].name;

        // get file content as base64
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContentValue = event.target?.result as string;
          onChange(fileContentValue.split(',')[1], _fileName);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  };

  const handleRemoveFile = (e: any) => {
    setFileName('');

    if (textFieldRef.current)
      textFieldRef.current.value = '';

    if (fileInputRef.current)
      fileInputRef.current.value = '';

    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <InputTitle>{label}</InputTitle>
      {description && (<InputSubTitle>{description}</InputSubTitle>)}
      <Container>
        <label htmlFor={"file-upload-" + questionCode}>
          <FormGroup>
            <HiddenInput
              accept=".png, .jpg, .jpeg, .pdf"
              id={"file-upload-" + questionCode}
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </FormGroup>
          <UploadContainer>
            {fileName ? (
              <>
                <IconButton size="small" onClick={handleRemoveFile}>
                  x
                </IconButton>
                <FileName variant="body1">{fileName}</FileName>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Clique para adicionar um arquivo (Somente PNG, JPEG, JPG ou PDF)
              </Typography>
            )}
          </UploadContainer>
        </label>
      </Container>
      <TextField
        required={required}
        inputRef={textFieldRef}
        style={{ width: '1px', height: '1px', padding: 0, margin: 0, border: 0, top: '-60px', right: '-100px', opacity: 0 }}
        onFocus={(e) => e.target.blur()}
      />
      {
        fileError && (
        <Typography color="error" variant="body2" style={{ marginTop: '8px' }}>
          {fileError}
        </Typography>)
      }
    </>
  );
}
