import React from 'react';
import { MedicineList } from '../components/MedicineList';
import { Footer, ImageTopBar } from '../../common/components';

export function MyMedicinesPage(): JSX.Element {
  return (
    <>
      <MedicineList />
    </>
  );
}

export default MyMedicinesPage;
