import React from 'react';
import styled from 'styled-components';
import { AccreditedWelcome } from '../components/AccreditedWelcome';
import { AccreditedForm } from '../components/AccreditedForm';

const AppContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    width: 95%;
    padding: 10px;
  }
`;

export default function AccreditedPage(): JSX.Element {
  return (
    <>
      <AppContainer>
        <AccreditedWelcome
          title="Encontre uma loja"
          subtitle="Digite seu CEP ou selecione o Estado e encontre uma loja do programa Abraçar a vida."
        />
        <AccreditedForm />
      </AppContainer>
    </>
  );
}
