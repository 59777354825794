import React, { createContext, useContext, useReducer, ReactElement, useCallback } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './Loader.module.css';

export type LoaderContextState = {
  isBackdropVisible: boolean;
  isLoader: boolean;
  showLoader: () => void;
  hideLoader: () => void;
  showBackdrop: () => void;
  hideBackdrop: () => void;
};

export type LoaderProviderProps = {
  children: ReactElement;
};

type Action =
  | { type: 'SHOW_LOADER' }
  | { type: 'HIDE_LOADER' }
  | { type: 'SHOW_BACKDROP' }
  | { type: 'HIDE_BACKDROP' };

const stub = (): never => {
  throw new Error('Wrap your component in a LoaderProvider');
};

const defaultState: LoaderContextState = {
  isBackdropVisible: false,
  isLoader: false,
  showLoader: stub,
  hideLoader: stub,
  showBackdrop: stub,
  hideBackdrop: stub,
};

const reducer = (state: LoaderContextState, action: Action) => {
  switch (action.type) {
    case 'SHOW_LOADER':
      return {
        ...state,
        isLoader: true,
      };
    case 'SHOW_BACKDROP':
      return {
        ...state,
        isBackdropVisible: true,
      };
    case 'HIDE_BACKDROP':
      return {
        ...state,
        isBackdropVisible: false,
      };
    default:
      return {
        ...state,
        isLoader: false,
      };
  }
};

const useStyles = makeStyles(() => ({
  backdrop: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.15)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9001,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
}));

export const LoaderContext = createContext<LoaderContextState>(defaultState);

export const LoaderProvider = ({ children }: LoaderProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const classes = useStyles();

  const showLoader = useCallback(() => {
    dispatch({ type: 'SHOW_LOADER' });
  }, []);

  const hideLoader = useCallback(() => {
    dispatch({ type: 'HIDE_LOADER' });
  }, []);

  const showBackdrop = useCallback(() => {
    dispatch({ type: 'SHOW_BACKDROP' });
  }, []);

  const hideBackdrop = useCallback(() => {
    dispatch({ type: 'HIDE_BACKDROP' });
  }, []);

  return (
    <LoaderContext.Provider
      value={{
        ...state,
        showLoader,
        hideLoader,
        showBackdrop,
        hideBackdrop,
      }}
    >
      <Dialog
        open={state.isLoader}
        aria-labelledby="responsive-dialog-title"
        className="loader"
      >
        <DialogContent className={styles.LoaderContainer}>
          <div className={styles.DivCircle}> </div>
          <div className={styles.LoaderText}>
            Por favor, aguarde, estamos validando suas informações
            <span className={styles.dot}>.</span>
            <span className={styles.dot}>.</span>
            <span className={styles.dot}>.</span>
          </div>
        </DialogContent>
      </Dialog>
      { children }
    </LoaderContext.Provider>
  );
};

/* eslint-disable-next-line */
export const useLoader = (): LoaderContextState => useContext<LoaderContextState>(LoaderContext);
