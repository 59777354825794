import { FlagQuestionTypeEnum, Question } from '../contracts';

function filterByFlag(dynamicQuestions: Question[], flag: FlagQuestionTypeEnum): Question[] {
  return dynamicQuestions?.filter((value) => {
    const mainQuestionCondition = value.FlagTipoQuestao === flag && !value.CodigoQuestaoPai;
    if (mainQuestionCondition) return true;
    return false;
  });
}

function filterByProductName(dynamicQuestions: Question[], productName: string): Question[] {
  return dynamicQuestions?.filter((value) => {
    const condition = (value.NomeProduto === productName.trim())
    && (value.FlagTipoQuestao === FlagQuestionTypeEnum.MEDICATION);
    if (condition) return true;
    return false;
  });
}

export const dynamicQuetionHelpers = {
  filterByFlag,
  filterByProductName,
};
