/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Container, Typography, FormControl, Grid, Box, CircularProgress } from '@material-ui/core';
import DiscountCard from './components/DiscountCard';
import styles from './DiscountsPage.module.css';
import { discountsService } from '../../../services/discountsService';
import envConfig from '../../../config/environment-config';
import { SelectInput } from '../common/components/SelectInput';
import { useForm } from 'react-hook-form';
import { IPatientCrendentials, IUseFormData, LocalStorageKeysEnum } from '../common/contracts';
import { noop } from 'lodash';
import { inputMUIDefaultStyles } from '../common/styles';
import { statesList } from '../common/helpers/statesListMock';
import { useAlert } from '../../../contexts/patient-portal/Alert.context';
import { handleQuantityMessage, RuleNameEnum } from './helpers';
import { localStorageMethods } from '../common/helpers';
import { Button } from '../../portal-patient/accredited/styles';
import { RoutesEnum } from '../../../constants';
import { medicinesService } from '../../../services';

interface Discount {
  name: string;
  presentation: string;
  quantity: string;
  imageUrl: string;
  discountType: string;
  discountValue: string;
  productLink: string;
  storeLink: string;
}

const DiscountsPage: React.FC = () => {
  const [callApi, setCallApi] = useState<boolean>(false);
  const { showAlert, hideAlert } = useAlert();
  const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    getValues,
    watch,
  } = useForm();
  const formData: IUseFormData = {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister: noop,
  };

  const watchData = watch() as {
    uf: string;
    lineId: string;
  };
  const disabledButton = (watchData.uf === '' || !watchData.uf) || (watchData.lineId === '' || !watchData.lineId);

  const handleSearch = async () => {
    if (watch().uf === '' || !watch().uf) {
      showAlert('Preencha os campos', 'Selecione um estado', 'error');
      return setTimeout(() => hideAlert(), 3000);
    }
    if (watch().lineId === '' || !watch().lineId) {
      showAlert('Preencha os campos', 'Selecione um medicamento', 'error');
      return setTimeout(() => hideAlert(), 3000);
    }

    setCallApi(true);
  };

  const handleStateChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setValue('uf', e.target.value as string);
  };

  const handleMedicationChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setValue('lineId', e.target.value as string);
  };

  const { data: productsData } = discountsService.findProductsAvailableToDiscounts(
    envConfig.boehringerProgramCode, 
  );
  
  const { data: medicinesByPatient } = medicinesService.findManyByPatient(
    envConfig.boehringerProgramCode,
    patientCredentials?.healthPassportId || ''
  );
  
  const medicinesByPatientNames = medicinesByPatient?.data?.map(medicine => medicine.name) || [];
  
  const filteredProductsData = productsData?.data?.filter(product =>
    medicinesByPatientNames.includes(product.name)
  ) || [];
  
  const products = filteredProductsData.map(product => ({
    label: product.name,
    value: product.lineId.toString(),
  })) || [];

  const { data: discountsData, isLoading: isLoadingDiscounts, error: findDiscountsError } = discountsService.findDiscountsByPatient({
    programId: envConfig.boehringerProgramCode,
    familyId: watchData.lineId,
    healthPassportId: patientCredentials?.healthPassportId ?? '',
    uf: watchData.uf,
  }, callApi, setCallApi);

  const productsDiscounts: Discount[] = [];

  discountsData?.data?.rules?.map((rule) => {
    rule.ranges?.map((range) => {
      productsDiscounts.push({
        name: discountsData?.data?.familyDescription ?? '-',
        presentation: rule.productDescription ?? '-',
        quantity: handleQuantityMessage(rule.ruleName as RuleNameEnum, range.boxesFrom, range.boxesTo),
        imageUrl: 'link_to_image_a',
        discountType: 'Percentual',
        discountValue: `${range.discountPercentage ?? '-'} %`,
        productLink: RoutesEnum.PATIENT_ACCREDITED_ROUTE,
        storeLink: RoutesEnum.PATIENT_ACCREDITED_ROUTE,
      });
    });
  });

  useEffect(() => {
    if (findDiscountsError) {
      showAlert(
        'Descontos',
        'Ops! Tivemos um problema ao obter os descontos, tente novamente mais tarde.',
        'error',
      );
      setTimeout(() => hideAlert(), 3000);
    }
  }, [findDiscountsError]);

  const conditionsToRenderProductsDiscounts = ((discountsData?.data?.rules?.length ?? 0) > 0 || !discountsData?.data)
  const ProductsDiscountsListCards = conditionsToRenderProductsDiscounts ? productsDiscounts?.map((discount) => (
    <Grid item xs={12} sm={6} md={4} key={discount.name}>
      <DiscountCard discount={discount} />
    </Grid>
  )) :
    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '40px' }}>
      <Typography variant="body1" component="p" >
        Não foram encontrados descontos para o produto selecionado.
      </Typography>
    </div>

  return (
    <Container>
      <Typography variant="h4" gutterBottom className={styles.title}>
        Meus descontos
      </Typography>
      <Typography variant="body1" gutterBottom className={styles.subTitle}>
        O programa Abraçar a Vida oferece desconto na compra de seu medicamento.
      </Typography>

      <hr style={{ margin: '30px 0px', border: '0', borderTop: '3px solid #e0e0e0' }} />

      <Box className={styles.banner}>
        <Typography variant="body1">
          <strong>Compre online e aproveite descontos exclusivos!</strong>
        </Typography>
        <Typography>
          Garanta os melhores preços e a conveniência da compra no conforto da sua casa.
        </Typography>
      </Box>
      <Typography className={styles.information}>
        Informe o estado para visualizar os descontos, que podem variar conforma alíquota de ICMS de cada região.
      </Typography>
      <Box mb={5} mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <SelectInput
              title="Selecione o produto"
              isRequired
              list={products}
              style={inputMUIDefaultStyles.large}
              formData={formData}
              propertieFormName={'lineId'}
              onChange={handleMedicationChange}
            />
            <FormControl
              variant="outlined"
              fullWidth
            >
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <SelectInput
                title="Estado"
                list={statesList}
                style={inputMUIDefaultStyles.large}
                formData={formData}
                propertieFormName={'uf'}
                onChange={handleStateChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Button>
        <button
          type="submit"
          onClick={handleSearch}
          disabled={isLoadingDiscounts || disabledButton}
        >
          Buscar
        </button>
      </Button>
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        {
          isLoadingDiscounts ?
            <div style={{ margin: '90px auto', marginLeft: '48%' }}>
              <CircularProgress />
            </div>
            :
            ProductsDiscountsListCards
        }
      </Grid>
    </Container>
  );
};

export default DiscountsPage;
