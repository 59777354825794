import React from 'react';
import { CheckBoxInput } from '../../common/components';
import { InputSubTitle } from '../../common/styles';
import { IUseFormData } from '../../common/contracts';

export function PatientPreferenceComunicationInput({
  formData,
  defaultValue,
}: {
  formData: IUseFormData,
  defaultValue?: any,
}) {
  const list = [
    { label: 'SMS', fieldName: 'acceptSMS' },
    { label: 'E-mail', fieldName: 'acceptEmail' },
    { label: 'Whatsapp', fieldName: 'acceptWhatsapp' },
  ];

  return (
    <>
      <InputSubTitle>
        Qual o meio de preferência para comunicação?
      </InputSubTitle>
      <CheckBoxInput
        list={list}
        row
        formData={formData}
        defaultValue={defaultValue}
      />
    </>
  );
}
