import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { validateCaptcha } from 'react-simple-captcha';
import { noop } from 'lodash';
import { Button } from '../../common/styles';
import { Box, Container, inputLabelProps, inputMUIDefaultStyles } from '../../common/styles/FormStyles';
import { IUseFormData, LocalStorageKeysEnum } from '../../common/contracts';
import { RoutesEnum } from '../../../../constants';
import { useAlert } from '../../../../contexts/patient-portal/Alert.context';
import { createUserFormAlertMessages, prepareCreateBodyData } from '../helpers';
import Captcha from '../../../../components/Captcha.tsx';
import { PasswordBoxInputs } from './PasswordBoxInputs';
import { TextField } from '@material-ui/core';
import { emailValidation } from '../../common/validations/forms';
import { captchaError } from '../../common/helpers/captchaError';
import { useLoader } from '../../../../contexts/patient-portal/Loader.context';
import { createUserService } from '../../../../services/createUserService';
import { localStorageMethods } from '../../common/helpers';

export function CreateUserForm(): JSX.Element {
    const { showLoader, hideLoader } = useLoader();
    const { push } = useHistory();
    const { showAlert, hideAlert } = useAlert();
    const origin = window.location.pathname.includes('/unimed') ? '/unimed' : '';

    const createUser = async (createUserFormData: any) => {

        const userCaptchaValue = (document.getElementById('create_user_captcha_input') as HTMLInputElement)?.value;
        if (!validateCaptcha(userCaptchaValue)) {
            return showAlert(captchaError.title, captchaError.subTitle, 'error');
        }

        const cpf = localStorageMethods.getItem<string>(LocalStorageKeysEnum.PATIENT_CPF_LOGIN) || '';

        const preparedData = prepareCreateBodyData({ cpf, createUserFormData });
        await createUserService.createUser(preparedData);

        showAlert(
            createUserFormAlertMessages.success.title,
            createUserFormAlertMessages.success.subTitle,
            createUserFormAlertMessages.success.status,
        );
        setTimeout(() => hideAlert(), 3000);
        setTimeout(() => push(`${RoutesEnum.PATIENT_LOGIN_PAGE}${origin}`), 3200);
    };

    const modeOptions = {
        dataDestiny: createUser,
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        control,
        setValue,
        getValues,
        watch,
    } = useForm();
    const formData: IUseFormData = {
        register,
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
        watch,
        getValues,
        unregister: noop,
    };

    const onSubmit = async (registrationFormData: any): Promise<void> => {
        try {
            showLoader();
            await modeOptions?.dataDestiny(registrationFormData);
            hideLoader();
        } catch (err: any) {
            hideLoader();
            const errorMessage = err.response?.data?.message || '';
            setTimeout(() => hideAlert(), 3000);
            return showAlert(
                createUserFormAlertMessages.defaultError.title,
                errorMessage,
                createUserFormAlertMessages.defaultError.status,
            );
        }
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <TextField
                        variant="outlined"
                        required
                        label="Email"
                        style={inputMUIDefaultStyles.large}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                        {...register('email', emailValidation)}
                        InputLabelProps={{
                            ...inputLabelProps,
                        }}
                    />
                </Box>
                <Box>
                    <PasswordBoxInputs formData={formData} />
                </Box>

                <Box>
                    <Captcha idName='create_user_captcha_input' />
                </Box>

                <Button type='default'>
                    <button type="submit"> Fazer meu cadastro </button>
                </Button>
            </form>
        </Container>
    );
}
