import React, { useEffect, useState } from 'react';
import { TextField, useMediaQuery } from '@material-ui/core';
import { Box, InputSubTitle, InputTitle, InputsBox, inputLabelProps, inputMUIDefaultStyles } from '../styles/FormStyles';
import { DynamicQuestionsBox } from './DynamicQuestionsBox';
import { SelectInput } from './SelectInput';
import { crmValidation } from '../validations/forms';
import { statesList } from '../helpers/statesListMock';
import { dynamicQuetionHelpers } from '../helpers/dynamicQuestions';
import envConfig from '../../../../config/environment-config';
import { dynamicQuestionsService, medicinesService } from '../../../../services';
import { Question } from '../contracts';

export function DoctorInputs({
  selectedMedicine,
  formData,
  index,
}: {
    selectedMedicine: string,
    formData: any,
    index: number,
  }) {
  const { register, errors, watch, setValue, getValues } = formData;

  const propertiesFormNames = {
    CRMUF: `medication.${index}.CRMUF`,
    CRM: `medication.${index}.CRM`,
    doctorName: `medication.${index}.doctorName`,
    doctorSpecialty: `medication.${index}.doctorSpecialty`,
  };

  const crmValue = watch(propertiesFormNames.CRM);
  const crmUfValue = watch(propertiesFormNames.CRMUF);

  const [crmBlurred, setCrmBlurred] = useState(false);
  const [queryError, setQueryError] = useState<boolean>(false);

  const {
    data: findDoctorNameAndSpecialty,
    isError,
    error,
  } = medicinesService.findDoctorNameAndSpecialty(
    envConfig.boehringerProgramCode,
    crmValue,
    crmUfValue,
    crmBlurred && !queryError
  );

  useEffect(() => {
    if (findDoctorNameAndSpecialty) {
      const doctorData = findDoctorNameAndSpecialty?.data;

      if (doctorData) {
        setValue(propertiesFormNames.doctorName, doctorData.name || '-');
        setValue(propertiesFormNames.doctorSpecialty, doctorData.doctorSpecialty || '-');
      }
    }

    if (isError) {
      setValue(propertiesFormNames.doctorName, '-');
      setValue(propertiesFormNames.doctorSpecialty, '-');
      setQueryError(true);
    }
  }, [findDoctorNameAndSpecialty, setValue, isError, error]);

  const handleCrmBlur = () => {
    setCrmBlurred(true);
  };

  const DisableOnchageCrm = (event: any) => {
    setValue(propertiesFormNames.CRM, event.target.value);
    setCrmBlurred(false);
    setQueryError(false);
  };

  const {
    data: questions,
  } = dynamicQuestionsService.findManyByProgramCode(envConfig.boehringerProgramCode);

  const quiz: Question[] = questions?.data?.Questionario || [];
  const productQuestions = dynamicQuetionHelpers.filterByProductName(
    quiz || [],
    selectedMedicine,
  );

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const inputStyle = {
    ...inputMUIDefaultStyles.normal,
    height: isSmallScreen ? '80px' : inputMUIDefaultStyles.normal.height,
  };

  return (
    <>
      <InputTitle>Sobre seu médico</InputTitle>
      <InputSubTitle>Agora, por favor, preencha com os dados do seu médico.</InputSubTitle>
      <Box>
        <InputsBox>
          <TextField
            variant="outlined"
            required
            id="doctorCode"
            label="Informe o CRM"
            autoComplete="doctorCode"
            style={inputStyle}
            error={!!errors?.medication?.[index]?.CRM}
            helperText={errors?.medication?.[index]?.CRM ? errors?.medication?.[index]?.CRM.message : ''}
            {...register(propertiesFormNames.CRM, crmValidation)}
            InputLabelProps={inputLabelProps}
            onBlur={handleCrmBlur}
            onChange={DisableOnchageCrm}
          />
          <SelectInput
            title="Estado"
            isRequired
            list={statesList}
            style={inputStyle}
            formData={formData}
            propertieFormName={propertiesFormNames.CRMUF}
            onChange={(e: any) => {
              setValue(propertiesFormNames.CRMUF, e?.target?.value)
              setQueryError(false);
            }}
          />
        </InputsBox>
      </Box>
      <Box>
        <InputsBox>
          <TextField
            variant="outlined"
            id="doctorName"
            label="Nome"
            autoComplete="doctorName"
            style={inputStyle}
            error={!!errors?.medication?.[index]?.doctorName}
            helperText={errors?.medication?.[index]?.doctorName ? errors?.medication?.[index]?.doctorName.message : ''}
            {...register(propertiesFormNames.doctorName)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!getValues(propertiesFormNames.doctorName),
            }}
            disabled
          />
          <TextField
            variant="outlined"
            id="doctorSpecialty"
            label="Especialidade"
            autoComplete="doctorSpecialty"
            style={inputStyle}
            {...register(propertiesFormNames.doctorSpecialty)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!getValues(propertiesFormNames.doctorSpecialty),
            }}
            disabled
          />
        </InputsBox>
      </Box>
      <Box>
        {/* <InputTitle>
          Selecione o diagnóstico abaixo para prescrição de
          { ' ' }
          { selectedMedicine }
        </InputTitle>
        <InputSubTitle>
          As indicações abaixo estão relatadas e aprovadas em bula.
          Mais de um diagnóstico pode ser selecionado.
        </InputSubTitle> */}
      </Box>
      <Box>
        {
          productQuestions?.map((value, i: number) => (
            <DynamicQuestionsBox
              key={i}
              index={i}
              question={value}
              formData={formData}
              allQuestions={productQuestions}
              mainFieldName={`medication.${index}.medication_dynamic_questions`}
              defaultValue={[]}
            />
          ))
        }
      </Box>
    </>
  );
}
