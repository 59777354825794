import React, { useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import NavbarComposer from '../../composers/NavbarComposer';
import SidebarComposer from '../../composers/SidebarComposer';
import { useStyles } from './styles';
import FooterComposer from '../../composers/FooterComposer';

type PortalLoggedLayoutProps = {
  children: JSX.Element;
};

export default function PortalLoggedLayout({ children }: PortalLoggedLayoutProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(!isXS);

  function handleDrawerOpen(): void {
    setIsDrawerOpen(true);
  }

  function handleDrawerClose(): void {
    setIsDrawerOpen(false);
  }

  return (
    <>
      <NavbarComposer
        isDrawerOpen={isDrawerOpen}
        onDrawerOpen={handleDrawerOpen}
        onDrawerClose={handleDrawerClose} />
      <div className={classes.mainLayout}>
        <SidebarComposer isDrawerOpen={isDrawerOpen} onDrawerClose={handleDrawerClose} />
        <main className={classes.main}>
          <div className={classes.content}>{children}</div>
        </main>
      </div>
      <footer>
        <FooterComposer className={classes.footer} />
      </footer>
    </>
  );
}
