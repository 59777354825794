import React, { useEffect } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload } from 'react-simple-captcha';
import { InputsBox } from '../../pages/portal-patient/common/styles';
import { TextField } from '@material-ui/core';
import { inputLabelProps } from '../../pages/portal-patient/common/styles';
import { inputMUIDefaultStyles } from '../../pages/portal-patient/common/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import styles from '../../pages/portal-patient/login/LoginPage.module.css';

const Captcha: React.FC<any> = ({
  idName
}: {
  idName: string,
}): JSX.Element => {
  useEffect(() => {
    loadCaptchaEnginge(8);
  }, []);

  const reloadCaptcha = () => {
    loadCaptchaEnginge(8);
  };

  return (
    <InputsBox className="my-4 flex-column flex-md-row">
      <div className="d-flex align-items-center">
        <LoadCanvasTemplateNoReload className={styles.CaptchaCanvas} />
        <RefreshIcon onClick={reloadCaptcha} className={styles.ReloadIcon} />
      </div>
      <TextField
        style={inputMUIDefaultStyles.large}
        InputLabelProps={inputLabelProps}
        variant="outlined"
        required
        fullWidth
        id={idName}
        label="Digite o captcha"
        name="captcha"
        autoComplete="off"
      />
    </InputsBox>
  );
};

export default Captcha;
