import { checkExactLength, checkOnlyNumber, checkOnlyString, createRequiredText, validateDate } from '../custom';

export const cpfValidation = {
  required: createRequiredText(),
  validate: {
    exactLength: (value: string) => checkExactLength(value, 11, 'Deve ter 11 números'),
    onlyNumber: (value: string) => checkOnlyNumber(value),
  },
};

export const birthDateValidation = {
  required: createRequiredText(),
  validate: validateDate,
};

export const crmValidation = {
  required: createRequiredText(),
  validate: {
    onlyNumber: (value: string) => checkOnlyNumber(value),
  },
};

export const doctorNameValidation = {
  required: createRequiredText(),
  validate: {
    onlyString: (value: string) => checkOnlyString(value),
  },
};

export const doctorSpecialtyValidation = {
  required: createRequiredText(),
};

export const patientNameValidation = {
  required: createRequiredText(),
  validate: {
    onlyString: (value: string) => checkOnlyString(value),
  },
};

export const dddValidation = {
  required: ' ',
  validate: {
    exactLength: (value: string) => checkExactLength(value, 2),
    onlyNumber: (value: string) => checkOnlyNumber(value),
  },
};

export const phoneNumberValidation = {
  required: createRequiredText(),
  validate: {
    exactLength: (value: string) => checkExactLength(value, 9, 'Deve ter 9 números'),
    onlyNumber: (value: string) => checkOnlyNumber(value),
  },
};

export const emailValidation = {
  required: createRequiredText(),
};

export const passwordValidation = {
  required: createRequiredText(),
  validate: {
    minLength: (value: string) => !(value.length < 6) || 'Deve ter pelo menos 6 caracteres',
  },
};

export const cepValidation = {
  required: createRequiredText(),
  validate: {
    exactLength: (value: string) => checkExactLength(value, 9, 'Deve ter 8 números'),
  },
};

export const addressValidation = {
  required: createRequiredText(),
};

export const numberHouseValidation = {
  required: createRequiredText(),
  validate: {
    onlyNumber: (value: string) => checkOnlyNumber(value),
  },
};

export const cityValidation = {
  required: createRequiredText(),
};
