import React from 'react';
import { MedicineForm } from '../components/MedicineForm';

export function MyMedicinesUpsertPage(): JSX.Element {
  return (
    <>
      <MedicineForm />
    </>
  );
}

export default MyMedicinesUpsertPage;
