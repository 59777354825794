function getItem<T>(key: string): T | undefined {
  const existingData = localStorage.getItem(key);
  if (existingData) return JSON.parse(localStorage.getItem(key) || '{}');
  return undefined;
}

function setItem<T>(key: string, data: T): void {
  return localStorage.setItem(key, JSON.stringify(data));
}

function removeItem(key: string): void {
  return localStorage.removeItem(key);
}

export const localStorageMethods = {
  getItem,
  setItem,
  removeItem,
};
