import React from 'react';
import { TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Box, InputsBox, inputLabelProps, inputMUIDefaultStyles } from '../../common/styles';
import { IUseFormData } from '../../common/contracts';
import { dddValidation, emailValidation } from '../../common/validations/forms';
import { IMaskInputCellPhoneNoDDD, IMaskInputDDD } from '../../common/components/IMaskInput';

export function ContactBoxInputs({
  formData,
  defaultValue,
}: {
  formData: IUseFormData,
  defaultValue: any,
}) {
  const { register, errors, getValues } = formData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Box>
        <InputsBox width="283px">
          <TextField
            variant="outlined"
            required
            id="DDD"
            label="DDD"
            style={inputMUIDefaultStyles.small}
            error={!!errors.DDD}
            helperText={errors.DDD ? errors.DDD.message : ''}
            {...register('DDD', dddValidation)}
            defaultValue={defaultValue?.DDD}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!defaultValue?.DDD || getValues('DDD'),
            }}
            InputProps={{
              inputComponent: IMaskInputDDD as any,
            }}
          />
          <TextField
            variant="outlined"
            required
            id="phone"
            label="Celular"
            style={{ ...inputMUIDefaultStyles.medium, marginTop: isSmallScreen ? '35px' : '0' }}
            error={!!errors.phone}
            helperText={errors.phone ? errors.phone.message : ''}
            {...register('phone')}
            defaultValue={defaultValue?.phone}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!defaultValue?.phone || getValues('phone'),
            }}
            InputProps={{
              inputComponent: IMaskInputCellPhoneNoDDD as any,
            }}
          />
        </InputsBox>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          required
          label="Email"
          style={isSmallScreen ? inputMUIDefaultStyles.normal : inputMUIDefaultStyles.large}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
          {...register('email', emailValidation)}
          defaultValue={defaultValue?.email}
          InputLabelProps={{
            ...inputLabelProps,
            shrink: !!defaultValue?.email || !!getValues('email'),
          }}
        />
      </Box>
    </>
  );
}
