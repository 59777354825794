import envConfig from "../../../../config/environment-config";
import { IPatientCrendentials, LocalStorageKeysEnum } from "../../common/contracts";
import { localStorageMethods } from "../../common/helpers";
import { DynamicResponse, Medication } from "../contracts";

function handleCodeResponses(medicationDynamicQuestions: DynamicResponse[], question: any) {
    if (Array.isArray(question.CodeResponse)) {
        question.CodeResponse?.forEach((codeResponse: any) => {
            medicationDynamicQuestions.push({
                CodeResponse: Number(codeResponse),
                CodeProductRule: Number(question.CodeProductRule),
                CodeQuestion: Number(question.CodeQuestion),
                EnteredValue: question.EnteredValue || "", 
            }); 
        })
    } else {
        medicationDynamicQuestions.push({
            CodeResponse: Number(question.CodeResponse),
            CodeProductRule: Number(question.CodeProductRule),
            CodeQuestion: Number(question.CodeQuestion),
            EnteredValue: question.EnteredValue || "", 
        });
    }
}

function handleMedicineData(data: Medication[]) {
    const medication = data?.map((value) => {
        const medicationDynamicQuestions: DynamicResponse[] = [];

        value?.medication_dynamic_questions?.forEach((dynamicQuestion: any) => {
            if (Array.isArray(dynamicQuestion)) {
                dynamicQuestion.forEach((question: any) => {
                    if (!question?.CodeQuestion || !question?.CodeProductRule) return;
                    handleCodeResponses(medicationDynamicQuestions, question);
                });
            } else {
                if (!dynamicQuestion?.CodeQuestion || !dynamicQuestion?.CodeProductRule) return;
                handleCodeResponses(medicationDynamicQuestions, dynamicQuestion);
            }
        });

        return {
            ...value,
            medication_dynamic_questions: medicationDynamicQuestions,
        };
    })

    return medication;
}

export function prepareAddMedicineData(data: Medication[]) {
    const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
    const medication = handleMedicineData(data);

    return {
        type: "type", // TODO
        programId: envConfig.boehringerProgramCode,
        healthPassportId: patientCredentials?.healthPassportId || '',
        medication,
    }
}