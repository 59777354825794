import { AxiosResponse } from 'axios';
import { authenticatedPostRequest } from './authenticatedRequestService';
import { CreateUserParams } from '../pages/portal-patient/create-user/contracts';

async function createUser(body: CreateUserParams): Promise<AxiosResponse<any, any>> {
  const method = 'patients/Createuser';

  return await authenticatedPostRequest(
    method,
    body,
  );
}

export const createUserService = {
    createUser,
};
