import React, { useState } from 'react';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, useMediaQuery, useTheme } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IUseFormData } from '../../common/contracts';
import { inputLabelProps, inputMUIDefaultStyles } from '../../common/styles';
import { validateEqualPasswords } from '../../common/validations/custom';
import { passwordValidation } from '../../common/validations/forms';

export function PasswordInput({
  title,
  helperText,
  formData,
  propertieFormName,
  className,
  style,
}: {
  title: string,
  helperText?: string,
  formData: IUseFormData,
  propertieFormName: string,
  className?: string
  style?: React.CSSProperties;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { register, errors, watch } = formData;
  const checkPasswords = propertieFormName === 'oldPassword' ? () => undefined : (value: string) => validateEqualPasswords(value, watch());
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <FormControl variant="outlined" required className={className} style={{  ...inputMUIDefaultStyles[isSmallScreen ? 'normal' : 'large'], marginBottom:'40px'}}>
      <InputLabel htmlFor="outlined-adornment-password" style={inputLabelProps.style}>{ title }</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        error={!!errors?.[propertieFormName]}
        {...register(propertieFormName, {
          ...passwordValidation,
          validate: {
            ...passwordValidation.validate,
            checkPasswords,
          },
        })}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
      />
      <FormHelperText id="outlined-weight-helper-text" error={!!errors?.[propertieFormName]}>
        { errors?.[propertieFormName] ? errors?.[propertieFormName]?.message : (helperText ?? ' ') }
      </FormHelperText>
    </FormControl>
  );
}
