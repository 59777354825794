/* eslint-disable-next-line no-shadow */
export enum FlagQuestionTypeEnum {
  PATIENT = 1,
  MEDICATION = 2,
}

/* eslint-disable-next-line no-shadow */
export enum DynamicFieldTypeEnum {
  TEXT_BOX = 'textbox',
  DROPDOWN_LIST = 'dropdownlist',
  RADIO_BUTTON = 'radiobuttonlist',
  UPLOAD_FILE = 'fileupload',
}

export interface IDynamicField {
  TipoControle: DynamicFieldTypeEnum,
  CodigoQuestao: string,
  Obrigatoria?: boolean,
  TituloExibicao: string,
  NomeProduto?: string,
  MultiplaEscolha: boolean,
  CodigoLinha: string,
  Respostas: {
    CodigoResposta: string,
    TituloExibicao: string,
    OrdemExibicao: number,
  }[]
}

type Response = {
  PermiteValorDigitado: boolean;
  OrdemExibicao: number;
  ValorDigitado: string;
  CodigoResposta: number;
  TituloExibicao: string;
}

export type NestedQuestion = {
  CodigoQuestaoAninhada: number;
  CodigoQuestaoPai: number;
  CodigoRespostaPai: number[];
}

export type Question = {
  CodigoLinha: number;
  CodigoQuestao: number;
  CodigoQuestaoPai: number | null;
  FlagTipoQuestao: number;
  Obrigatoria: boolean,
  NomeProduto: string,
  MultiplaEscolha: boolean;
  OrdemExibicao: number;
  QuestaoAninhada: NestedQuestion[] | null;
  Respostas: Response[];
  TipoControle: string;
  TituloExibicao: string;
  DicaQuestao: string;
}

export type DynamicQuestions = {
  code: number;
  message: null;
  status: string;
  Questionario: Question[];
}

export type DynamicResponse = {
  EnteredValue?: string,
  CodeQuestion: string,
  CodeProductRule?: string,
  CodeResponse: string | string[],
}
