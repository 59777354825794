import React, { useEffect } from 'react';
import { TextField, Grid, makeStyles } from '@material-ui/core';
import { InputTitle, InputsBox, inputLabelProps, inputMUIDefaultStyles } from '../styles/FormStyles';
import { IUseFormData } from '../contracts';
import { birthDateValidation, cpfValidation } from '../validations/forms';
import { IMaskInputCPF, IMaskInputDate } from './IMaskInput';

const useStyles = makeStyles((theme) => ({
  marginTopXs: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3), 
    },
  },
}));

export function PatientCodeAndBirthdayInputBox({
  formData,
  defaultValue,
  isDisabled,
}: {
  formData: IUseFormData,
  defaultValue?: { cpf?: string, birthDate?: string },
  isDisabled?: boolean,
}) {
  const { register, errors, getValues, setValue } = formData;
  const dateHelperText = (defaultValue ? undefined : 'dd/mm/aaaa');
  const cpfIsDisabled = isDisabled || !!defaultValue?.cpf;

  const classes = useStyles(); 

  useEffect(() => {
    if (defaultValue?.cpf) setValue('cpf', defaultValue?.cpf);
  }, [defaultValue?.cpf, setValue]);

  return (
    <>
      <InputTitle>Dados do paciente*</InputTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            disabled={cpfIsDisabled}
            label="CPF do Paciente"
            defaultValue={defaultValue?.cpf}
            style={inputMUIDefaultStyles.normal}
            error={!!errors.cpf}
            helperText={errors.cpf ? errors.cpf.message : ''}
            {...register('cpf')}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: true,
            }}
            InputProps={{
              inputComponent: IMaskInputCPF as any,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            disabled={isDisabled}
            label="Data de nascimento"
            defaultValue={defaultValue?.birthDate}
            style={inputMUIDefaultStyles.normal}
            error={!!errors.birthDate}
            helperText={errors.birthDate ? errors.birthDate.message : dateHelperText}
            {...register('birthDate', defaultValue?.birthDate ? undefined : birthDateValidation)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: true,
            }}
            InputProps={{
              inputComponent: IMaskInputDate as any,
            }}
            fullWidth
            className={classes.marginTopXs} 
          />
        </Grid>
      </Grid>
    </>
  );
}
