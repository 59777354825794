import { IMaskInputCellPhone } from '../../components/IMaskInput';

export const validateDate = (value: string) => {
  const datePattern = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;
  if (!datePattern.test(value)) {
    return 'Deve estar no formato dd/mm/aaaa';
  }

  const [day, month, year] = value.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (
    date.getFullYear() !== year
    || date.getMonth() !== month - 1
    || date.getDate() !== day
    || date > today
  ) {
    return 'Data inválida';
  }

  return true;
};

export const validateBothCheckedTerms = (value: string, allValues: any) => {
  if (!allValues.confirmation || !allValues.terms) {
    return 'Para finalizar o cadastro, você deverá aceitar todos os termos';
  }
  return true;
};

export const validateEqualPasswords = (value: string, allValues: any) => {
  if (allValues.password !== allValues.confirmPassword) {
    return 'As senhas não correspondem';
  }
  return true;
};

export const checkOnlyString = (value: string, message?: string) => !/\d/.test(value) || `${message ?? 'Somente letras'}`;

export const createRequiredText = (fieldName?: string) => (fieldName ? `${fieldName} é obrigatório` : 'Obrigatório');

export const checkExactLength = (value: string, length: number, message?: string) => value.length === length || `${message ?? ' '}`;

// eslint-disable-next-line
export const checkOnlyNumber = (value: string, message?: string) => !isNaN(Number(value)) || `${message ?? 'Somente números'}`;

export const masksByCodeQuestion: any = {
  // eslint-disable-next-line
  'Celular': IMaskInputCellPhone as any,
};
