import React, { memo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { PortalPatientLayout, PortalLoggedLayout } from '../layouts';
import PatientLoginPage from '../pages/portal-patient/login';
import MedicineSignupPage from '../pages/portal-patient/signup/pages/MedicineSignUpPage';
import PatientSignupPage from '../pages/portal-patient/signup/pages/PatientSignUpPage';
import MyDataPage from '../pages/portal-patient/signup/pages/MyDataPage';
import { RoutesEnum } from '../constants';
import DashboardPage from '../pages/portal-patient/dashboard';
import MyMedicinesPage from '../pages/portal-patient/my-medicines/pages/MyMedicinesPage';
import SchedulesPage from '../pages/portal-patient/schedules';
import DiscountsPage from '../pages/portal-patient/discounts';
import ContentsPage from '../pages/portal-patient/contents';
import PurchasePage from '../pages/portal-patient/purchase';
import StorePage from '../pages/portal-patient/store';
import RegulamentPage from '../pages/portal-patient/regulament';
import MedicineLeafletsPage from '../pages/portal-patient/medicine-leaflets/pages/MedicineLeafletsPage';
import FaqPage from '../pages/portal-patient/faq';
import { News1, News2, News3, News4, News5, News6, News7, News8 } from '../pages/portal-patient/contents/contents/news';
import { MyMedicinesUpsertPage } from '../pages/portal-patient/my-medicines/pages';
import AccreditedPage from '../pages/portal-patient/accredited/pages/AccreditedPage';
import { useAuth } from '../contexts/auth.context';
import CreateUserPage from '../pages/portal-patient/create-user/pages/CreateUserPage';
import termOfAcceptancePage from '../pages/portal-patient/termOfAcceptance/index';

function Routes(): JSX.Element {
  const { isAuthenticated, isLoading, isUnimed } = useAuth();

  if (isLoading) {
    return <div />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {isAuthenticated ? (
          <Switch>
            {isUnimed ? (
              <>
                <Route key="portal_patient_routes" path={RoutesEnum.PATIENT_PANEL_PAGE}>
                  <PortalLoggedLayout>
                    <Switch>
                      <Route path={`${RoutesEnum.PATIENT_MY_DATA}/unimed`} component={MyDataPage} exact />

                      <Route path={`${RoutesEnum.PATIENT_PANEL_DASHBOARD}/unimed`} component={DashboardPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_MEDICATION_ROUTE}/unimed`} component={MyMedicinesPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_MEDICATION_ADD_ROUTE}/unimed`} component={MyMedicinesUpsertPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_MEDICATION_EDIT_ROUTE}/unimed`} component={MyMedicinesUpsertPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_SCHEDULES_ROUTE}/unimed`} component={SchedulesPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_DISCOUNTS_ROUTE}/unimed`} component={DiscountsPage} exact />

                      <Route path={`${RoutesEnum.PATIENT_CONTENTS_ROUTE}/unimed`} component={ContentsPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_PROGRAM_PURCHASE_ROUTE}/unimed`} component={PurchasePage} exact />
                      <Route path={`${RoutesEnum.PATIENT_REGULAMENT_ROUTE}/unimed`} component={RegulamentPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_MEDICINE_LEAFLETS_ROUTE}/unimed`} component={MedicineLeafletsPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_FAQ_ROUTE}/unimed`} component={FaqPage} exact />
                      <Route path={`${RoutesEnum.PATIENT_ACCREDITED_ROUTE}/unimed`} component={AccreditedPage} exact />

                      <Route path='/panel/contents/o-que-e-diabetes' component={News1} exact />
                      <Route path='/panel/contents/mitos-e-verdades-sobre-alimentacao-e-diabetes' component={News2} exact />
                      <Route path='/panel/contents/todo-movimento-conta' component={News3} exact />
                      <Route path='/panel/contents/como-tratar-a-hipoglicemia' component={News4} exact />
                      <Route path='/panel/contents/os-sete-comportamentos-do-autocuidado' component={News5} exact />
                      <Route path='/panel/contents/dieta-dash-alimentacao-saudavel-para-hipertensos-1-3' component={News6} exact />
                      <Route path='/panel/contents/o-que-e-hipertensao-arterial' component={News7} exact />
                      <Route path='/panel/contents/tratamento-para-hipertensao' component={News8} exact />

                      {/* Adicione outras rotas /panel aqui */}
                    </Switch>
                  </PortalLoggedLayout>
                </Route>
                {/* Catch-all route for invalid URLs */}
                <Route path="*">
                  <Redirect to={`${RoutesEnum.PATIENT_PANEL_DASHBOARD}/unimed`} />
                </Route>
              </>
            ) : (
              <>
                <Route key="portal_patient_routes" path={RoutesEnum.PATIENT_PANEL_PAGE}>
                  <PortalLoggedLayout>
                    <Switch>
                      <Route path={RoutesEnum.PATIENT_MY_DATA} component={MyDataPage} exact />

                      <Route path={RoutesEnum.PATIENT_PANEL_DASHBOARD} component={DashboardPage} exact />
                      <Route path={RoutesEnum.PATIENT_MEDICATION_ROUTE} component={MyMedicinesPage} exact />
                      <Route path={RoutesEnum.PATIENT_MEDICATION_ADD_ROUTE} component={MyMedicinesUpsertPage} exact />
                      <Route path={RoutesEnum.PATIENT_MEDICATION_EDIT_ROUTE} component={MyMedicinesUpsertPage} exact />
                      <Route path={RoutesEnum.PATIENT_SCHEDULES_ROUTE} component={SchedulesPage} exact />
                      <Route path={RoutesEnum.PATIENT_DISCOUNTS_ROUTE} component={DiscountsPage} exact />

                      <Route path={RoutesEnum.PATIENT_CONTENTS_ROUTE} component={ContentsPage} exact />
                      <Route path={RoutesEnum.PATIENT_PROGRAM_PURCHASE_ROUTE} component={PurchasePage} exact />
                      <Route path={RoutesEnum.PATIENT_REGULAMENT_ROUTE} component={RegulamentPage} exact />
                      <Route path={RoutesEnum.PATIENT_MEDICINE_LEAFLETS_ROUTE} component={MedicineLeafletsPage} exact />
                      <Route path={RoutesEnum.PATIENT_FAQ_ROUTE} component={FaqPage} exact />
                      <Route path={RoutesEnum.PATIENT_ACCREDITED_ROUTE} component={AccreditedPage} exact />

                      <Route path='/panel/contents/o-que-e-diabetes' component={News1} exact />
                      <Route path='/panel/contents/mitos-e-verdades-sobre-alimentacao-e-diabetes' component={News2} exact />
                      <Route path='/panel/contents/todo-movimento-conta' component={News3} exact />
                      <Route path='/panel/contents/como-tratar-a-hipoglicemia' component={News4} exact />
                      <Route path='/panel/contents/os-sete-comportamentos-do-autocuidado' component={News5} exact />
                      <Route path='/panel/contents/dieta-dash-alimentacao-saudavel-para-hipertensos-1-3' component={News6} exact />
                      <Route path='/panel/contents/o-que-e-hipertensao-arterial' component={News7} exact />
                      <Route path='/panel/contents/tratamento-para-hipertensao' component={News8} exact />

                      {/* Adicione outras rotas /panel aqui */}
                    </Switch>
                  </PortalLoggedLayout>
                </Route>
                {/* Catch-all route for invalid URLs */}
                <Route path="*">
                  <Redirect to={RoutesEnum.PATIENT_PANEL_DASHBOARD} />
                </Route>
              </>
            )}
          </Switch>
        ) : (
          <>
            <Route key="portal_patient_routes" path={RoutesEnum.PATIENT_LOGIN_PAGE}>
              <PortalPatientLayout>
                <Switch>
                  <Route path={RoutesEnum.PATIENT_LOGIN_PAGE} component={PatientLoginPage} exact />
                  <Route path={RoutesEnum.PATIENT_SIGNUP_MEDICINE} component={MedicineSignupPage} exact />
                  <Route path={RoutesEnum.PATIENT_SIGNUP_REGISTRATION} component={PatientSignupPage} exact />
                  <Route path={RoutesEnum.PATIENT_CREATE_USER} component={CreateUserPage} exact />

                  <Route path='/unimed' component={PatientLoginPage} exact />
                  <Route path={`${RoutesEnum.PATIENT_SIGNUP_MEDICINE}/unimed`} component={MedicineSignupPage} exact />
                  <Route path={`${RoutesEnum.PATIENT_SIGNUP_REGISTRATION}/unimed`} component={PatientSignupPage} exact />
                  <Route path={`${RoutesEnum.PATIENT_CREATE_USER}/unimed`} component={CreateUserPage} exact />

                  <Route path={RoutesEnum.TERM_OF_ACCEPTANCE} component={termOfAcceptancePage} exact />

                  {/* Adicione outras rotas / aqui */}

                  {/* Catch-all route for invalid URLs */}
                  <Route path="*">
                    <Redirect to={RoutesEnum.PATIENT_LOGIN_PAGE} />
                  </Route>
                </Switch>
              </PortalPatientLayout>
            </Route>
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default memo(Routes);
