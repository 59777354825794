import React from 'react';
import { ThemeProvider, CssBaseline, makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import light from './themes/light';
import Routes from './routes';
import AppContext from './contexts/app.context';

const useStyles = makeStyles({
  app: {
    background: '#fff',
    height: '100vh',
  },
});

function App(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.app}>
      <ThemeProvider theme={light}>
        <ToastContainer
          position="top-center"
          autoClose={false}
          closeOnClick
          draggable
          hideProgressBar
          style={{ top: '40%' }}
          limit={1}
          newestOnTop
        />
        <CssBaseline />
        <AppContext>
          <Routes />
        </AppContext>
      </ThemeProvider>
    </div>
  );
}

export default App;
