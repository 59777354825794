import { IAlertMessage } from '../../common/contracts';

export const patientFormAlertMessages: IAlertMessage = {
  success: {
    title: 'Cadastro realizado.',
    subTitle: 'Bem vindo ao programa, você será redirecionado para o Portal do Paciente.',
    status: 'success',
  },
  defaultError: {
    title: 'Ocorreu um erro ao realizar o seu cadastro.',
    subTitle: 'Não foi possível realizar o seu cadastro.',
    status: 'error',
  },
  missingMedicineFormDataError: {
    title: 'Cadastro inválido.',
    subTitle: 'É necessário preencher os dados da etapa de medicamento.',
    status: 'error',
  },
  successUpdatePatient: {
    title: 'Paciente atualizado com sucesso.',
    subTitle: 'Você será redirecionado para o Portal do Paciente.',
    status: 'success',
  },
  missingCaregiverError: {
    title: 'Cadastro inválido.',
    subTitle: 'O paciente menor de idade precisa ter um cuidador ou responsável legal.',
    status: 'error',
  },
  unauthorizedPatient: {
    title: 'Cadastro realizado.',
    subTitle: 'Bem vindo ao programa, você será redirecionado para a página de login.',
    status: 'info',
  }
};
