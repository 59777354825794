/* eslint-disable */

import React, { useState } from 'react';
import { Typography, Container, Box, Grid, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const noticias = [
  {
    title: "O que é diabetes?",
    content: "Quantas vezes você já ouviu falar que o açúcar em excesso faz mal para a saúde? Apesar de essa ser uma informação já conhecida por muitas pessoas, o conhecimento sobre o que é o diabetes de fato ainda é escasso.",
    fileName: 'contents/o-que-e-diabetes',
  },
  {
    title: "Mitos e verdades sobre alimentação e diabetes",
    content: "Quem convive com o diabetes sabe que não faltam dicas e receitas milagrosas que prometem controlar a glicemia. Mas afinal, o que realmente é verdade e o que é mentira? Veja abaixo alguns mitos e verdades sobre o assunto:",
    fileName: 'contents/mitos-e-verdades-sobre-alimentacao-e-diabetes',
  },
  {
    title: "Todo movimento conta",
    content: "Você quer incorporar a atividade física na sua rotina, mas não sabe o que fazer nem por onde começar?",
    fileName: 'contents/todo-movimento-conta',
  },
  {
    title: "Como tratar a hipoglicemia",
    content: "A hipoglicemia é uma complicação aguda que se caracteriza por níveis baixos de glicose no sangue. Ocorre uma queda na concentração da glicose para níveis abaixo de 70 mg/dl. É mais comum acontecer em pessoas...",
    fileName: 'contents/como-tratar-a-hipoglicemia',
  },
  {
    title: "Os sete comportamentos do autocuidado",
    content: "O autocuidado pode ser definido através de atividades que o indivíduo realiza em benefício de si próprio. Essas atividades estão relacionadas ao bem-estar e à promoção da saúde. A facilidade para essas atividades...",
    fileName: 'contents/os-sete-comportamentos-do-autocuidado',
  },
  {
    title: "Dieta DASH: alimentação saudável para hipertensos 1-3",
    content: "Que a alimentação faz toda diferença na sua saúde você já deve saber, certo? Mas você sabia que é possível reduzir a pressão arterial com mudanças na dieta diária?",
    fileName: 'contents/dieta-dash-alimentacao-saudavel-para-hipertensos-1-3',
  },
  {
    title: "O que é hipertensão arterial?",
    content: "A pressão arterial é um daqueles assuntos de saúde que todo mundo acha que entende um pouco. Afinal, quem nunca sentiu uma fraqueza e logo pensou “minha pressão deve estar baixa”?",
    fileName: 'contents/o-que-e-hipertensao-arterial',
  },
  {
    title: "Tratamento para hipertensão",
    content: "Ser hipertenso significa que o coração tem que trabalhar mais para conseguir vencer os altos valores de pressão. Esse trabalho extra faz com que as chances de ter complicações como um acidente vascular cerebral...",
    fileName: 'contents/tratamento-para-hipertensao',
  },
];

const ContentsPage: React.FC<any> = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNews, setFilteredNews] = useState(noticias);
  const [isFiltred, setIsFiltred] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedNews = showAll ? filteredNews : filteredNews.slice(0, 6);

  const handleSearchChange = (event: { target: { value: string; }; }) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = noticias.filter(news =>
      news.title.toLowerCase().includes(term) || news.content.toLowerCase().includes(term)
    );

    setIsFiltred(term != '' && filtered.length > 0);
    setFilteredNews(filtered);
  };

  return (
    <>
      <Container>
        <Box my={1} py={1}>
          <Typography variant="h1" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
            Conteúdos
          </Typography>
          <hr style={{ border: '1px solid lightgray', margin: '24px 0' }} />

          <Typography variant="h6" style={{ textAlign: 'left', marginBottom: '12px' }}>
            Aqui é possível encontrar informações seguras e de qualidade para auxiliar no conhecimento e na jornada das especialidades contempladas no programa.
          </Typography>

          <Box my={2} py={2}>
            <TextField
              fullWidth
              placeholder="Busque e explore nosso banco de conteúdos"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ backgroundColor: '#fff' }}
              InputProps={{
                startAdornment:
                  <Box>
                    <SearchOutlined />
                  </Box>
              }}
            />
          </Box>
          <Grid container spacing={5}>
            {displayedNews.map((news, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link to={`/panel/${news.fileName}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>
                    {news.title}
                  </Typography>
                  <Typography variant="body2" style={{ color: '#556275' }}>
                    {news.content}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>

        {!isFiltred && filteredNews.length > 6 && (
          <Box my={2} py={2}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Typography variant="body1" onClick={toggleShowAll} style={{ fontWeight: 'bold', color: 'var(--primary-main)', cursor: 'pointer', marginBottom: '64px' }}>
                  {showAll ? 'Exibir menos' : 'Exibir mais'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ContentsPage;
