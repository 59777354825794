import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../styles';
import { Box, Container } from '../styles/FormStyles';
import { IUseFormData } from '../../common/contracts';
import { prepareData } from '../validations/custom';
import { useLoader } from '../../../../contexts/patient-portal/Loader.context';
import { AddressBoxInputs } from './AddressBoxInputs';
import { addressAndAccreditedService } from '../../../../services/addressAndAccreditedService';
import { IFindAllAccreditedLocations } from '../contracts/accreditadeLocationInterface';
import { InputTable } from '../styles/TableInput';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';

export function AccreditedForm(): JSX.Element {
  const { showLoader, hideLoader } = useLoader();
  const { showDialogue } = useDialogue();
  const [results, setResults] = useState<IFindAllAccreditedLocations['listAccredited']>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false);

  const consultAccredited = async (accreditedFormData: any) => {
    const preparedData = prepareData({ accreditedFormData });
    try {
      const response = await addressAndAccreditedService.ListAccreditedLocation(preparedData);
      const { listAccredited } = response as unknown as IFindAllAccreditedLocations;
      setResults(listAccredited);
      setIsTableVisible(true);
    } catch (err) {
      console.error('Erro ao buscar locais credenciados:', err);
      setIsTableVisible(false);
    }
  };

  const modeOptions = {
    dataDestiny: consultAccredited,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    getValues,
    watch,
    unregister,
  } = useForm({ mode: 'onChange' });

  const formData: IUseFormData = {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister,
  };

  const uf: string = watch()?.uf;
  const city = Number(watch()?.city);
  const neighborhood = Number(watch()?.neighborhood);

  useEffect(() => {
    const isFormComplete = Boolean(uf) && Boolean(city) && Boolean(neighborhood);
    setIsFormValid(isFormComplete);

    if (!isFormComplete) {
      setIsTableVisible(false);
    }
  }, [uf, city, neighborhood]);

  const onSubmit = async (accreditedFormData: any): Promise<void> => {
    try {
      showLoader();
      await modeOptions?.dataDestiny(accreditedFormData);
      hideLoader();
    } catch (err: any) {
      hideLoader();
      showDialogue('Lista de Lojas', 'Não foi possível obter a lista de Lojas, tente novamente mais tarde!', 'info');
    }
  };

  return (
    <Container>
      <form
        style={{ flex: 1 }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <AddressBoxInputs formData={formData} />
        </Box>
        <Button>
          <button type="submit" disabled={!isFormValid}>Buscar</button>
        </Button>
      </form>
      {isTableVisible && results.length > 0 && <InputTable data={results} />}
    </Container>
  );
}
