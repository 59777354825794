import React, { ReactElement } from 'react';
import { AuthProvider } from './auth.context';
import { LoaderProvider } from './patient-portal/Loader.context';
import { AlertProvider } from './patient-portal/Alert.context';
import { RoutesProvider } from './routes.context';
import { DialogueProvider } from './patient-portal/Dialogue.context';

export type AppContextProps = {
  children: ReactElement;
};

export default function AppContext({ children }: AppContextProps): JSX.Element {
  return (
    <AuthProvider>
      <LoaderProvider>
        <AlertProvider>
          <DialogueProvider> 
            <RoutesProvider>{children}</RoutesProvider>
          </DialogueProvider>
        </AlertProvider>
      </LoaderProvider>
    </AuthProvider>
  );
}
