import envConfig from "../../../../config/environment-config";
import { CreateUserParams, ICreateUserFormData } from "../contracts";

export function prepareCreateBodyData({
    cpf,
    createUserFormData,
}: {
    cpf: string,
    createUserFormData: ICreateUserFormData,
}): CreateUserParams {

    return {
        originCode: envConfig.originCode,
        programId: envConfig.boehringerProgramCode,
        user: {
            cpf: cpf.replace(/\D/g, ''),
            email: createUserFormData.email,
            password: createUserFormData.password,
        },
    };
}