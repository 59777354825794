import EnvConfig from "../types/config"

const envConfig: EnvConfig = {
  boehringerProgramCode: process.env['REACT_APP_BOEHRINGER_PROGRAM_CODE'] ? parseInt(process.env['REACT_APP_BOEHRINGER_PROGRAM_CODE']) : -1,
  patientPortalApiUrl: process.env['REACT_APP_PORTAL_PATIENT_API_URL'] || '',
  originCode: process.env['REACT_APP_ORIGIN_CODE'] ? parseInt(process.env['REACT_APP_ORIGIN_CODE']) : -1,
  jwtToken: process.env['REACT_APP_JWT_TOKEN_MASTER'] || '',
  doctorPortal: process.env['REACT_APP_DOCTOR_PORTAL_URL'] || '',
  originCodeUnimed: process.env['REACT_APP_ORIGIN_CODE_UNIMED'] ? parseInt(process.env['REACT_APP_ORIGIN_CODE_UNIMED']) : -1,
}
  
export default envConfig
  