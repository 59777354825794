import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { IUseFormData } from '../contracts';

export function CheckBoxInput({
  list,
  row,
  formData,
  defaultValue,
}: {
  list: { label: string, fieldName: string }[],
  row?: true,
  formData: IUseFormData,
  defaultValue?: any,
}) {
  const { register } = formData;
  return (
    <FormGroup row={row}>
      {
        list?.map((value, index) => (
          <FormControlLabel
            key={index}
            control={
              (
                <Checkbox
                  defaultChecked={defaultValue?.[value.fieldName]}
                  {...register(value.fieldName)}
                />
              )
            }
            label={value.label}
          />
        ))
      }
    </FormGroup>
  );
}
