import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { GenderEnum, IDefaultValueRegistrationFormData, IMedicineFormData, IRegistrationFormData, IUpdatedPatient } from '../contracts';
import { DynamicResponse, GetPatient, Medication, SignupPatientParams, UpdatePatientParams } from '../contracts/signupInterface';
import envConfig from '../../../../config/environment-config';

export function handleDefaultValueData(
  patientData?: GetPatient,
): IDefaultValueRegistrationFormData | undefined {
  if (!patientData) return undefined;

  return {
    name: patientData.name,
    cpf: patientData.cpf,
    birthDate: patientData.birthDate,
    gender: patientData.gender as GenderEnum,
    DDD: patientData.cellphone?.slice(0, 2),
    phone: patientData.cellphone?.slice(2),
    email: patientData.email,
    cep: `${patientData.cep?.slice(0, 5)}-${patientData.cep?.slice(5)}`,
    address: patientData.address,
    numberHouse: patientData.numberHouse,
    complement: patientData.neighborhood,
    city: patientData.city,
    uf: patientData.uf,
    user_dynamic_questions: patientData
      ?.user_dynamic_questions?.map((value) => {
        if (!Array.isArray(value?.CodeResponse)) {
          return {
            ...value,
            CodeResponse: [value.CodeResponse],
          };
        }

        return value;
      }),
  };
}

export function setDefaultValues(
  defaultValue: IRegistrationFormData,
  setValue: UseFormSetValue<FieldValues>,
) {
  if (defaultValue) {
    setValue('cpf', defaultValue?.cpf);
    setValue('birthDate', defaultValue?.birthDate);
    setValue('name', defaultValue?.name);
    setValue('gender', defaultValue?.gender);
    setValue('DDD', defaultValue?.DDD);
    setValue('phone', defaultValue?.phone);
    setValue('cep', defaultValue?.cep);
    setValue('numberHouse', defaultValue?.numberHouse);
    setValue('complement', defaultValue?.complement);
    setValue('city', defaultValue?.city);
    setValue('uf', defaultValue?.uf);
    setValue('address', defaultValue?.address);
    setValue('email', defaultValue?.email);
  }
}

export function prepareUpdateBodyData(
  registrationFormData: IDefaultValueRegistrationFormData,
  healthPassportId: string,
): UpdatePatientParams {
  const cellphone = registrationFormData.DDD + registrationFormData.phone;
  const userDynamicQuestions = registrationFormData.user_dynamic_questions
    ?.filter((value) => {
      const condition = value !== undefined && value.CodeResponse && value.CodeResponse !== '';
      return condition;
    });

  return {
    code: envConfig.originCode,
    type: 'type', // TODO
    programId: envConfig.boehringerProgramCode,
    healthPassportId,
    user: {
      name: registrationFormData.name,
      birthDate: registrationFormData.birthDate ?? '',
      gender: registrationFormData.gender,
      cellphone,
      email: registrationFormData.email,
      password: registrationFormData.password,
      confirmPassword: registrationFormData.confirmPassword,
      oldPassword: registrationFormData.oldPassword,
      cep: registrationFormData.cep,
      address: registrationFormData.address,
      numberHouse: registrationFormData.numberHouse,
      neighborhood: registrationFormData.complement,
      city: registrationFormData.city,
      uf: registrationFormData.uf,
      // eslint-disable-next-line
      user_dynamic_questions: userDynamicQuestions,
    },
  };
}

function handleMedicationData(medicineFormData: IMedicineFormData): Medication[] {
  return medicineFormData.medication.map((value) => {
    const medicationDynamicQuestions = value.medication_dynamic_questions.filter(
      (dynamicQuestion) => {
        const contition = dynamicQuestion && dynamicQuestion.CodeQuestion && dynamicQuestion.CodeProductRule && (Array.isArray(dynamicQuestion) || typeof dynamicQuestion === "object")
        if (contition) return true;
        return false;
      }
    ); 

    return {
      ...value,
      medication_dynamic_questions: medicationDynamicQuestions,
    };
  });
}

function handleUserDynamicQuestions(registrationFormData: IRegistrationFormData): DynamicResponse[] {
  return registrationFormData.user_dynamic_questions
    ?.filter((value) => {
      const condition = value !== undefined && value.CodeResponse && value.CodeResponse !== '';
      return condition;
    });
}

export function prepareCreateBodyData({
  medicineFormData,
  registrationFormData,
}: {
  medicineFormData: IMedicineFormData,
  registrationFormData: IRegistrationFormData,
}): SignupPatientParams {
  const acceptTermsAndContacts = registrationFormData.confirmation && registrationFormData.terms;
  const userDynamicQuestions = handleUserDynamicQuestions(registrationFormData);
  const medication = handleMedicationData(medicineFormData);

  return {
    code: envConfig.originCode,
    type: 'type',
    programId: envConfig.boehringerProgramCode,
    user: {
      cpf: medicineFormData.cpf.replace(/\D/g, ''),
      birthDate: medicineFormData.birthDate,
      name: registrationFormData.name,
      gender: registrationFormData.gender,
      email: registrationFormData.email,
      cellphone: registrationFormData.DDD + registrationFormData.phone,
      uf: registrationFormData.uf,
      city: registrationFormData.city,
      address: registrationFormData.address,
      neighborhood: registrationFormData?.complement,
      numberHouse: registrationFormData.numberHouse,
      cep: registrationFormData.cep,
      password: registrationFormData.password,
      acceptTermsAndContacts,
      acceptSMS: registrationFormData.acceptSMS,
      acceptWhatsapp: registrationFormData.acceptWhatsapp,
      acceptEmail: registrationFormData.acceptEmail,
      user_dynamic_questions: userDynamicQuestions,
    },
    medication,
  };
}

//- InputData: {\"body\":{\"code\":35,\"type\":\"type\",\"programId\":1,\"user\":{\"cpf\":\"***********\",\"birthDate\":\"15/10/1996\",\"name\":\"Jean Valentim\",\"gender\":\"M\",\"email\":\"**********************************\",\"cellphone\":\"819 8199-5671\",\"uf\":\"PE\",\"city\":\"Recife\",\"address\":\"Avenida Guararapes\",\"neighborhood\":\"Santo Antônio\",\"numberHouse\":\"100\",\"cep\":\"50010-000\",\"password\":\"*********\",\"acceptTermsAndContacts\":true,\"acceptSMS\":false,\"acceptWhatsapp\":false,\"acceptEmail\":false,\"user_dynamic_questions\":[{\"CodeResponse\":\"961\",\"CodeQuestion\":\"99\",\"EnteredValue\":\"\",\"CodeProductRule\":\"0\"}]},\"medication\":[{\"CodeProductRule\":\"6991\",\"CRM\":\"123123\",\"doctorName\":\"Jean Carlos\",\"doctorSpecialty\":\"Pediatra\",\"CRMUF\":\"SP\",\"medication_dynamic_questions\":[{\"CodeResponse\":[\"942\"],\"CodeQuestion\":\"84\",\"EnteredValue\":\"\",\"CodeProductRule\":\"2517\"},null,null,null,null,{\"CodeResponse\":\"\"},{\"CodeResponse\":\"\"},{\"CodeResponse\":\"\"}]}],\"originCode\":35}} - Error: \"Cannot read properties of null (reading 'CodeResponse')\" - SecurityInfos: {\"ipAddress\":\"10.193.22.16\",\"operationalSystem\":\"Windows 10.0.0\",\"browser\":\"Chrome 127.0.0\",\"device\":\"Other 0.0.0\",\"uaSource\":\"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36\"}"}
//{"severity":"error","@timestamp":"2024-08-23T17:45:02.488Z","pid":1,"hostname":"portal-paciente-pbm-bff-69c47f67f4-wwmdx","context":"ExceptionsHandler","err":{"type":"Error","message":"Cannot read properties of null (reading 'CodeResponse')","stack":"TypeError: Cannot read properties of null (reading 'CodeResponse')\n    at PatientService.mapMedicationDynamicQuestions (/app/dist/src/modules/patient/services/patient.services.js:539:29)\n    at /app/dist/src/modules/patient/services/patient.services.js:438:58\n    at Array.map (<anonymous>)\n    at /app/dist/src/modules/patient/services/patient.services.js:431:47\n    at Array.map (<anonymous>)\n    at PatientService.mapSignupRequestDtoToRegisterPatientFormDto (/app/dist/src/modules/patient/services/patient.services.js:421:58)\n    at PatientService.signup (/app/dist/src/modules/patient/services/patient.services.js:40:48)\n    at PatientController.signup (/app/dist/src/modules/patient/controllers/patient.controller.js:30:48)\n    at /app/node_modules/.pnpm/@nestjs+core@10.0.0_@nestjs+common@10.0.0_class-transformer@0.5.1_class-validator@0.14.0_refl_x44b67olrgeayval67knffd7i4/node_modules/@nestjs/core/router/router-execution-context.js:38:29\n    at process.processTicksAndRejections (node:internal/process/task_queues:95:5)"},"message":"Cannot read properties of null (reading 'CodeResponse')"}