import { IAlertMessage } from '../../common/contracts';
import { captchaError } from '../../common/helpers';

export const medicineFormAlertMessages: IAlertMessage = {
  success: {
    title: 'Medicamento(s) adicionado(s) com sucesso.',
    subTitle: 'Você será redirecionado para visualizar seus medicamentos.',
    status: 'success',
  },
  error: {
    title: 'Não foi possível adicionar medicamento(s).',
    subTitle: '',
    status: 'error',
  },
};
