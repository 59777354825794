import React from 'react';
import { MedicineToggleList, SignupPhases, SignupWelcome } from '../components';
import { Footer, ImageTopBar } from '../../common/components';
import { PatientForm } from '../components/PatientForm';
import { PageModeEnum } from '../../common/contracts';

export default function PatientSignupPage(): JSX.Element {
  return (
    <>
      <ImageTopBar />
      <SignupPhases activeStep={1} />
      <MedicineToggleList />
      <SignupWelcome
        title="Informações de cadastro"
        subtitle="Precisamos de algumas informações para criar o seu perfil no Programa."
      />
      <PatientForm mode={PageModeEnum.CREATE} />
      <Footer />
    </>
  );
}
