import React from 'react';
import { PatientForm } from '../components/PatientForm';
import { PageModeEnum } from '../../common/contracts';
import { Typography } from '@material-ui/core';
import { Container } from '../styles';

export default function MyDataPage(): JSX.Element {
  return (
    <Container>
      <Typography variant="h4" style={{ width: '100%', color: '#000000' }}>
        Meus dados
      </Typography>
      <PatientForm mode={PageModeEnum.UPDATE} />
    </Container>
  );
}
