/* eslint-disable */

import React from 'react';
import { Card, CardContent, Typography, Button, CardActions } from '@material-ui/core';
import useStyles from './DiscountCardStyles';
import { useHistory } from 'react-router-dom';

interface DiscountCardProps {
  discount: {
    name: string;
    presentation: string;
    quantity: string;
    imageUrl: string;
    discountType: string;
    discountValue: string;
    productLink: string;
    storeLink: string;
  };
}

const DiscountCard: React.FC<DiscountCardProps> = ({ discount }) => {
  const { push } = useHistory();
  const classes = useStyles();

  return (
    <Card className="MedicineCard">
      <CardContent>
        <Typography variant="h5" component="div" className={classes.medicineName}>
          {discount.name}
        </Typography>
        <Typography variant="body2">
          {discount.presentation}
        </Typography>
        <Typography variant="body2" className={classes.medicineQuantity}>
          {discount.quantity}
        </Typography>
        <Typography variant="body2" className={classes.medicineDiscount}>
          {discount.discountValue} OFF
        </Typography>
      </CardContent>
      <CardActions className={classes.actionsContainer}>
        <Button onClick={() => push(discount.productLink) } className={classes.storeButton}>
          Melhor preço. Confira Aqui!
        </Button>
        <Typography onClick={() => push(discount.storeLink)} variant="body2" className={classes.storeText}>
          Encontre uma loja
        </Typography>
      </CardActions>
    </Card>
  );
};

export default DiscountCard;
