import styled from 'styled-components';

export const Button = styled.div<{type: 'default' | 'cancel'}>`
    margin: 0 auto;
    padding-bottom: 40px;
    width: 182px;

    button {
        background-color: ${({type}) => type === 'default' ? '#1570EF' : '#e64040'};
        width: 182px;
        height: 44px;
        border-radius: 999px;
        padding: 0px, 20px, 0px, 20px;
        border: none;
        color: #FFF8F5;
        font-size: 16px;
        line-height: 18.75px;
        text-align: center;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
    }

    @media (max-width: 600px) {
        margin: 0;
        padding-bottom: 20px;
    }
`;
