import { authenticatedGetRequest, authenticatedPostRequest } from './authenticatedRequestService';
import { IFindAllAccreditedLocations } from '../pages/portal-patient/accredited/contracts/accreditadeLocationInterface';
import { IAccreditedLocationParams } from '../pages/portal-patient/accredited/contracts/accreditedFormDataInterface';

async function getListCities(
  programCode: number,
  state: string,
): Promise<{ listCities: { code: number; name: string }[] }> {
  const method = 'address/GetListCities';
  const body = { programCode, state };

  try {
    const response = await authenticatedPostRequest(method, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getAddressByCEP(cep: string): Promise<any> {
  const method = 'integration/get-address-by-cep';
  const params = `/${cep}`;
  const cepLengthWithPontuation = 9;

  if (cep?.length !== cepLengthWithPontuation) {
    throw new Error('CEP inválido');
  }

  try {
    const response = await authenticatedGetRequest(method, params)
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function listNeighborhoods(
  programCode: number,
  cityCode: number,
  state: string,
): Promise<{ listNeighborhoods: { code: number; description: string }[] }> {
  const method = 'address/ListNeighborhoods';
  const body = { programCode, cityCode, state };

  try {
    const response = await authenticatedPostRequest(method, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function ListAccreditedLocation(body: IAccreditedLocationParams): Promise<{ listAccreditedLocation: IFindAllAccreditedLocations }> {
  const method = 'accredited/ListAccreditedLocation';

  try {
    const response = await authenticatedPostRequest(method, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

export const addressAndAccreditedService = {
  getListCities,
  getAddressByCEP,
  listNeighborhoods,
  ListAccreditedLocation
};
