import React, { useState } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import { MedicineBoxInput } from './MedicineInput';
import { Box, InputTitle } from '../styles/FormStyles';
import { DoctorInputs } from './DoctorInputs';
import { IMedicine, IUseFormData } from '../contracts';

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

  p {
    margin: 0;
  }
`;

export function MedicineAndDoctorInputBox({
  index,
  formData,
  medicinsList,
}: {
  index: number,
  formData: IUseFormData,
  medicinsList?: IMedicine[],
}) {
  const [selectedMedicine, setSelectedMedicine] = useState<string | undefined>(undefined);
  const [showMedicine, setShowMedicine] = useState<boolean>(true);
  const handleSetSelectedMedicine = (selectedValue: string | undefined) => {
    setSelectedMedicine(selectedValue);
  };
  const handleShowMedicine = () => {
    setShowMedicine(!showMedicine);
  };

  return (
    <div>
      <ToggleBox onClick={handleShowMedicine}>
        { showMedicine ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
        <InputTitle>Medicamento { selectedMedicine ?? ''}</InputTitle>
      </ToggleBox>
      <MedicineBoxInput
        index={index}
        handleSetSelectedMedicine={handleSetSelectedMedicine}
        showMedicine={showMedicine}
        formData={formData}
        medicinsList={medicinsList}
      />
      {
        selectedMedicine
          ? (
            <Box style={{ display: showMedicine ? 'initial' : 'none' }}>
              <DoctorInputs
                selectedMedicine={selectedMedicine}
                formData={formData}
                index={index}
              />
            </Box>
          ) : ''
      }
    </div>
  );
}
