import React, { useState } from 'react';
import { AddMoreText, InputsBox } from '../../common/styles';
import { PasswordInput } from './PasswordInput';
import { IUseFormData } from '../../common/contracts';

export function PasswordBoxInputs({ formData }: {formData: IUseFormData}) {
  return (
    <>
      <InputsBox>
        <PasswordInput
          title="Digite sua senha"
          helperText="Deve ter pelo menos 6 caracteres"
          formData={formData}
          propertieFormName="password"
        />
        <PasswordInput
          title="Confirme sua senha"
          formData={formData}
          propertieFormName="confirmPassword"
        />
      </InputsBox>
    </>
  );
}

export function UpdatePasswordBoxInputs({ formData }: {formData: IUseFormData}) {
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const { unregister } = formData;

  return (
    <>
      <AddMoreText
        onClick={() => {
          setEditPassword(!editPassword);
          if (editPassword) {
            unregister('password');
            unregister('oldPassword');
            unregister('confirmPassword');
          }
        }}
      >
        {editPassword ? 'Desfazer alterações de senha' : 'Clique aqui pra editar sua senha ' }
      </AddMoreText>
      {
        editPassword
          ? (
            <>
              <PasswordInput
                title="Digite sua senha antiga"
                formData={formData}
                propertieFormName="oldPassword"
              />
              <InputsBox>
                <PasswordInput
                  title="Digite sua nova senha"
                  helperText="Deve ter pelo menos 6 caracteres"
                  formData={formData}
                  propertieFormName="password"
                />
                <PasswordInput
                  title="Confirme sua nova senha"
                  formData={formData}
                  propertieFormName="confirmPassword"
                />
              </InputsBox>
            </>
          )
          : <></>
    }
    </>
  );
}
