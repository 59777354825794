import { FieldValues, UseFormSetValue } from 'react-hook-form';

function onChangeTextField({
  value,
  codeQuestion,
  lineCode,
  codeResponse,
  setValue,
  fieldName,
}: {
    value: string,
    codeQuestion: number | string,
    lineCode?: number | string,
    codeResponse: number | string,
    setValue: UseFormSetValue<FieldValues>,
    fieldName: string,
}) {
  setValue(`${fieldName}.EnteredValue`, value);
  setValue(`${fieldName}.CodeQuestion`, codeQuestion.toString());
  setValue(`${fieldName}.CodeProductRule`, lineCode?.toString());
  setValue(`${fieldName}.CodeResponse`, [codeResponse.toString()]);
}

function onChangeSelectInput({
  value,
  codeQuestion,
  lineCode,
  setValue,
  fieldName,
}: {
    value: string,
    codeQuestion: number | string,
    lineCode?: number | string,
    setValue: UseFormSetValue<FieldValues>,
    fieldName: string,
}) {
  setValue(`${fieldName}.CodeResponse`, [value]);
  setValue(`${fieldName}.CodeQuestion`, codeQuestion.toString());
  setValue(`${fieldName}.EnteredValue`, '');
  setValue(`${fieldName}.CodeProductRule`, lineCode?.toString());
}

function onChangeCheckBox({
  value,
  codeQuestion,
  lineCode,
  setValue,
  fieldName,
  getValues,
  checked,
}: {
    value: string,
    codeQuestion: number | string,
    lineCode: number | string,
    setValue: UseFormSetValue<FieldValues>,
    fieldName: string,
    getValues: any,
    checked: boolean,
}) {
  let currentValues: string[] = getValues(`${fieldName}.CodeResponse`) || [];

  if (checked) {
    currentValues.push(value);
  } else {
    currentValues = currentValues.filter((v) => v !== value);
  }

  setValue(`${fieldName}.CodeResponse`, currentValues);
  setValue(`${fieldName}.CodeQuestion`, codeQuestion.toString());
  setValue(`${fieldName}.EnteredValue`, '');
  setValue(`${fieldName}.CodeProductRule`, lineCode.toString());
}

function onChangeFileUpload({
  fileContentCodeResponse,
  fileContentValue,
  fileNameCodeResponse,
  fileNameValue,
  codeQuestion,
  lineCode,
  setValue,
  fieldName,
}: {
    fileContentCodeResponse: number | string,
    fileContentValue: string,
    fileNameCodeResponse: number | string,
    fileNameValue: string,
    codeQuestion: number | string,
    lineCode?: number | string,
    setValue: UseFormSetValue<FieldValues>,
    fieldName: string,
}) {
  setValue(`${fieldName}.0.CodeResponse`, [fileContentCodeResponse.toString()]);
  setValue(`${fieldName}.0.EnteredValue`, fileContentValue);
  setValue(`${fieldName}.0.CodeQuestion`, codeQuestion.toString());
  setValue(`${fieldName}.0.CodeProductRule`, lineCode?.toString());
  
  setValue(`${fieldName}.1.CodeResponse`, [fileNameCodeResponse.toString()]);
  setValue(`${fieldName}.1.EnteredValue`, fileNameValue);
  setValue(`${fieldName}.1.CodeQuestion`, codeQuestion.toString());
  setValue(`${fieldName}.1.CodeProductRule`, lineCode?.toString());
}

export const dynamicFieldsHelpers = {
  onChangeTextField,
  onChangeSelectInput,
  onChangeCheckBox,
  onChangeFileUpload,
};
