import React from 'react';
import styled from 'styled-components';
import { Step, StepConnector, StepLabel, Stepper, withStyles } from '@material-ui/core';

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#4CAF50',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#4CAF50',
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const StepIconRoot = styled.div`
  color: #eaeaf0;
  display: flex;
  height: 22px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: 1px solid none;
  width: 27px;
  height: 27px;
  border-radius: 50%;

  &.active {
    color: #4caf50;
    background-color: #00853C29;
  }
  &.completed {
    color: #4caf50;
  }
`;

const StepIconCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: currentColor;
`;

const Label = styled.div<{active: boolean}>`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.06px;
  text-align: center;
  color: ${(props: {active: boolean}) => (props.active ? 'black' : '#A3A4A5')};
`;

const LabelDescription = styled.div<{active: boolean}>`
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;
  text-align: center;
  color: ${(props: {active: boolean}) => (props.active ? 'black' : '#A3A4A5')};
`;

function CustomStepIcon({ active, completed }: any) {
  return (
    <StepIconRoot className={`${active ? 'active' : ''} ${completed ? 'completed' : ''}`}>
      <StepIconCircle />
    </StepIconRoot>
  );
}

const steps: {
  label: string,
  description: string,
  active: boolean,
}[] = [
  { label: 'Medicamento', description: 'diagnóstico e prescrição', active: false },
  { label: 'Cadastro', description: 'Informe seus dados', active: false },
  { label: 'Portal Paciente', description: 'Abraçar a Vida', active: false },
];

export function SignupPhases({ activeStep }: {activeStep: number}): JSX.Element {
  const isMobile = window.innerWidth <= 600;

  steps.forEach((value, index) => {
    /* eslint-disable-next-line no-param-reassign */
    if (index === activeStep) value.active = true;
  });

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />} style={{ width: isMobile ? '100%' : '591px', margin: '20px auto' }}>
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} />}>
            <div style={{ textAlign: 'center' }}>
              <Label active={step.active}>{step.label}</Label>
              <LabelDescription active={step.active}>{step.description}</LabelDescription>
            </div>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
