import React, { createContext, useContext, useReducer, ReactElement, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import styles from './Dialogue.module.css';

type DialogueContextState = {
  title: string;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  isVisible: boolean;
  showDialogue: (title: string, message: string, severity: 'success' | 'info' | 'warning' | 'error', confirmLabel?: string, cancelLabel?: string, onConfirm?: () => void, onCancel?: () => void) => void;
  hideDialogue: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

type DialogueProviderProps = {
  children: ReactElement;
};

type Action =
  | { type: 'SHOW_ALERT'; payload: { title: string; message: string; severity: 'success' | 'info' | 'warning' | 'error', confirmLabel?: string, cancelLabel?: string, onConfirm?: () => void, onCancel?: () => void } }
  | { type: 'HIDE_ALERT' };

const stub = (): never => {
  throw new Error('Wrap your component in an DialogueProvider');
};

const defaultState: DialogueContextState = {
  title: '',
  message: '',
  severity: 'success',
  isVisible: false,
  showDialogue: stub,
  hideDialogue: stub,
};

const reducer = (state: DialogueContextState, action: Action) => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {
        ...state,
        ...action.payload,
        isVisible: true,
      };
    case 'HIDE_ALERT':
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
};

export const DialogueContext = createContext<DialogueContextState>(defaultState);

export const DialogueProvider = ({ children }: DialogueProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const showDialogue = useCallback((title: string, message: string, severity: 'success' | 'info' | 'warning' | 'error', confirmLabel?: string, cancelLabel?: string, onConfirm?: () => void, onCancel?: () => void) => {
    dispatch({ type: 'SHOW_ALERT', payload: { title, message, severity, confirmLabel, cancelLabel, onConfirm, onCancel } });
  }, []);

  const hideDialogue = useCallback(() => {
    dispatch({ type: 'HIDE_ALERT' });
  }, []);

  const handleConfirm = () => {
    if (state.onConfirm) {
      state.onConfirm();
    }
    hideDialogue();
  };

  const handleCancel = () => {
    if (state.onCancel) {
      state.onCancel();
    }
    hideDialogue();
  };

  return (
    <DialogueContext.Provider
      value={{
        ...state,
        showDialogue,
        hideDialogue,
      }}
    >
      <Dialog open={state.isVisible} onClose={hideDialogue} aria-labelledby="dialogue-dialog-title" aria-describedby="dialogue-dialog-description">
        <DialogTitle id="dialogue-dialog-title">{state.title}</DialogTitle>
        <DialogContent>
          <Alert severity={state.severity}>{state.message}</Alert>
        </DialogContent>
        <DialogActions>
          {state.confirmLabel && (
            <Button onClick={handleConfirm} color="primary" variant="contained">
              {state.confirmLabel}
            </Button>
          )}
          {state.cancelLabel && (
            <Button onClick={handleCancel} color="secondary" variant="outlined">
              {state.cancelLabel}
            </Button>
          )}
          <IconButton aria-label="close" className={styles.CloseButton} onClick={hideDialogue}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      {children}
    </DialogueContext.Provider>
  );
};

export const useDialogue = (): DialogueContextState => useContext(DialogueContext);
