/* eslint-disable max-len */
/* eslint-disable react/display-name */
import React, { useCallback } from 'react';
import { IMaskInput } from 'react-imask';
import { OnChangeType } from './OnChangeType';

const createIMaskInput = (mask: any, placeholder: string) => {
  const MaskedInput = React.forwardRef((props: {
    name: string,
    onChange: (target: OnChangeType) => void,
  }, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        placeholder={placeholder}
        inputRef={ref}
        onAccept={useCallback(
          (value) => onChange({ target: { name: props.name, value } }),
          [onChange, props.name],
        )}
        overwrite
      />
    );
  });

  return MaskedInput;
};

const cpfEmailMask = [
  {
    mask: '000.000.000-00', // CPF format
    lazy: true, // Show the placeholder from the start
  },
  {
    mask: /^\S*@?\S*$/, // Regex pattern for email format
    lazy: false,
    overwrite: false,
  },
];

const IMaskInputCPFEmail = createIMaskInput(cpfEmailMask, 'CPF ou Email');
const IMaskInputCRM = createIMaskInput('000000/aa', '000000/UF');
const IMaskInputCPF = createIMaskInput('000.000.000-00', '000.000.000-00');
const IMaskInputDate = createIMaskInput('00/00/0000', 'DD/MM/YYYY');
const IMaskInputRG = createIMaskInput('00.000.000-0', '00.000.000-0');
const IMaskInputCellPhone = createIMaskInput('(00) 9 0000-0000', '(00) 9 0000-0000');
const IMaskInputPhone = createIMaskInput('+00 (00) 0000-0000', '+00 (00) 0000-0000');
const IMaskInputCellPhoneNoDDD = createIMaskInput('9 0000-0000', '9 0000-0000');
const IMaskInputCEP = createIMaskInput('00000-000', '00000-000');
const IMaskInputDDD = createIMaskInput('00', '00');

export {
  IMaskInputCPFEmail,
  IMaskInputCRM,
  IMaskInputCPF,
  IMaskInputDate,
  IMaskInputRG,
  IMaskInputCellPhone,
  IMaskInputPhone,
  IMaskInputCEP,
  IMaskInputDDD,
  IMaskInputCellPhoneNoDDD,
};
