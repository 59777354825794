import React, { createContext, useContext, useReducer, ReactElement, useCallback } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close'; // Importando ícone de fechar
import IconButton from '@material-ui/core/IconButton'; // Importando componente de botão de ícone
import styles from './Alert.module.css';

type AlertContextState = {
  title: string;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  style?: any;
  isVisible: boolean;
  showAlert: (title: string, message: string, severity: 'success' | 'info' | 'warning' | 'error', style?: any) => void;
  hideAlert: () => void;
};

type AlertProviderProps = {
  children: ReactElement;
};

type Action =
  | { type: 'SHOW_ALERT'; payload: { title: string; message: string; severity: 'success' | 'info' | 'warning' | 'error', style?: any } }
  | { type: 'HIDE_ALERT' };

const stub = (): never => {
  throw new Error('Wrap your component in an AlertProvider');
};

const defaultState: AlertContextState = {
  title: '',
  message: '',
  severity: 'success',
  isVisible: false,
  showAlert: stub,
  hideAlert: stub,
};

const reducer = (state: AlertContextState, action: Action) => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {
        ...state,
        ...action.payload,
        isVisible: true,
      };
    case 'HIDE_ALERT':
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
};

export const AlertContext = createContext<AlertContextState>(defaultState);

export const AlertProvider = ({ children }: AlertProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const showAlert = useCallback((title: string, message: string, severity: 'success' | 'info' | 'warning' | 'error', style) => {
    dispatch({ type: 'SHOW_ALERT', payload: { title, message, severity, style } });
  }, []);

  const hideAlert = useCallback(() => {
    dispatch({ type: 'HIDE_ALERT' });
  }, []);

  return (
    <AlertContext.Provider
      value={{
        ...state,
        showAlert,
        hideAlert,
      }}
    >
      {state.isVisible && (
        <Alert 
          variant="filled" 
          severity={state.severity} 
          className={state.style ? '' : styles.Alert}
          style={state.style ?{
            maxWidth: "479px",
            width: "100%",
            position: "absolute",
            zIndex: 9999,
            ...state.style,
          } : {}}
        >
          <div className={styles.AlertDiv}>
            <div className={styles.AlertText}>
              <strong>{state.title}</strong>
              <br />
              {state.message}
            </div>
            <IconButton className={styles.AlertButton} onClick={hideAlert} style={{marginTop: "-20px"}}>
              <CloseIcon style={{ fontSize: '15px' }} />
            </IconButton>
          </div>
        </Alert>
      )}
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = (): AlertContextState => useContext(AlertContext);
