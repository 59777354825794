// /components/PortalPatient/Footer.tsx
import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import styles from './Footer.module.css';

export default function Footer(): JSX.Element {
  return (
    <footer className={styles.Footer}>
      <div className={styles.Container}>
        <div className={`${styles.Heading} text-center text-md-start`}>
          <p className={styles.Small}>
            Central de atendimento
          </p>
          <div>
            <PhoneIcon />
            <p className={styles.Contact}>
              0800 701 6633
            </p>
          </div>
          <p className="mb-0">
            de segunda a sexta, das 9h às 18h, exceto feriados.
          </p>
        </div>
        <div className={`${styles.Email} text-center text-md-start mt-4 mt-md-0`}>
          <p className={styles.Small}>
            E-mail
          </p>
          <div>
            <EmailIcon />
            <p className={styles.Contact}>
              abracaravida@funcionalcorp.com.br
            </p>
          </div>
        </div>
        <div className={`${styles.Links} text-center text-md-start mt-4 mt-md-0`}>
          <a href="/termOfAcceptance#confidentialityAndProtection" target='_blank'>Política de privacidade</a>
          <a href="/termOfAcceptance#termsAndConditions" target='_blank'>Termos de Uso</a>
        </div>
        <div className={`${styles.Branding} text-center text-md-start mt-4 mt-md-0`}>
          <img src="/assets/img/logo/logo-boehringer.webp" alt="Boehringer Ingelheim" />
        </div>
      </div>
      <div className={styles.Disclaimer}>
        <p>
          ATENÇÃO: Todo medicamento deve ser utilizado conforme as orientações de bula.
          O uso fora dessas especificações não possui eficácia e segurança comprovadas,
          portanto não é recomendado.
        </p>
      </div>
    </footer>
  );
}
