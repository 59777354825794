import React from 'react';
import ServiceWillBeAvailable from '../../../components/ServiceWillBeAvailable';

const SchedulesPage: React.FC<any> = (): JSX.Element => {
  
  return (
    <>
      <ServiceWillBeAvailable />
    </>
  );
};

export default SchedulesPage;
