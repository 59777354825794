import React, { useEffect, useState } from 'react';
import { DynamicResponse, IUseFormData, Question } from '../contracts';
import { NestedQuestions } from './NestedQuestion';
import { DynamicFields } from './DynamicFields';
import { Box } from '../styles';

export function DynamicQuestionsBox({
  question,
  formData,
  mainFieldName,
  index,
  allQuestions,
  defaultValue,
}: {
  question: Question,
  formData: IUseFormData,
  mainFieldName: string,
  index: number,
  allQuestions: Question[],
  defaultValue?: DynamicResponse[],
}) {
  const defaultValueQuestion = defaultValue
    ?.find((v) => v.CodeQuestion.toString() === question.CodigoQuestao.toString());
  const [answerCode, setAnswerCode] = useState<string>(defaultValueQuestion?.CodeResponse?.[0] ?? '0'); // Don't work to multiple choice
  const [initialDefaultValue, setInitialDefaultValue] = useState<DynamicResponse | undefined>(defaultValueQuestion);

  useEffect(() => {
    if (answerCode === '0') {
      setAnswerCode(defaultValueQuestion?.CodeResponse?.[0] ?? '0');
    }
    if (!defaultValueQuestion) {
      setInitialDefaultValue(defaultValueQuestion)
    }
  }, [defaultValue, defaultValueQuestion]);

  return (
    <>
      <Box>
        <DynamicFields
          question={question}
          formData={formData}
          mainFieldName={mainFieldName}
          index={index}
          setAnswerCode={setAnswerCode}
          defaultValue={defaultValueQuestion}
        />
      </Box>
      <Box>
        <NestedQuestions
          question={question}
          formData={formData}
          allQuestions={allQuestions}
          answerCode={answerCode}
          mainFieldName={mainFieldName}
          defaultValue={defaultValue}
        />
      </Box>
    </>
  );
}
