import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

type FooterLoggedProps = {
  className?: string;
};

export default function FooterLogged({ className }: FooterLoggedProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.footer, className)}>
      <Typography variant="caption" className={classes.span}>
        © 2024 Funcional Health Tech. Todos os direitos reservados.
      </Typography>
    </div>
  );
}
