import React from 'react';
import { Checkbox, FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import { BoxFlex } from '../../common/styles';
import { IUseFormData } from '../../common/contracts';
import { validateBothCheckedTerms } from '../../common/validations/custom';
import { Link } from 'react-router-dom';

const TermText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #282929;
  margin-top: 20px;

  span {
    color: #1570EF;
  }
`;

export function TermsBoxInputs({
  formData,
}: {
  formData: IUseFormData,
}) {
  const { register, watch, errors } = formData;

  return (
    <>
      <BoxFlex>
        <Checkbox
          {...register('confirmation', {
            validate: (value) => validateBothCheckedTerms(value, watch()),
          })}
          style={{ marginLeft: '-11px' }}
        />
        {' '}
        <TermText>
          Li e aceito a 
          <Link to="/termOfAcceptance#confidentialityAndProtection" target="_blank">
            <span> confirmação de registro</span>
          </Link>
          . *
        </TermText>
      </BoxFlex>
      <BoxFlex>
        <Checkbox
          {...register('terms', {
            validate: (value) => validateBothCheckedTerms(value, watch()),
          })}
          style={{ marginLeft: '-11px' }}
        />
        <TermText>
          Li e concordo com os
          {' '}
          <Link to="/termOfAcceptance#termsAndConditions" target="_blank">
            <span>Termos e condições</span>
          </Link>
          {' '}
          do site
          e autorizo a prosseguir com o meu cadastro. *
        </TermText>
      </BoxFlex>
      {
        (errors.confirmation || errors.terms) && (
          <FormHelperText error>
            {errors.confirmation?.message || errors.terms?.message}
          </FormHelperText>
        )
      }
    </>
  );
}
