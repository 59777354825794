import React from 'react';
import FooterLogged from '../../components/FooterLogged';

type FooterComposerProps = {
  className?: string;
};

export default function FooterComposer(props: FooterComposerProps): JSX.Element {
  return <FooterLogged {...props} />;
}
